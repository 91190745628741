
import {
    GET_RESTAURANT_SEARCH_CITIES,
      SET_MESSAGE,
    } from "./types";
    import CommonHelper from "../utils/CommonHelper";
    
    import CitySearchDataService from "../services/city_search.service";
    // import { _pre } from "../utils/vlogs";
    
    export const getSearchedCityData = (value) => async (dispatch) => {
      try {
        const res = await CitySearchDataService.getAutoCityData(value);
          
        console.log(res.data.data, "hhhhhaaasshh");
        dispatch({
          type: GET_RESTAURANT_SEARCH_CITIES,
          payload: res.data.data,
        });
    
        /* const resMessage = {
          message: res.data.message,
          type: CommonHelper.getMsgType("E"),
        };
        dispatch({
          type: SET_MESSAGE,
          payload: resMessage, //message,
        }); */
        return Promise.resolve(res.data);
      } catch (error) {
  
        // console.log('in error of create restaurant');
  
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    
      // _pre("  action auth-user.js -> updatePassword -> error ", error);
      // dispatch({
      //   type: UPDATE_PASSWORD_FAIL,
      // });
      const resMessage = {
        message: message,
        type: CommonHelper.getMsgType("E"),
      };
      // console.log(resMessage, 'restaurant');
  
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      });
        return Promise.reject(error);
      }
    };
  
  
  
    
  