

import axios from "axios";
import configData from "../config.json";
//const API_URL = "http://localhost:8080/api/test/";
const apiEndpoint = configData.apiUrl + "/home";

class CitySearchDataService {
  

getCityData() {
    return axios.get(apiEndpoint + `/cities`);
    //return axios.get(apiEndpoint, { headers: authHeader() } );
  }

  getAutoCityData(value) {
    return axios.get(apiEndpoint + `/city/autocomplete?cityName=` + value);
    //return axios.get(apiEndpoint, { headers: authHeader() } );
  }
  

}

export default new CitySearchDataService();
