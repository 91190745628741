import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import * as yup from "yup";
import { getRestaurantDetailByslug } from "../../../actions/restaurants";
import { withTranslation } from "react-i18next";
import Loading from "../../common/loading";
import { connect } from "react-redux";
import { compose } from "redux";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

 
class Individual2 extends Component {
  constructor(props) {
    super(props);
    

    this.state = {
        activeIndex: 1,
        email: "",
        password: "",
        loading: false,
        animating: false,
        resturants: [],
    };


  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.getRestaurantByDetail();
  }

  async getRestaurantByDetail()
  {
    let slug = this.props.match.params.slug;
    await this.props.getRestaurantDetailByslug(slug);

    console.log(this.props, "kkkkkkkk");
    this.setState({
        resturants:this.props.restaurants.merchant
    });
  } 
   
 

  render() {
    //const { isLoggedIn, message } = this.props;
    const { isLoggedIn } = this.props;

    if(this.state.resturants.length !== 0){
    return (
            <div>
                <section id="main-wrapper" className="padall0 image-to-background" data-overlay-black={5}>
                    <img src={this.state.resturants.merchant_image.cover_photo_url} onError={(e)=>{e.target.src= this.state.resturants.default_cover_photos[0]; }}  alt="Hero Slider Background" />
                 
                    <div id="hero-wrapper">
                    <OwlCarousel className="hero-slider owl-theme" loop autoplay={true} nav={false} dots={false} items={1}>
                 
                        <div className="container">
                            <div className="center-hero-text">
                                
                                { 
                                this.state.resturants.merchant_image.logo_shape == 2 ? 
                                <div className="d-flex justify-content-center">
                                <div className="individual-slider-logo-img" >
                                     <img src={this.state.resturants.merchant_image.logo_url} className="img-fluid" style={{maxWidth: 150}}/>
                                </div>
                                </div>
                                : this.state.resturants.merchant_image.logo_shape == 1 ?
                                <div className="d-flex justify-content-center">  
                                <img src={this.state.resturants.merchant_image.logo_url} className="img-fluid" style={{maxWidth: 150}} />
                                </div>
                                :this.state.resturants.merchant_image.logo_shape == 3 ?
                                 <div className="d-flex justify-content-center rectangle">  
                                    <img src={this.state.resturants.merchant_image.logo_url} className="img-fluid" style={{maxWidth: 150}} />
                                 </div>
                                 : ""
                                }
                                
                                <h2 className="carousel-big">{this.state.resturants.merchant_restaurant.restaurant_name}</h2>
                                <p className="text-white m-0">{this.props.t("restaurant_detail.rest_msg")}.</p>
                                <div className="single-page-nav" id="testing">
                                <a href="#" className="btn btn-bg-custom margintop30 btn-lg mr-1">{this.props.t("restaurant_detail.find_out_more")}</a>
                                <a href="#" className="btn btn-bg-custom margintop30 btn-lg">{this.props.t("restaurant_detail.back_to_listing")}</a>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="center-hero-text">
                            { 
                                this.state.resturants.merchant_image.logo_shape == 2 ? 
                                <div className="d-flex justify-content-center">
                                <div className="individual-slider-logo-img" >
                                     <img src={this.state.resturants.merchant_image.logo_url} className="img-fluid" style={{maxWidth: 150}}/>
                                </div>
                                </div>
                                : this.state.resturants.merchant_image.logo_shape == 1 ?
                                <div className="d-flex justify-content-center">  
                                <img src={this.state.resturants.merchant_image.logo_url} className="img-fluid" style={{maxWidth: 150}} />
                                </div>
                                :this.state.resturants.merchant_image.logo_shape == 3 ?
                                 <div className="d-flex justify-content-center rectangle">  
                                    <img src={this.state.resturants.merchant_image.logo_url} className="img-fluid" style={{maxWidth: 150}} />
                                 </div>
                                 : ""
                                }
                                <h2 className="carousel-big">{this.state.resturants.merchant_restaurant.restaurant_name}</h2>
                                <p className="text-white m-0">{this.props.t("restaurant_detail.rest_msg")}.</p>
                                <div className="single-page-nav" id="testing">
                                <a href="#" className="btn btn-bg-custom margintop30 btn-lg mr-1">{this.props.t("restaurant_detail.find_out_more")}</a>
                                <a href="#" className="btn btn-bg-custom margintop30 btn-lg">{this.props.t("restaurant_detail.back_to_listing")}</a>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="center-hero-text">
                            { 
                                this.state.resturants.merchant_image.logo_shape == 2 ? 
                                <div className="d-flex justify-content-center">
                                <div className="individual-slider-logo-img" >
                                     <img src={this.state.resturants.merchant_image.logo_url} className="img-fluid" style={{maxWidth: 150}}/>
                                </div>
                                </div>
                                : this.state.resturants.merchant_image.logo_shape == 1 ?
                                <div className="d-flex justify-content-center">  
                                <img src={this.state.resturants.merchant_image.logo_url} className="img-fluid" style={{maxWidth: 150}} />
                                </div>
                                :this.state.resturants.merchant_image.logo_shape == 3 ?
                                 <div className="d-flex justify-content-center rectangle">  
                                    <img src={this.state.resturants.merchant_image.logo_url} className="img-fluid" style={{maxWidth: 150}} />
                                 </div>
                                 : ""
                                }
                                <h2 className="carousel-big">{this.state.resturants.merchant_restaurant.restaurant_name}</h2>
                                <p className="text-white m-0">{this.props.t("restaurant_detail.rest_msg")}.</p>
                                <div className="single-page-nav" id="testing">
                                <a href="#" className="btn btn-bg-custom margintop30 btn-lg mr-1">{this.props.t("restaurant_detail.find_out_more")}</a>
                                <a href="#" className="btn btn-bg-custom margintop30 btn-lg">{this.props.t("restaurant_detail.back_to_listing")}</a>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                    </div>
                </section>
            <div>
            { this.state.resturants.merchant_aboutus !== undefined ?
            <section className="sb-video ">
                <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 align-self-center">
                    <div className="sb-illustration-7">
                        <div className="sb-interior-frame">
                        <img src={this.state.resturants.merchant_aboutus.image_url} alt="interior" className="sb-interior" />
                        </div>
                        <div className="sb-cirkle-1" />
                        <div className="sb-cirkle-2" />
                        <div className="sb-cirkle-3" />
                        <div className="sb-cirkle-4" />
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <h1 className="sb-mb-30 mt-0 color-black">{this.props.t("restaurant_detail.about")} <span className="color">{this.state.resturants.merchant_restaurant.restaurant_name}</span></h1>
                    <p className="sb-text sb-mb-10">
                    {this.state.resturants.merchant_aboutus.description}
                    </p>
                    {/* <p className="sb-text sb-mb-10">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat.
                    </p>
                    <p className="sb-text sb-mb-10">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat.
                    </p> */}
                    <a href={this.state.resturants.merchant_location.website_url} className="btn btn-lg btn-bg-custom">{this.props.t("restaurant_detail.visit_website")}</a>
                    </div>
                </div>
                </div>
            </section>
            : ''
            }
            {this.state.resturants.merchant_foods.length > 0 ?
            <section id="portfolio" className="dark-wood white py-5">
                <div className="title header-line text-center">
                <div>
                    <h2 className="color">{this.props.t("restaurant_detail.food_type")}</h2>
                </div>
                <small className="mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua.</small>
                </div>
                <div className="container" id="food-type-top-section">
                <div className="row mt-5">
                {this.state.resturants.merchant_foods.map((f, index) => {
                            
                            return (
                                <div key={index} className={"col-md-2 col-xs-6-cus " + (index == 0 ? 'offset-md-2' :'') + " d-flex justify-content-around"}>
                                <div className="food-type-img">
                                    <img src={f.image_url} className="img-fluid" />
                                    <h5 className="text-center font-weight-bold mt-4">{f.displayName}</h5>
                                </div>
                                </div>
                               )
                            })
                        }
                    {/* <div className="col-md-2 col-xs-6-cus d-flex justify-content-around">
                    <div className="food-type-img">
                        <img src="https://media.istockphoto.com/photos/toast-with-avocado-and-egg-in-heart-shape-picture-id1046693924?b=1&k=20&m=1046693924&s=170667a&w=0&h=hntlfoxp2gK9BRGqgbLJOEQDw8Ae9aNroJKqE2DZ5OI=" className="img-fluid" />
                        <h5 className="text-center font-weight-bold mt-4">Italian</h5>
                    </div>
                    </div>
                    <div className="col-md-2 col-xs-6-cus d-flex justify-content-around">
                    <div className="food-type-img">
                        <img src="https://media.istockphoto.com/photos/heart-shape-eggs-in-toast-picture-id495061495?k=20&m=495061495&s=170667a&w=0&h=QNFtN4e6wqGe3W2rVPjsTVd-fE63VIO_AM699TLrmZ0=" className="img-fluid" />
                        <h5 className="text-center font-weight-bold mt-4">Vegetarian Options</h5>
                    </div>
                    </div>
                    <div className="col-md-2 col-xs-6-cus d-flex justify-content-around">
                    <div className="food-type-img">
                        <img src="https://images.unsplash.com/photo-1513442542250-854d436a73f2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwcm9maWxlLXBhZ2V8OHx8fGVufDB8fHx8&w=1000&q=80" className="img-fluid" />
                        <h5 className="text-center font-weight-bold mt-4">Vegan Options</h5>
                    </div>
                    </div> */}
                </div>
                </div>
            </section>
            : ''
            }
            {this.state.resturants.merchant_slider_images.length > 0 ?
            <section className="py-0">
                <div className="container" id="individiual-tow">
                <div className="padall0">
                    <div className="margintop60 padlr10">
                    <div className="cards-slider owl-carousel owl-theme owl-loaded owl-drag rounded-0">
                        <div className="owl-stage-outer rounded-0">
                        <div className="owl-stage rounded-0" style={{transform: 'translate3d(-4080px, 0px, 0px)', transition: 'all 0.25s ease 0s', width: 6120}}>
                        { this.state.resturants.merchant_slider_images.map((slider, index) => {
                            return(
                                <div className="owl-item" style={{width: 510}}>
                                    <div className="item-wrapper rounded-0">
                                        <a href="#">
                                        <img alt="Some desc" className="img-fluid rounded-0" src={slider.image_url} />
                                        </a>
                                    </div>
                                </div>
                                )
                            })
                        }
                            
                        </div>
                        </div>
                        <div className="owl-nav disabled">
                        <button type="button" role="presentation" className="owl-prev">
                            <span aria-label="Previous">‹</span>
                        </button>
                        <button type="button" role="presentation" className="owl-next">
                            <span aria-label="Next">›</span></button>
                        </div>
                        <div className="owl-dots disabled" />
                    </div>
                    </div>
                </div>
                </div>
            </section>
            : ''
            }           
            <section id="info" className="bg-white">
                <div className="container">
                <div className="row ">
                    <div className="col-sm-6  divider_right ">
                        {   this.state.resturants.merchant_restaurant_timing.length > 0 ?
                        <>
                        <h1 className="sb-mb-30 color-black mt-0">{this.props.t("restaurant_detail.opening")} <span className="color">{this.props.t("restaurant_detail.hours")}</span></h1>
                        <ul style={{columns: 2, textAlign: 'left'}}>
                        { this.state.resturants.merchant_restaurant_timing.map((timing, index) => {
                            return(
                                    <li>
                                    { timing.day_of_week == "Sunday" ?
                                        <h2 className="deco-font color margintop10">{timing.day_of_week}</h2>
                                      : <h4 className="margintop10">{timing.day_of_week}</h4>
                                    }
                                    <p>{timing.start_end_times[0].start_time} - {timing.start_end_times[0].end_time}</p>
                                    { 
                                        timing.is_closed == 0 ?
                                        <p>{timing.start_end_times[1].start_time} - {timing.start_end_times[1].end_time}</p>
                                        :<p>CLOSED</p>
                                    }
                                    </li>
                                    )
                                })
                            }
                           
                        </ul>
                        </>
                        : ''
                        }
                    </div>
                    <div className="col-md-6">
                    {   this.state.resturants.merchant_additional_feature.length > 0 ?
                        <>
                        <h1 className="sb-mb-10 color-black mt-0">{this.props.t("restaurant_detail.additional")} <span className="color">{this.props.t("restaurant_detail.info")}</span></h1>
                        <div className="col-md-6 d-flex justify-content-left p-0">
                        { this.state.resturants.merchant_additional_feature.map((af, index) => {
                            return(
                                af.is_available == 1 ?
                                <div key={index} className="img-bg-payment mr-1 p-3 rounded-circle bg-dark">
                                    <img src={"../../img/"+ af.feature +".png"} className="img-fluid" />
                                </div>
                                : ''
                                 )
                                })
                            }
                        <div  className="img-bg-payment mr-1 p-3 text-white">
                            s
                          </div>
                          <div  className="img-bg-payment mr-1 p-3 text-white">
                            s
                          </div>
                          <div  className="img-bg-payment mr-1 p-3 text-white">
                            s
                          </div>
                            {/* <div className="img-bg-payment mx-1 p-3 rounded-circle  bg-dark">
                            <img src="img/wifi-free.png" className="img-fluid" />
                            </div>
                            <div className="img-bg-payment mx-1 p-3 rounded-circle bg-dark">
                            <img src="img/delivery_new.png" className="img-fluid" />
                            </div>
                            <div className="img-bg-payment mx-1 p-3 rounded-circle bg-dark">
                            <img src="img/delivery_new.png" className="img-fluid" />
                            </div>
                            <div className="img-bg-payment mx-1 p-3 rounded-circle bg-dark">
                            <img src="img/delivery_new.png" className="img-fluid" />
                            </div> */}
                        </div>
                        </>
                        : ''
                    }
                     {   this.state.resturants.merchant_payment_types.length > 0 ?
                        <>
                        <h1 className="sb-mb-20 mt-5 color-black">{this.props.t("restaurant_detail.payment")} <span className="color">{this.props.t("restaurant_detail.types")}</span></h1>
                        <div className="col-md-6 d-flex justify-content-left p-0">
                        { this.state.resturants.merchant_payment_types.map((payment, index) => {
                            return(
                                <div key={index} className="img-bg-payment mr-1 p-3 rounded-circle bg-dark" style={{ lineHeight: 39 + "px"}}>
                                      <img src={payment.image_url} classname="img-fluid"  />
                                </div>
                             )
                            })
                        }
                          <div  className="img-bg-payment mr-1 p-3 text-white">
                            s
                          </div>
                          <div  className="img-bg-payment mr-1 p-3 text-white">
                            s
                          </div>
                          <div  className="img-bg-payment mr-1 p-3 text-white">
                            s
                          </div>
                        </div>
                        </>
                    :''
                     }
                      {   this.state.resturants.merchant_location !== undefined ?
                        <>
                        <h1 className="sb-mb-20 mt-5 color-black">{this.props.t("restaurant_detail.social")} <span className="color">{this.props.t("restaurant_detail.media")}</span></h1>
                        <div className="col-md-12 p-0">
                            <div className="d-flex justify-content-left p-0">
                            {this.state.resturants.merchant_location.social_media_url_twitter !== undefined ?
                            <div className="img-bg-payment text-center mr-1 bg-dark" style={{borderRadius: '100%', width: 50, lineHeight: "50px", fontSize: 25, height: 50}}>
                                <a href={this.state.resturants.merchant_location.social_media_url_twitter} className="text-white">
                                <i className="fa fa-twitter-square" />
                                </a>
                            </div>
                             : ''
                            }
                            {this.state.resturants.merchant_location.social_media_url_instagram !== undefined ?
                            <div className="img-bg-payment text-center mx-1 bg-dark" style={{borderRadius: '100%', width: 50, lineHeight: "50px", fontSize: 25, height: 50}}>
                                <a href={this.state.resturants.merchant_location.social_media_url_instagram} className="text-white">
                                <i className="fa fa-instagram" />
                                </a>
                            </div>
                            : ''
                            }
                            {this.state.resturants.merchant_location.social_media_url_facebook !== undefined ?
                            <div className="img-bg-payment text-center mx-1 bg-dark" style={{borderRadius: '100%', width: 50, lineHeight: "50px", fontSize: 25, height: 50}}>
                                <a href={this.state.resturants.merchant_location.social_media_url_facebook} className="text-white">
                                <i className="fa fa-facebook" />
                                </a>
                            </div>
                            : ''
                            }
                            {this.state.resturants.merchant_location.social_media_url_google !== undefined ?
                            <div className="img-bg-payment text-center mx-1 bg-dark" style={{borderRadius: '100%', width: 50, lineHeight: "50px", fontSize: 25, height: 50}}>
                                <a href={this.state.resturants.merchant_location.social_media_url_google} className="text-white">
                                <i className="fa fa-google-plus" />
                                </a>
                            </div>
                             : ''
                            }
                            {/* <div className="img-bg-payment mx-md-1 p-3 rounded-circle text-white">
                                s
                                 <img src="img/delivery_new.png" class="img-fluid"> 
                            </div>
                            <div className="img-bg-payment mx-md-1 p-3 rounded-circle text-white">
                                s
                                 <img src="img/delivery_new.png" class="img-fluid"> 
                            </div>
                            <div className="img-bg-payment mx-md-1 p-3 rounded-circle text-white">
                                s
                                 <img src="img/delivery_new.png" class="img-fluid"> 
                            </div> */}
                            </div>
                        </div>
                        </>
                        :''
                      }
                    </div>
                </div>
                </div>
            </section>
            { this.state.resturants.merchant_location.mapEmbedUrl !== undefined ?
            <section id="map" className="p-0">
                <iframe src={this.state.resturants.merchant_location.mapEmbedUrl} width="100%" height={550} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
            </section>
            :''
        }
        </div>


      </div>
    );
    }else{
        return (
            <>
             <div className="row justify-content-center align-items-center h-70">
                <Loading />
            </div>
            </>
        )
    }
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  let { restaurants } = state.restaurants;
  if(state.restaurants){
      
    restaurants = state.restaurants[1];
}
  return {
    isLoggedIn,
    message,
    restaurants: restaurants,
  };
}

export default compose(withTranslation(), connect(mapStateToProps,{
    getRestaurantDetailByslug
}))(Individual2);
