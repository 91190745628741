import {
  GET_RESTAURANT_CITIES,
 
    // DELETE_ALL_RATES,
  } from "../actions/types";
  
  const initialState = [];
  
  function citySearchReducer(cities = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_RESTAURANT_CITIES:
        return [...cities, payload];
  
    
  
      default:
        return cities;
    }
  }
  
  export default citySearchReducer;