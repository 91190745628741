import React, { Component } from "react";
//import { Redirect } from "react-router-dom";
//import * as yup from "yup";
//import Message from "./common/message";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
//import { login } from "../../../actions/auth";

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  render() {
    //const { message } = this.props;
    return (
      <>
        <div>
            <div className="sb-app">
                <div id="sb-dynamic-content" className="sb-transition-fade">
                <section className="sb-banner sb-banner-sm sb-banner-color">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="sb-main-title-frame">
                            <div className="sb-main-title text-center">
                            {/* <span class="sb-suptitle sb-mb-30">Pricing</span> */}
                            <div className="psbx_title text-center">
                                <div className="pricng_title">
                                <div className="pcng_l">{this.props.t("pricing.pricingTitle")}</div>
                                <div className="pcng_r">{this.props.t("pricing.take_seat")}</div>
                                </div>
                            </div>
                            <h1 className="sb-mb-30 color-black m-0">{this.props.t("pricing.take_seat_text")}
                                <span className="color">{this.props.t("pricing.trade")}</span></h1>
                            <p className="sb-text sb-text-lg sb-mb-30">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. <br /> Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                            </p>
                            <ul className="sb-breadcrumbs">
                                <li><Link to="/">{this.props.t("aboutUs.home")}</Link></li>
                                <li><Link to="/pricing">{this.props.t("pricing.pricingTitle")}</Link></li>
                            </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
                </div>
            </div>
            <section className="ftco-section bg-light">
                <div className="container">
                <div className="row">
                    <div className="col-md-4 hover-section">
                    <div className="block-7 box-shadow-basic">
                        <div className="img img-images-1" />
                        <div className="text-center p-4 box-basic">
                        <span className="excerpt d-block text-white"></span>
                        <div className="mh-250">{this.props.t("pricing.basic")}
                            <span className="price "><span className="number ">{this.props.t("pricing.free")}</span></span>
                            <ul className="pricing-text">
                            <li>{this.props.t("pricing.basic_text")}</li>
                            <li className="text-white">* {this.props.t("pricing.limited_services")}</li>
                            </ul>
                        </div>
                        <div className="row">
                            <a href="reg.html" className="btn btn-primary col ml-4 mr-1 px-2 py-3 btn-light btn-obile-size">{this.props.t("pricing.signUp")}</a>
                            <a href="#" className="btn btn-primary col mr-4 ml-1 px-2 py-3 btn-light btn-obile-size">{this.props.t("pricing.view_services")}</a>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-4 hover-section ">
                    <div className="block-7 box-shadow-premium">
                        <div className="img img-images-2" />
                        <div className="text-center p-4 box-premium">
                        <span className="excerpt d-block text-white">{this.props.t("pricing.premium")}</span>
                        <span className="price"><span className="number">3.99€ / {this.props.t("pricing.month")}</span></span>
                        <ul className="pricing-text mb-5">
                            <div className="btn-group btn-group-toggle" data-toggle="buttons">
                            <label className="btn btn-secondary-cus active">
                                <input type="radio" name="options" id="option1" autoComplete="off" defaultChecked /> {this.props.t("pricing.monthly")}
                            </label>
                            <label className="btn btn-secondary-cus">
                                <input type="radio" name="options" id="option2" autoComplete="off" /> {this.props.t("pricing.annual")}
                            </label>
                            </div>
                            <div className="form-group mt-5 for-promo-code">
                            <div className="input-group">
                                <input type="text" onclick="CopyIt()" className="form-control coupon" name placeholder="Promo Code :" />
                                <span className="input-group-append">
                                <button className="btn btn-light btn-apply coupon">{this.props.t("pricing.apply")}</button>
                                </span> 
                            </div>
                            </div>
                        </ul>
                        <div className="row">
                            <a href="reg.html" className="btn btn-primary col ml-4 mr-1 px-2 py-3 btn-light btn-obile-size">{this.props.t("pricing.signUp")}</a>
                            <a href="#" className="btn btn-primary col mr-4 ml-1 px-2 py-3 btn-light btn-obile-size">{this.props.t("pricing.view_services")}</a>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-4 hover-section ">
                    <div className="block-7 box-shadow-platinum">
                        <div className="img img-images-3" />
                        <div className="text-center p-4 box-platinum">
                        <span className="excerpt d-block text-white">{this.props.t("pricing.platinum")}</span>
                        <div className="d-flex align-items-center justify-content-center h-303">
                            <span className="price">
                            <span className="number">{this.props.t("pricing.coming_soon")}</span>
                            </span>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            <section id="table">
                <div className="container">
                <div className="row">
                    <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table bg-white table-bordered text-uppercase font-weight-bold">
                        <thead>
                            <tr>
                            <th className="btn-bg-custom border-0 p-0">
                                <h3 className="m-0 p-5">{this.props.t("pricing.full_plan_comparison")}</h3></th>
                            <th className="text-center text-white box-basic"><h5 className="m-0"><strong>{this.props.t("pricing.basic")}</strong> <br /><span>{this.props.t("pricing.free")}</span></h5></th>
                            <th className="text-center text-white box-premium"><h5 className="m-0"><strong>{this.props.t("pricing.premium")}</strong> <br /><span>3.99€ / {this.props.t("pricing.month")}</span></h5></th>
                            <th className="text-center text-white box-platinum"><h5 className="m-0"><strong>{this.props.t("pricing.platinum")}</strong><br /><span>{this.props.t("pricing.coming_soon")}</span></h5></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td className="pl-5">{this.props.t("pricing.standard_listing_on_search")}</td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            </tr>
                            <tr>
                            <td className="pl-5">{this.props.t("pricing.priority_listing_on_search")}</td>
                            <td className="text-center">-</td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            </tr>
                            <tr>
                            <td className="pl-5">{this.props.t("pricing.premium_advertising_page")}</td>
                            <td className="text-center">-</td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            </tr>
                            <tr>
                            <td className="pl-5">{this.props.t("pricing.food_classification")}</td>
                            <td className="text-center">X2</td>
                            <td className="text-center">X4</td>
                            <td className="text-center">X6</td>
                            </tr>
                            <tr>
                            <td className="pl-5">{this.props.t("pricing.cover_banner_text")}</td>
                            <td className="text-center">-</td>
                            <td className="text-center">-</td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            </tr>
                            <tr>
                            <td className="pl-5">{this.props.t("pricing.cover_banner_video")}</td>
                            <td className="text-center">-</td>
                            <td className="text-center">-</td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            </tr>
                            <tr>
                            <td className="pl-5">{this.props.t("pricing.restaurant_picture")}</td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            </tr>
                            <tr>
                            <td className="pl-5">{this.props.t("pricing.display_menu")}</td>
                            <td className="text-center">-</td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            </tr>
                            <tr>
                            <td className="pl-5">{this.props.t("pricing.website_link")}</td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            </tr>
                            <tr>
                            <td className="pl-5">{this.props.t("pricing.address_tel")}</td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            </tr>
                            <tr>
                            <td className="pl-5">{this.props.t("pricing.opening_hours")}</td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            </tr>
                            <tr>
                            <td className="pl-5">{this.props.t("pricing.restaurant_photo")}</td>
                            <td className="text-center">X4</td>
                            <td className="text-center">X10</td>
                            <td className="text-center">X15</td>
                            </tr>
                            <tr>
                            <td className="pl-5">{this.props.t("pricing.wifi_zone")}</td>
                            <td className="text-center">-</td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            </tr>
                            <tr>
                            <td className="pl-5">{this.props.t("pricing.customer_payment_option")}</td>
                            <td className="text-center">-</td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            </tr>
                            <tr>
                            <td className="pl-5">{this.props.t("pricing.restaurat_synosis")}</td>
                            <td className="text-center">-</td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            </tr>
                            <tr>
                            <td className="pl-5">{this.props.t("pricing.delivery_status")}</td>
                            <td className="text-center">-</td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            </tr>
                            <tr>
                            <td className="pl-5">{this.props.t("pricing.location_map")}</td>
                            <td className="text-center">-</td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            </tr>
                            <tr>
                            <td className="pl-5">{this.props.t("pricing.social_media_link")}</td>
                            <td className="text-center">-</td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            <td className="text-center"><i className="fa fa-check-square-o" aria-hidden="true" /></td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
            </section>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default compose(withTranslation(), connect(mapStateToProps))(Pricing);
