import React, { Component } from "react";
import { Link } from "react-router-dom";
import { _pre } from "../../utils/vlogs";
import i18n from "../../i18n/i18n";
import { getSearchedCityData } from "../../actions/city_search";
import { getCityData } from "../../actions/restaurant-search";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {userChatLogin} from "../../actions/user";
//import CommonHelper from "../../utils/CommonHelper";
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import configData from "../../config.json";
import debounce from 'lodash.debounce';

//import $ from "jquery";

class AppHeader extends Component {
  constructor(props) {
    super(props);
  

    if (localStorage.getItem("lan")) {
      this.state = {
        selectedLang: localStorage.getItem("lan"),
        isHomePage: true,
        serchedCities:[],
        allCity: [],
        searchValue: "",
        selectedCity: "",
        showDropdown:false,
      };
    } else {
      this.state = {
        selectedLang: "en",
        isHomePage: true,
        serchedCities:[],
        searchValue: "",
        allCity: [],
        selectedCity: "",
        showDropdown:false,
      };
    }

    this.sideBarToggle = this.sideBarToggle.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearchedCity = this.handleSearchedCity.bind(this);
    this.handleChatLogin =     this.handleChatLogin.bind(this);

    
    this.onChangeDebounced = debounce(this.onChangeDebounced, 2000)
  }

  async componentDidMount() {
    await this.sideBarToggle();
    await this.getSerchedCity();
 
  }


  componentDidUpdate(prevProps, prevState) {

    if (localStorage.getItem("lan") !== this.state.selectedLang) {
      this.setState({
        selectedLang:localStorage.getItem("lan"),
      });
     
    } 

    if(localStorage.getItem('city_id') !== this.state.selectedCity){
      this.setState({
        selectedCity: localStorage.getItem('city_id'),
      });
    }
    if (this.props.isHomePage !== this.state.isHomePage) {
      // console.log('did update');
    }
  }


  sideBarToggle() {
    /* const script = document.createElement("script");
    script.src = "/assets/theme_assets/js/main.js";
    script.async = true;
    document.body.appendChild(script); */
    console.log("sideBarTogglesideBarToggle");
  }

  changeLanguage(value) {
    this.setState({
      selectedLang: value,
    });

    localStorage.setItem("lan", value);

    i18n.changeLanguage(value);
  }


  async handleChange(e){
    // this is the search text
  
      this.setState({
        searchValue: e.target.value,
      });

      this.onChangeDebounced(e)
  
  }

  async getSerchedCity(){
    let data = {
      type: 'cities_of_merchants',
    }

    await this.props.getCityData(data);


    this.setState({
      allCity: this.props.restaurantsearch.cities,
    });


  }


  async onChangeDebounced(e){
    await this.props.getSearchedCityData(this.state.searchValue);

    this.setState({
      serchedCities: this.props.citiesAuto,
      showDropdown:true,
    });
  }



  async handleSearchedCity(e){
    let city = e.target.getAttribute('data-id');

    

    let cities = this.state.allCity.filter((ct, index) => {
     
      if(ct.name == city){
        return ct;
      }
    });

    // console.log(cities);
  if(cities.length > 0){

      this.setState({
        selectedCity: city,
        searchValue:cities[0].name,
        serchedCities:[],
        showDropdown: false,
      });


      localStorage.setItem('city_id', cities[0].encrypted_id);
      localStorage.setItem('subregion_id', cities[0].encrypted_subregion_id);
      localStorage.setItem('region_id', cities[0].encrypted_region_id);
      // const newList = this.state.defaultCities.filter((i, itemIndex) => index !== itemIndex) 
      const { history } = this.props;
      // console.log(e.target);
      history.push("/search-listing");
    }

  }

  scrollToSection = (e, sectionId) => {
    e.preventDefault();
  
    const targetSection = document.getElementById(sectionId);
  
    if (targetSection) {
      window.scrollTo({
        top: targetSection.offsetTop - 100, // Adjust the offset as needed
        behavior: 'smooth',
      });
    }
  };

  handleChatLogin(){
    // console.log(e.currentTarget.getAttribute('data-id'));
    let formdata = new FormData();
    let user = JSON.parse(localStorage.getItem("user"));
    formdata.append(`email`, user.email);

    this.props
    .userChatLogin(formdata)
    .then((data) => {
        if (data.status) { 
          window.open(data.data.auto_login_url, '_blank');
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
        this.setState({
          loading: false,
        });
    })
    .catch((e) => {
      console.log(e);
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    // console.log(window.location.origin);
    //const { currentUser, toggled, isHomePage } = this.props;
    const { isHomePage, isLoggedIn } = this.props;
    // console.log(this.props, 'header page');
    //let buttonClass = toggled ? "add-padding1" : "";
    //let toggleClass = toggled ? "margin-left-add" : "margin-left-add1";
    //_pre(" buttonClass ", buttonClass);
    //_pre(" t ", this.props.toggled);
    let sectionClass = isHomePage ? "" : "login-page-header";
    let mainNavClass = this.props.history.location.pathname === '/'  ? "nav-bottom " : "";
    console.log(this.props.history.location.pathname );
    if(this.props.history.location.pathname !== '/authetication' && this.props.history.location.pathname !== '/login' && this.props.history.location.pathname !== '/register'){
      return (
        // <!-- start header area -->
          <header className="header--sticky header-one header-four ">
        {/* <div class="header-top header-top-one header-top-four">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <p class="top-left">Are you ready to grow up your business? <a href="contactus.html">Contact Us
                                    <i class="far fa-arrow-right"></i></a></p>
                        </div>
                        <div class="col-lg-6 right-h-three">
                            <div class="header-top-right">
                                <div class="single-right email">
                                    <i class="fas fa-envelope"></i>
                                    <a href="#">info@example.com</a>
                                </div>
                                <div class="single-right call">
                                    <i class="far fa-phone-volume"></i>
                                    <span>Hotline:</span>
                                    <a href="#">+210-9856988</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        <div className="header-main-one bg-soft-purple">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6">
                <div className="thumbnail">
                  <a href="index.html">
                    <img src="assets/images/logo/kardarma-logo.png" alt="kardarma-logo" />
                  </a>
                </div>
              </div>
              <div className=" col-xl-9 col-lg-8 col-md-8 col-sm-8 col-6">
                <div className="main-header main-header-four">
                  <nav className="nav-main mainmenu-nav d-none d-xl-block">
                    <ul className="mainmenu custom-btn">
                      <li><a href="#" onClick={(e) => this.scrollToSection(e, 'banner')}>Home</a></li>
                      <li><a href="#" onClick={(e) => this.scrollToSection(e, 'about-us')}>About Us</a></li>
                      <li><a href="#" onClick={(e) => this.scrollToSection(e, 'features')}>features</a></li>
                      <li><a href="#" >testimonial</a></li>
                      <li><a href="#" onClick={(e) => this.scrollToSection(e, 'pricing')}>pricing</a></li>
                      <li><a href="#" onClick={(e) => this.scrollToSection(e, 'faq')}>faq</a></li>
                      <li><a href="#" onClick={(e) => this.scrollToSection(e, 'contact_us')}>contact</a></li>
                      { !isLoggedIn ?
                      <li>
                        <Link to="/login" className="rts-btn-cus btn-cus-menu ">Login</Link>
                        </li>
                        :
                        <li className="has-droupdown">
                          <a className="nav-link" href="#">
                            <i className="fa fa-user" style={{background: '#fff', padding: 5, borderRadius: 100}} />
                          </a>
                          <ul className="submenu">
                            <li><Link to="\">My Profile</Link></li>
                            <li> <a href="#!"
            
                onClick={(e) => this.handleChatLogin(e)}>Chat</a></li>
                            <li onClick={this.props.onClick}><a href="#">Logout</a></li>
                          </ul>
                        </li>
                       }
                    </ul>
                  </nav>
                  <div className="button-area d-block d-sm-none">
                    <div className="search-input-area">
                      <div className="container">
                        <div className="search-input-inner">
                          <div className="input-div">
                            <input id="searchInput1" className="search-input" type="text" placeholder="Search by keyword or #" />
                            <button><i className="far fa-search" /></button>
                          </div>
                          <div id="close" className="search-close-icon"><i className="far fa-times" /></div>
                        </div>
                      </div>
                    </div>
                    <a href="#" className="rts-btn btn-primary btn-primary-4 ml--20 ml_sm--5 header-one-btn quote-btn">Book
                      a
                      Meeting</a>
                    <button id="menu-btn" className="menu rts-btn btn-primary-alta btn-primary-alta-four ml--20 ml_sm--5">
                      <img className="menu-dark" src="assets/images/icon/menu.png" alt="Menu-icon" />
                      <img className="menu-light" src="assets/images/icon/menu-light.png" alt="Menu-icon" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          </header>

        // <!-- End header area -->
      );
          }else{
            return(
              <>
              </>
            )

          }
  }
}


function mapStateToProps(state) {

  const { isLoggedIn } = state.auth;

  let { citiesAuto } = state.citiesAuto;
  if(state.citiesAuto){
    citiesAuto = state.citiesAuto[0];
  }
  let { restaurantsearch } = state.restaurantsearch;
    if(state.restaurantsearch){
    restaurantsearch = state.restaurantsearch[1];
    }

  return {
      isLoggedIn,
      citiesAuto:citiesAuto,
      restaurantsearch: restaurantsearch
  };
 
}

export default withTranslation()(withRouter(connect(mapStateToProps, {
  getSearchedCityData,
  getCityData,
  userChatLogin,
})(AppHeader)));

//  export default connect(mapStateToProps, mapDispatchToProps)(Eventdetail)
// export default withTranslation()(withRouter(AppHeader));
//export default Header;
