import React, { Component } from "react";
//import { Redirect } from "react-router-dom";
//import * as yup from "yup";
//import Message from "./common/message";
import { Link } from "react-router-dom";

import { withTranslation } from "react-i18next";
import { getRestaurantDetailByslug } from "../../../actions/restaurants";
import { connect } from "react-redux";
import { compose } from "redux";
import $ from 'jquery'; 

//import { login } from "../../../actions/auth";

class ViewMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
       si: -1,
       z:1,
       resturants: [],
       first: "",
       last: "",
       chunk: [],
    };

    this.turnRight = this.turnRight.bind(this);
    this.turnLeft = this.turnLeft.bind(this);
    this.backTo = this.backTo.bind(this);
    
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.getRestaurantByDetail();
  }


  async getRestaurantByDetail()
  {
    let slug = this.props.match.params.slug;
    await this.props.getRestaurantDetailByslug(slug);

    console.log(this.props, "kkkkkkkk");
    

    let allData = this.props.restaurants.merchant.merchant_menu_images;
    let first = allData.shift();
    let last = allData.pop();
    let chunk = [];
    const chunkSize = 2;
    for (let i = 0; i <  this.props.restaurants.merchant.merchant_menu_images.length; i += chunkSize) {
        chunk.push(this.props.restaurants.merchant.merchant_menu_images.slice(i, i + chunkSize));
        // do whatever
    }

    this.setState({
        resturants:this.props.restaurants.merchant,
        first: first,
        last:last,
        chunk: chunk,
    });


  } 

  turnRight(){

    console.log(this.state.si, "Fffffff");
    var right = $(".right");
    var si = this.state.si == -1 ? right.length : this.state.si;
    console.log(si);
    var z = this.state.z;
    if(si>=1){
        console.log(si);
        si--;
    }
    else{
        console.log(si,"else");
        si=right.length-1;
        function sttmot(i){
            setTimeout(function(){right[i].style.zIndex="auto";},300);
        }
        for(var i=0;i<right.length;i++){
            right[i].className="right";
            sttmot(i);
            z=1;
        }
    }
    right[si].classList.add("flip");
    z = z+1;
    right[si].style.zIndex=z;
    this.setState({
        z:z,
        si:si
    });

  }

  turnLeft(){

    var right = $(".right");
    var si = this.state.si == 0 ? right.length  : this.state.si;
    
    if(si<right.length){
        si++;
    }
    else{
        si=1;
        for(var i=right.length-1;i>0;i--){
            right[i].classList.add("flip");
            right[i].style.zIndex=right.length+1-i;
        }
    }
    right[si-1].className="right";
    setTimeout(function(){right[si-1].style.zIndex="auto";},350);

    this.setState({
        si:si
    });

  }

  backTo()
  {
    const { history } = this.props;
   let url = "/resturants/"+this.state.resturants.merchant_restaurant.restaurant_slug + "/" + this.state.resturants.merchant_restaurant.registration_mode;
    history.push(url);

  }




  render() {

   
    //const { message } = this.props;
    if(this.state.resturants.length > 0 || this.state.resturants.merchant_menu_images !== undefined ){
       
        console.log(this.state.resturants, "ffffffffsssfffffffffffff");
      
        return (
    <div className="book-section" style={{background: 'url(../../img/dark_wood.png)'}}>
        <div className=" modal-title d-flex justify-content-around">
            <h3 className="m-0">{this.state.resturants.merchant_restaurant.restaurant_name}</h3>
            <button className="btn btn-bg-custom" onClick={this.backTo}>RETURN TO MAIN ADVERT</button>
            </div>

    <div className="container">
    <div className="right" >
     <figure className="back" id="back-cover" />
     { this.state.last !== undefined ?
        <figure className="front" style={{ backgroundImage: 'url('+this.state.last.image_url+')', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center'}} />
        : ''
     }
        </div>

    {this.state.chunk.length > 0 ?
     this.state.chunk.map((f, index) => {
        return (
            <div className="right" key={index}>
                <figure className="back" style={{backgroundImage: 'url('+f[1].image_url+')', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center'}} />
                <figure className="front" style={{backgroundImage: 'url('+f[0].image_url+')', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center'}} />
             </div>
            )
          })

        :""
      }


            
    <div className="right" >
    { this.state.first !== undefined ?
        <figure className="back" style={{ backgroundImage: 'url('+this.state.first.image_url+')', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center'}} />
        : ''
    }
        <figure className="front" id="cover">
            <h1 className="mb-0">{this.state.resturants.merchant_restaurant.restaurant_name}</h1>
            <p>Phone:<a href="tel:+34951991450" className="text-dark">+{this.state.resturants.merchant_location.phone_number1_display}</a></p>
        </figure>
    </div>

       
        
    </div>
    <div className="clearfix" />
    <button onClick={this.turnLeft}>Prev</button> <button onClick={this.turnRight}>Next</button>
    </div>

        );
    }else{
        return (
            <>
            </>
        );
    }
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  let { restaurants } = state.restaurants;
  if(state.restaurants){
      
    restaurants = state.restaurants[1];
}
  return {
    isLoggedIn,
    message,
    restaurants: restaurants,
  };
}

export default compose(withTranslation(), connect(mapStateToProps,{
    getRestaurantDetailByslug
}))(ViewMenu);
