import axios from "axios";
import authHeader from "./auth-header";
import configData from "../config.json";
//const API_URL = "http://localhost:8080/api/test/";
const apiEndpoint = configData.apiUrl + "/restaurant";

class RestaurantSearchService {
  

  getData() {
    return axios.get(apiEndpoint + `/search`);
    //return axios.get(apiEndpoint, { headers: authHeader() } );
  }


  getSubregion(id) {
    return axios.post(apiEndpoint + `/search/subregions`, {region_id: id});
    //return axios.get(apiEndpoint, { headers: authHeader() } );
  }

  getCityData(data){
    return axios.post(apiEndpoint + `/search/cities`, data,);
  }

 

}

export default new RestaurantSearchService();
