import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";

import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import configData from "../../../config.json";
const apiEndpoint = configData.apiUrl;

function Blog() {
  const [items, setItems] = useState([]);

  const [pageCount, setpageCount] = useState(0);

  let limit = 3;

  useEffect(() => {
    const getBlogs = async () => {
      let selectedLanguage = "en";
      if(localStorage.getItem("lan")){
          selectedLanguage = localStorage.getItem("lan");
      }
      else{
          selectedLanguage = "en";
      }
      const config = {
          headers: {
              'Accept-Language': selectedLanguage,
          }
      };

      axios.get(apiEndpoint + `/home/blogs?_page=1&_limit=${limit}`, config).then((response) => {
        let data = [...response.data.data.blogs];
        setItems(data);

        setpageCount(response.data.data.totalPages);
      });
    };

    getBlogs();
  }, [limit]);

  const handlePageClick = async (data) => {
    console.log(data.selected);

    let currentPage = data.selected + 1;

    let selectedLanguage = "en";
    if(localStorage.getItem("lan")){
        selectedLanguage = localStorage.getItem("lan");
    }
    else{
        selectedLanguage = "en";
    }
    const config = {
        headers: {
            'Accept-Language': selectedLanguage,
        }
    };

    axios.get(apiEndpoint + `/home/blogs?_page=${currentPage}&_limit=${limit}`, config).then((response) => {
      let data = [...response.data.data.blogs];
      setItems(data);
    });
  };
  return (
    <>
      <div className="sb-app">
      <div id="sb-dynamic-content" className="sb-transition-fade">
          <section className="sb-banner sb-banner-xs sb-banner-color pb-5">
          <div className="container">
              <div className="row">
              <div className="col-lg-12">
                  <div className="sb-main-title-frame pt-5 pb-0">
                  <div className="sb-main-title">
                      <h1 className="sb-h2 mt-0">Zampoita <span className="color">Blogs</span></h1>
                      <ul className="sb-breadcrumbs">
                      <li><Link to={`/`}>Home</Link></li>
                      <li><a href="#.">Blog</a></li>
                      </ul>
                  </div>
                  </div>
              </div>
              </div>
          </div>
          </section>
          <section className="sb-blog-list sb-p-90-90">
          <div className="sb-bg-1">
              <div />
          </div>
          <div className="container">
              <div className="sb-mb-60">
              <h2 className="sb-cate-title sb-mb-15 mt-0">Lorem ipsum <span className="color">dolor</span></h2>
              <p className="sb-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod<br />
                  tempor incididunt ut labore et dolore magna aliqua.</p>
              </div>
              <div className="sb-masonry-grid">
              <div className="sb-grid-sizer" />
              {items.length > 0 ? items.map((value, index, array) => {
                  return (
                      <div className="sb-grid-item sb-item-33" key={value.encrypted_id}>
                          <a href="#" className="sb-blog-card">
                          <div className="sb-cover-frame sb-mb-30">
                              <img src={value.imageUrl} alt="cover" />
                          </div>
                          <div className="sb-blog-card-descr">
                              <h4 className="sb-mb-10">{value.displayBlogName}</h4>
                              <div className="sb-suptitle sb-mb-15"><span>{value.createdDateFormatted}</span></div>
                              <p className="sb-text">{value.displayShortDescription}</p>
                              <Link className="btn btn-bg-custom my-3" to={`/blog/${value.blog_slug}`}>Read More</Link>
                          </div>
                          </a>
                      </div>
                  )
              }) : ''
              }
              </div>
              <div>
              {
                items.length > 0 ? (
                  <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"sb-pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"sb-active"}
                  />
                ) : ''
              }
              </div>
          </div>
          </section>
      </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default compose(withTranslation(), connect(mapStateToProps))(Blog);