import Moment from 'moment';
import React from "react";

export function _pre(msg, data) {
  console.log("==== " + msg + "======");
  console.log(data);
  console.log("----------------------");
}

export function _nameInitials(string) {
  return string
    .split(" ")
    .map((n) => n[0])
    .join(".");
}

export function _getformatDate(date, format){

  const d = new Date(date);
  return Moment(d).format(format);
  
}

export function _arrayOfAlphabet(){
    return [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];
}


export function _paginationComponentOptions(rowsPerPage,rangeSeparator ){
  
  return  {
    rowsPerPageText: rowsPerPage,
    rangeSeparatorText: rangeSeparator,
    selectAllRowsItem: true,
    selectAllRowsItemText: 'ALL',
  };
}



export function _generateTables(data, type, reservationData){

  let restaurantTables = '';
  if(data[0] !== undefined ){

   restaurantTables = data[0].list;
  }else{
     restaurantTables = data.list;
  }
 
  if(restaurantTables){


    const table = restaurantTables.map(tables => {

      let clsTableConditionActive = tables.status === 1 ?
          'table_configuration'
          : tables.status === 2 ?
          'table_configuration_inactive'
          : 'table_configuration_inherit'

      let ClsConditionWise;
      ClsConditionWise = tables.table_shape === 0 &&  tables.table_size === 0 ?
            'small_horizontal'
            : tables.table_shape === 1 &&  tables.table_size === 0 ? 
            'small_vertical'
            : tables.table_shape === 2 &&  tables.table_size === 0 ? 
            'circle_small'
            :tables.table_shape === 0 &&  tables.table_size === 1 ? 
            'medium_hori'
            :tables.table_shape === 1 &&  tables.table_size === 1 ? 
            'medium'
            :tables.table_shape === 2 &&  tables.table_size === 1 ? 
            'circle_medium'
            :tables.table_shape === 0 &&  tables.table_size === 2 ? 
            'big_hori'
            :tables.table_shape === 1 &&  tables.table_size === 2 ? 
            'big'
            :tables.table_shape === 2 &&  tables.table_size === 2 ? 
            'circle_big'
            :''

        let ClsSpanConditionWise;
        ClsSpanConditionWise = tables.table_size === 0 && tables.table_shape !== 2 ?
              ' span_small '
              : tables.table_size === 1 && tables.table_shape !== 2 ? 
              'medium_span '
              :tables.table_size === 2 && tables.table_shape !== 2 ? 
              'big_span'
              :tables.table_shape === 2 &&  tables.table_size === 0 ? 
              'circle_small_span'
              :tables.table_shape === 2 &&  tables.table_size === 1 ? 
              'circle_medium_span'
              :tables.table_shape === 2 &&  tables.table_size === 2 ? 
              'circle_big_span'
              :''
      return (
      
        <div key={tables.id} className={`${clsTableConditionActive} tisch ${ClsConditionWise}`} 
        style={{
          position: "absolute",
          left: `${tables.x_pos}px`,
          top: `${tables.y_pos}px`
        }}>
          {tables.table_number} <br/> 
          <span className={`${ClsSpanConditionWise}`}>{tables.min_space}-{tables.max_space} </span>
        </div>
      
      );
    });
   
    return table;
  }
}
