import {
    GET_RESTAURANT_SEARCH_DATA,
    SET_MESSAGE,
  } from "./types";
  import CommonHelper from "../utils/CommonHelper";
  
  import RestaurantSearchDataService from "../services/resturant_search.service";
  // import { _pre } from "../utils/vlogs";
  
  export const getSearchData = () => async (dispatch) => {
    try {
      const res = await RestaurantSearchDataService.getData();
        
      console.log(res.data.data, 'getregion api');
      dispatch({
        type: GET_RESTAURANT_SEARCH_DATA,
        payload: res.data.data,
      });
  
      /* const resMessage = {
        message: res.data.message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      }); */
      return Promise.resolve(res.data);
    } catch (error) {

      // console.log('in error of create restaurant');

      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
  
    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    // console.log(resMessage, 'restaurant');

    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
      return Promise.reject(error);
    }
  };


  export const getProvinceData = (id) => async (dispatch) => {
    try {
      const res = await RestaurantSearchDataService.getSubregion(id);
        
      console.log(res.data.data, 'getSubregion api');
      dispatch({
        type: GET_RESTAURANT_SEARCH_DATA,
        payload: res.data.data,
      });
  
      /* const resMessage = {
        message: res.data.message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      }); */
      return Promise.resolve(res.data);
    } catch (error) {

      // console.log('in error of create restaurant');

      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
  
    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    // console.log(resMessage, 'restaurant');

    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
      return Promise.reject(error);
    }
  };

  export const getCityData = (id) => async (dispatch) => {
    try {
      const res = await RestaurantSearchDataService.getCityData(id);
        
      console.log(res.data.data, 'getcityregion api');
      dispatch({
        type: GET_RESTAURANT_SEARCH_DATA,
        payload: res.data.data,
      });
  
      /* const resMessage = {
        message: res.data.message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      }); */
      return Promise.resolve(res.data);
    } catch (error) {

      // console.log('in error of create restaurant');

      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
  
    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    // console.log(resMessage, 'restaurant');

    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
      return Promise.reject(error);
    }
  };

  
