import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { getSearchData,getProvinceData,getCityData } from "../../actions/restaurant-search";
import { getRestaurant,getRestaurantList } from "../../actions/restaurants";
import Loading from "../common/loading";
import { connect } from "react-redux";
import { compose } from "redux";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Select from 'react-select'


 
class SearchListing extends Component {
  constructor(props) {
    super(props);
    

    this.state = {
        page: 1,
        limit: 12,
        selectedvalue:{},
        restaurant_type_id:  localStorage.getItem('resturant_type_id'),
        region_id: localStorage.getItem('region_id'),
        subregion_id: localStorage.getItem('subregion_id'),
        city_id: localStorage.getItem('city_id'),
        sort_by_delivery: "",
        foods: [],
        region: [],
        cities: [],
        subregions: [],
        foodsDropdown: [],
        foodSliderData: [],
        resturants:"",
        activeFoodType: 0,
        loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubRegionChange = this.handleSubRegionChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleFoodTypeChange = this.handleFoodTypeChange.bind(this);
    this.logChange = this.logChange.bind(this);


  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.fetchSearchData();
    await this.fetchRestaurant();
    await this.fetchRestaurantList();
    console.log(this.state);
  }

  async componentDidUpdate() {

    if(localStorage.getItem('city_id') !== this.state.city_id){
        this.setState({ 
            city_id: localStorage.getItem('city_id'),
            region_id: localStorage.getItem('region_id'),
            subregion_id: localStorage.getItem('subregion_id'),
        });
        await this.fetchRestaurantList();
    }
   }


  fetchSearchData = async () =>{
    this.setState({ loading: true });
   
    await this.props.getSearchData();

    console.log(this.props);
    this.setState({ 
        region: this.props.restaurantsearch.regions,
        cities: this.props.restaurantsearch.cities,
    });

    await this.props.getProvinceData('');
    this.setState({ 
        loading: false,
        subregions: this.props.restaurantsearch.subregions,
    });

 }

  async handleChange(e){
    console.log(e.target.value, "Ddddddd");
        let region_id = e.target.value
    if(e.target.value !== ''){
        localStorage.setItem('region_id', region_id);

        this.setState({ loading: true });
        await this.props.getProvinceData(region_id);

        this.setState({ 
            loading: false,
            region_id: region_id,
            subregions: this.props.restaurantsearch.subregions,
        });
    }

 }

 async handleSubRegionChange(e){

    let subregion_id = e.target.value;
    if(e.target.value !== ''){
        localStorage.setItem('subregion_id', e.target.value);

        this.setState({ loading: true });
        let data = {
            subregion_id : e.target.value,
            type: 'cities',
        }
        await this.props.getCityData(data);

        this.setState({ 
            loading: false,
            subregion_id:subregion_id,
            cities: this.props.restaurantsearch.cities,
            city_id: this.props.restaurantsearch.cities[0].encrypted_id,
        });
        await this.fetchRestaurant();
        await this.fetchRestaurantList();
    }

 }


 async handleCityChange(e){
    console.log(e.target.value, "Ddddddd");
    let city_id = e.target.value;
    localStorage.setItem('city_id', e.target.value);
    if(e.target.value !== ''){
        this.setState({ 
            loading: false,
            city_id: city_id,
        });
       

    }
    await this.fetchRestaurant();
    await this.fetchRestaurantList();
    
 

  
 }

 async handleTypeChange(e){
    let resturant_type_id = e.target.getAttribute('data-id');
    localStorage.setItem('resturant_type_id', resturant_type_id);

        this.setState({ 
            loading: false,
            restaurant_type_id: resturant_type_id,
        });
    
    await this.fetchRestaurant();
    await this.fetchRestaurantList();
 }

 async handleFoodTypeChange(e){

    let food_type = [];
     food_type.push(e.target.getAttribute('data-id'));
     let food_type_selected = [];
     food_type_selected[0] = {
        'value' : e.target.getAttribute('data-id'),
        'label' : e.target.getAttribute('data-value'),
     };

     console.log(food_type_selected);
        this.setState({ 
            loading: false,
            foods: food_type,
            activeFoodType: e.target.getAttribute('data-id'),
            selectedvalue:food_type_selected,

        }, () => {
            this.fetchRestaurantList();
        });
    
    // await this.fetchRestaurant();
 
    
 

  
 }

 async fetchRestaurant(){
    this.setState({ loading: true });
  
    let data = {
        page: this.state.page,
        limit:this.state.limit,
        restaurant_type_id:this.state.restaurant_type_id,
        region_id:localStorage.getItem('region_id'),
        subregion_id:localStorage.getItem('subregion_id'),
        city_id:localStorage.getItem('city_id'),
        sort_by_delivery:this.state.sort_by_delivery,
        foods:[],
    }   

    
    await this.props.getRestaurant(data);

    // console.log(this.props, "sssssssssssssssssssssssss");
    this.setState({ 
        loading: false,
        foodsDropdown:this.props.restaurantsFoods.foodsFilterDropdown,
        foodSliderData:this.props.restaurantsFoods.foodsSliderData,
    });


 }

 async fetchRestaurantList(){

    this.setState({ loading: true });
    let data = {
        page: this.state.page,
        limit:this.state.limit,
        restaurant_type_id:this.state.restaurant_type_id,
        region_id:this.state.region_id,
        subregion_id:this.state.subregion_id,
        city_id:localStorage.getItem('city_id'),
        sort_by_delivery:this.state.sort_by_delivery,
        foods: this.state.foods,
    }   
    await this.props.getRestaurantList(data);
    // console.log(this.props, "sssssssssssssssssssssssssxx");

    this.setState({ 
        loading: false,
        resturants:this.props.restaurantsFoods,
    });
 }


 async logChange(val) {
    if(val.length > 0){
        let food_type = this.state.foods;
        
        food_type.push(val[0].value);
            console.log(val[0].value);
        this.setState({ 
            loading: false,
            selectedvalue:val,
            foods:food_type,
        },() => {
            this.fetchRestaurantList();
        });
    }else{
        this.setState({ 
            loading: false,
            selectedvalue:[],
            foods:[],
            activeFoodType: 0,
        },() => {
            this.fetchRestaurantList();
        });
    }
   
  }
  
   
 

  render() {

   
    console.log(this.state, "sssssssssssssssssssssss222");
    //const { isLoggedIn, message } = this.props;
    const { isLoggedIn } = this.props;


    const res = {
        
            0:{
                items:1,
                nav:true
            },
            600:{
                items:3,
                nav:false
            },
            1000:{
                items:2,
                nav:true,
                loop:true
            }
        
    }

    const options = {
        items: 1,
        nav: true,
        navText:["<div className='nav-btn prev-slide'></div>","<div className='nav-btn next-slide'></div>"],
        rewind: true,
        autoplay: true,
        slideBy: 1,
        dots: true,
        dotsEach: true,
        dotData: true
      };
    return (
      <>
        <div className="blog-single gray-bg">
            <div className="container">
                <div className="row align-items-start">
                <div className="col-lg-3 m-15px-tb blog-aside sticky-top">
                    <div className="widget widget-tags d-none d-sm-block d-sm-none d-md-block">
                    <div className="widget-title text-center btn-bg-custom">
                        <h3 className="text-white">{this.props.t('searchPage.filterBy')}</h3>
                    </div>
                    <div className="widget-body">
                        <div className="nav tag-cloud d-flex justify-content-center">
                        <div className="mb-3">
                            <img src="img/city-loc.png" className="img-fluid" />
                        </div>
                        <button className="btn btn-block">{this.state.resturants !== "" ? this.state.resturants.countCity : '' }  </button>
                        { this.state.resturants !== "" &&
                          this.state.resturants.allRestaurantTypeData.length > 0 ?
                          this.state.resturants.allRestaurantTypeData.map((r, index) => {
                            return (
                                    <button type="button" onClick={this.handleTypeChange} key={index} className={"btn btn-bg-custom-res btn-block " + (r.encrypted_id == this.state.restaurant_type_id ? 'active' : ' ')}  data-id={r.encrypted_id}>{r.name} <span>({r.total})</span></button>
                            )
                          })
                          : ''
                        }
                        {/* <button className="btn btn-bg-custom btn-block">Tapas <span>(80)</span></button>
                        <button className="btn btn-bg-custom btn-block">Take Aways <span>(50)</span></button> */}
                        <select className="form-control border mb-1 mt-3" value={this.state.region_id}  onChange={this.handleChange}>
                            <option value={0} >{this.props.t('searchPage.selectRegion')}</option>
                            { this.state.region.length > 0 ?
                                this.state.region.map((r, index) => {
                                    return (
                                            <option  key={index} value={r.encrypted_id} data-id={r.id}>{r.displayName}</option>
                                            )
                                        })
                                : ''
                            }
                        </select>
                        <select className="form-control border my-1" value={this.state.subregion_id}  onChange={this.handleSubRegionChange}>

                        {   this.state.subregions.length > 0 ?
                                this.state.subregions.map((s, index) => {
                                    return (
                                    
                                            <option  key={index} value={s.encrypted_id} data-id={s.id}>{s.displayName}</option>
                                            )
                                        })
                                : <option value={0} >{this.props.t('searchPage.selectProvince')}</option>
                            }
                            
                        </select>
                        <select className="form-control border my-1"  value={this.state.city_id}  onChange={this.handleCityChange}>
                        { this.state.cities.length > 0 ?
                                this.state.cities.map((c, index) => {
                                    return (
                                        <option key={index} value={c.encrypted_id} data-id={c.id} selected={c.encrypted_id == this.state.city_id ? true : false }>{c.displayName}</option>
                                        )
                                    })
                            : ''
                        }
                        
                        </select>
                        { this.state.selectedvalue.length > 0 ?
                            <Select
                            className="form-control my-1 border"
                            value={this.state.selectedvalue}
                            isMulti={true}
                            clearable={false}
                            options={this.state.foodsDropdown}
                            placeholder="Food Type"
                            onChange={this.logChange}
                            />
                            : 
                            <Select
                            className="form-control my-1 border"
                            isMulti={true}
                            clearable={false}
                            options={this.state.foodsDropdown}
                            placeholder="Food Type"
                            onChange={this.logChange}
                            />
                        }
                        {/* <input type="text" name className="form-control my-1 border" /> */}
                        <label className="w-100 text-center">
                            <input type="checkbox" />
                            <span className="text">{this.props.t('searchPage.sortDelivery')}</span>
                        </label>
                        </div>
                    </div>
                    </div>
                    <div className="widget widget-tags d-block d-sm-none d-none d-sm-block d-md-none">
                    <div className="widget-body">
                        <div className="nav tag-cloud d-flex justify-content-center">
                        <div className="mb-1">
                            <img src="img/city-loc.png" className="img-fluid" />
                        </div>
                        <button className="btn btn-block">Malaga <span>(200)</span></button>
                        <div className="row">
                            <div className="col-sm-12 col-xs-12 col-md-12 d-flex justify-content-center">
                            <button className="btn btn-bg-custom btn-sm mx-1" style={{fontSize: 10}}>Restaurants <span>(150)</span></button>
                            <button className="btn btn-bg-custom btn-sm mx-1" style={{fontSize: 10}}>Tapas <span>(80)</span></button>
                            <button className="btn btn-bg-custom btn-sm mx-1" style={{fontSize: 10}}>Take Aways <span>(50)</span></button>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="widget-title text-center btn-bg-custom p-1">
                        <a href="#" data-toggle="modal" data-target="#exampleModal">
                        <h3 className="text-white"><i className="fa fa-filter" aria-hidden="true" /> FILTER BY</h3>
                        </a>
                    </div>
                    </div>
                </div>
                <div className="col-lg-9">
                    { this.state.loading ?
                     <div className="row justify-content-center align-items-center h-70">
                     <Loading />
                     </div>  
                     :
                     <>
                    <div className="card border-radius p-2 mt-3">
                        <div id="events" className="event-list">
                            <OwlCarousel className="owl-theme" items={10} navText={['<div className="owl-nav-wrapper bg-soft-primary">'+
                                     '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  viewBox="0 0 24 24" fill="none" stroke="currentColor"stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-left text-primary">'+
                                     '<polyline points="15 18 9 12 15 6"></polyline>'+
                                 ' </svg>'+
                                 '</div>',
                             
                                 '<div className="owl-nav-wrapper bg-soft-primary">'+
                                     '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-right text-primary">'+
                                     '<polyline points="9 18 15 12 9 6"></polyline>'+
                                 '  </svg>'+
                                 '</div>']} autoplay={false} nav dots={false}>

                                { this.state.foodSliderData.length > 0 ?
                                        this.state.foodSliderData.map((food, index) => {
                                        return (
                                            <div className={"item text-center cursor-pointer "} key={index} onClick={this.handleFoodTypeChange} data-id={food.foodId} data-value={food.foodName} >
                                                <div className={"event-item text-center " + (food.foodId == this.state.activeFoodType ? 'active' : ' ')}>
                                                    <img className="event-user-pic img-fluid cursor-pointer" src={food.foodImage} onError={(e)=>{e.target.src= "https://development.zampoita.com/etr-backend/public/storage/uploads/merchant_images/default/default.jpg"; }} alt="Profile Pic"  data-id={food.foodId} data-value={food.foodName}/>
                                                </div>
                                                <p className="m-0">{food.foodName}</p>
                                            </div>
                                        )
                                    })
                                    : ''
                                }
                                
                            </OwlCarousel>
                        </div>
                    </div>
                    <div className="my-3">
                        <div className="popular_courses">
                        
                        { this.state.resturants.listingRestaurantPremium !== undefined ? 
                            <OwlCarousel className="owl-theme" margin={20}  navText={['<div className="owl-nav-wrapper bg-soft-primary">'+
                                        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  viewBox="0 0 24 24" fill="none" stroke="currentColor"stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-left text-primary">'+
                                        '<polyline points="15 18 9 12 15 6"></polyline>'+
                                    ' </svg>'+
                                    '</div>',
                                
                                    '<div className="owl-nav-wrapper bg-soft-primary">'+
                                        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-right text-primary">'+
                                        '<polyline points="9 18 15 12 9 6"></polyline>'+
                                    '  </svg>'+
                                    '</div>']} autoplay={true} nav dots={false} items={2}>

                                    { this.state.resturants.listingRestaurantPremium.map((r, index) => {
                                        return (
                                        <div className="itemqqq" key={index}>
                                            <div className="single_course">
                                                <div className="course_head">
                                                    <p className="prem_open_p">
                                                        <img src={r.logoUrlPremium} className="pp_premium" />
                                                    </p>
                                                    <img className="img-fluid" src={r.logoUrl} onError={(e)=>{e.target.src= "https://development.zampoita.com/etr-backend/public/storage/uploads/merchant_images/default/restaurant-premium-logo.jpg"; }} />
                                                </div>
                                                <div className="course_content">
                                                    { r.isRestaurantOpenNow ?

                                                        <span className="btn btn btn-outline-success-custom float-right">{r.isRestaurantOpenNowStatus}</span>
                                                        :
                                                        <span className="btn btn btn-outline-danger-custom float-right">{r.isRestaurantOpenNowStatus}</span>
                                                    }
                                                
                                                    <h3 className="my-2">
                                                    <a href={"/resturants/" + r.restaurant_slug + '/' + r.template} > {r.restaurant_name}</a>
                                                    </h3>
                                                    <p>
                                                    <i className="fa fa-thumb-tack w-15 " />{r.address_line1}  {r.address_line2} {r.address_line3} {r.postal_code}, 
                                                    </p>
                                                    <p>
                                                    <i className="fa fa-phone-square w-15" />: {r.phone_number1}
                                                    </p>
                                                    <div className="course_meta d-flex justify-content-lg-between align-items-lg-center flex-lg-row flex-column mt-1">
                                                    <div className="mt-lg-0 mt-3">
                                                        { r.merchantFoodsData != null && r.merchantFoodsData.length > 0 ?
                                                            r.merchantFoodsData.map((foodData, index) => {
                                                            return (
                                                                <span className="meta_info" key={index}>
                                                                <a href="#">
                                                                    <button className="badge badge-pill badge-outline-cus border-0">{foodData.name}</button>
                                                                </a>
                                                                </span>
                                                            )
                                                            })
                                                            : ''
                                                        }
                                                        
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    })
                                }
                                
                                
                                
                            </OwlCarousel>
                            : ''
                            }
                        </div>
                    </div>
                    { this.state.resturants.listingRestaurant !== undefined ? 
                        <div className="row mb-3 mr-0">
                        { this.state.resturants.listingRestaurant.map((r, index) => {
                        return (
                            <div className="col-md-4 pr-md-0 mb-3 align-self-stretch" key={index}>
                                <div className="single_course" data-toggle="modal" data-target={"#centralModalLGInfoDemo" + r.encrypted_id }>
                                    <div className="course_head">
                                        <img className="img-fluid" src={r.logoUrl}  onError={(e)=>{e.target.src= "https://development.zampoita.com/etr-backend/public/storage/uploads/merchant_images/default/restaurant-logo.jpg"; }} alt />
                                    </div>
                                    <div className="course_content">
                                        { r.isRestaurantOpenNow ?

                                            <span className="btn btn btn-outline-success-custom float-right">{r.isRestaurantOpenNowStatus}</span>
                                            :
                                            <span className="btn btn btn-outline-danger-custom float-right">{r.isRestaurantOpenNowStatus}</span>
                                            }
                                        
                                        <h5 className="my-2 font-weight-bold">
                                        <a href="#" >{r.restaurant_name}</a>
                                        </h5>
                                        <p>
                                        <i className="fa fa-thumb-tack w-15 " /> {r.displayAddress} 
                                        </p>
                                        <p>
                                        <i className="fa fa-phone-square w-15" />:{ r.phone_number1}
                                        </p>
                                        <div className="course_meta d-flex justify-content-lg-between align-items-lg-center flex-lg-row flex-column mt-1">
                                        <div className="mt-lg-0 mt-3">
                                        { r.merchantFoodsData != null && r.merchantFoodsData.length > 0 ?
                                            r.merchantFoodsData.map((foodData, index) => {
                                                return(
                                                <span className="meta_info">
                                                <a href="#">
                                                    <button className="badge badge-pill badge-outline-cus border-0">{foodData.name}</button>
                                                </a>
                                                </span>
                                                )
                                            })
                                            : ''
                                        }
                                        
                                           
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                              )
                            })
                            
                        }

                            {/* <div className="col-md-4 pr-md-0 mb-3 d-flex align-self-stretch">
                                <div className="single_course">
                                <div className="course_head">
                                    <img className="img-fluid" src="img/slider-img.jpg" alt />
                                </div>
                                <div className="course_content">
                                    <span className="btn btn btn-outline-success-custom float-right">Open Now</span>
                                    <h5 className="my-2 font-weight-bold">
                                    <a href="#">La Proa de Teatinos</a>
                                    </h5>
                                    <p>
                                    <i className="fa fa-thumb-tack w-15 " /> Av. Salvador Allende, 340, 
                                    </p>
                                    <p>
                                    <i className="fa fa-phone-square w-15" />: +34 951 48 51 28
                                    </p>
                                    <div className="course_meta d-flex justify-content-lg-between align-items-lg-center flex-lg-row flex-column mt-1">
                                    <div className="mt-lg-0 mt-3">
                                        <span className="meta_info">
                                        <a href="#">
                                            <button className="badge badge-pill badge-outline-cus border-0">Spanish</button>
                                        </a>
                                        </span>
                                        <span className="meta_info">
                                        <a href="#">
                                            <button className="badge badge-pill badge-outline-cus border-0">European</button>
                                        </a>
                                        </span>
                                        <span className="meta_info">
                                        <a href="#">
                                            <button className="badge badge-pill badge-outline-cus border-0">Seafood</button>
                                        </a>
                                        </span>
                                        <span className="meta_info">
                                        <a href="#">
                                            <button className="badge badge-pill badge-outline-cus border-0">mediterranean</button>
                                        </a>
                                        </span>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-4 d-flex align-self-stretch mb-3">
                                <div className="single_course">
                                <div className="course_head">
                                    <img className="img-fluid" src="img/slider-img.jpg" alt />
                                </div>
                                <div className="course_content">
                                    <span className="btn btn btn-outline-success-custom float-right">Open Now</span>
                                    <h5 className="my-2 font-weight-bold">
                                    <a href="#">La Proa de Teatinos</a>
                                    </h5>
                                    <p>
                                    <i className="fa fa-thumb-tack w-15 " /> Av. Salvador Allende, 340, 
                                    </p>
                                    <p>
                                    <i className="fa fa-phone-square w-15" />: +34 951 48 51 28
                                    </p>
                                    <div className="course_meta d-flex justify-content-lg-between align-items-lg-center flex-lg-row flex-column mt-1">
                                    <div className="mt-lg-0 mt-3">
                                        <span className="meta_info">
                                        <a href="#">
                                            <button className="badge badge-pill badge-outline-cus border-0">Spanish</button>
                                        </a>
                                        </span>
                                        <span className="meta_info">
                                        <a href="#">
                                            <button className="badge badge-pill badge-outline-cus border-0">European</button>
                                        </a>
                                        </span>
                                        <span className="meta_info">
                                        <a href="#">
                                            <button className="badge badge-pill badge-outline-cus border-0">Seafood</button>
                                        </a>
                                        </span>
                                        <span className="meta_info">
                                        <a href="#">
                                            <button className="badge badge-pill badge-outline-cus border-0">mediterranean</button>
                                        </a>
                                        </span>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div> */}
                        </div>
                       
                        : ''
                    }
                    </>
                    }
                </div>
               
                </div>
            </div>
        </div>
        { this.state.resturants.listingRestaurant !== undefined ? 
        <div className="d-flex justify-content-center ">
        { this.state.resturants.listingRestaurant.map((r, index) => {
            return (
            <div className="modal fade" id={"centralModalLGInfoDemo" + r.encrypted_id} tabIndex={-1} role="dialog" aria-labelledby="myModalLabel">
                <div className="modal-dialog m-0" role="document">
                {/*Content*/}
                <div className="modal-content">
                    <div className="modal-body">
                    <button type="button" className="close close-modal-button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                    <div className="contant-box text-center" style={{ background: "url("+r.logoUrl+")"}}>
                        <h1 className="m-0"> {r.restaurant_name}</h1>
                        <h6>
                        <p><i className="fa fa-map-marker" /> {r.popupAddress}</p>
                        <p><i className="fa fa-phone" aria-hidden="true" /><a href="tel:+34 648 85 32 53"> {r.popupPhone}</a></p>
                        </h6>
                        <label>{r.merchantRestaurantTypeData.displayName}</label>
                    </div>
                    <div className="text-center">
                        <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                           
                            { r.merchantFoodsData != null && r.merchantFoodsData.length > 0 ?
                             <div className="fud_types">
                                <h4>FOOD TYPE</h4>
                                
                                <ul>
                             
                                {r.merchantFoodsData.map((foodData, index) => {
                                    return(
                                    <li>
                                        <img alt={foodData.name} src="https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aGVhbHRoeSUyMGZvb2R8ZW58MHx8MHx8&w=1000&q=80" />
                                        <h6>{foodData.name}</h6>
                                    </li>
                                 )
                                })
                               
                                }
                                </ul>
                                </div>
                                : ''
                            }
                           
                            </div>
                            <div className="col-md-6">
                            <div className="fud_types">
                                <h4>Photos</h4>
                                <OwlCarousel className="owl-theme owl-carousel-custom modal-carusal" items={2}  margin={10} responsive={res} navText={['<div className="owl-nav-wrapper bg-soft-primary">'+
                                    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  viewBox="0 0 24 24" fill="none" stroke="currentColor"stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-left text-primary">'+
                                    '<polyline points="15 18 9 12 15 6"></polyline>'+
                                ' </svg>'+
                                '</div>',
                            
                                '<div className="owl-nav-wrapper bg-soft-primary">'+
                                    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-right text-primary">'+
                                    '<polyline points="9 18 15 12 9 6"></polyline>'+
                                '  </svg>'+
                                '</div>']} autoplay={true} nav dots={false}>

                                {/* <div className="
                                owl-carousel owl-carousel-custom modal-carusal"> */}
                                {r.merchantRestaurantSliderImagesData.map((photos, index) => {
                                     return(
                                    <div>
                                        <img src={photos.image_url} onError={(e)=>{e.target.src= "https://development.zampoita.com/etr-backend/public/storage/uploads/merchant_images/default/restaurant-logo.jpg"; }} />
                                    </div>
                                    )
                                })
                               
                                }
                                {/* <div>
                                    <img src="https://thumbs.dreamstime.com/b/assorted-american-food-top-view-109748438.jpg" />
                                </div>
                                <div>
                                    <img src="https://images.unsplash.com/photo-1606787366850-de6330128bfc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8OHx8fGVufDB8fHx8&w=1000&q=80" />
                                </div> */}
                                </OwlCarousel>
                                {/* </div> */}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/*Footer*/}
                <div className="modal-footer justify-content-center">
                    <div className="normal_prfl_wrp">
                    <div className="text-center">
                        <img src="img/new_prfl_openg.png" className="img-fluid openg_h_img" />
                    </div>
                    { r.merchantRestaurantTimingsData != null && r.merchantRestaurantTimingsData.length > 0 ?  
                    <div className="openg_hr_section">
                        <ul>
                        {r.merchantRestaurantTimingsData.map((res, index) => {
                            return(
                                <li>
                                    <div className="work_time_each">
                                    <label>MON</label>
                                    <p>  CLOSED </p>
                                    </div>
                                </li>
                             )
                            })
                           
                        }
                        {/* <li>
                            <div className="work_time_each">
                            <label>TUE</label>
                            <p>CLOSED</p>
                            </div>
                        </li>
                        <li>
                            <div className="work_time_each">
                            <label>WED</label>
                            <p>  CLOSED </p>
                            </div>
                        </li>
                        <li className="current_op_day">
                            <div className="work_time_each">
                            <label>THU</label>
                            <p>CLOSED</p>
                            </div>
                        </li>
                        <li>
                            <div className="work_time_each">
                            <label>FRI</label>
                            <p> 19:30 - 22:30</p>
                            </div>
                        </li>
                        <li>
                            <div className="work_time_each">
                            <label>SAT</label>
                            <p>19:30 - 22:30 </p>
                            </div>
                        </li>
                        <li>
                            <div className="work_time_each">
                            <label>SUN</label>
                            <p> CLOSED</p>
                            </div>
                        </li> */}
                        </ul>
                    </div>
                    : ''
                    }
                    </div>
                </div>
                </div>
                {/*/.Content*/}
            </div>
             )
            })
        }
        </div>
        : ''
    }
      </>
    );
  }
}

function mapStateToProps(state) {

    console.log(state);
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  let { restaurantsearch } = state.restaurantsearch;
  let { restaurantsFoods } = state.restaurants;
 
    if(state.restaurantsearch){
    restaurantsearch = state.restaurantsearch[1];
    }
    if(state.restaurants){
      
        restaurantsFoods = state.restaurants[1];
    }
  
  return {
    isLoggedIn,
    message,
    restaurantsearch: restaurantsearch,
    restaurantsFoods: restaurantsFoods,
  };
}

export default compose(withTranslation(), connect(mapStateToProps, {
    getSearchData,
    getProvinceData,
    getCityData,
    getRestaurant,
    getRestaurantList,
}))(SearchListing);
