import React, { Component } from "react";
//import { Redirect } from "react-router-dom";
//import * as yup from "yup";
//import Message from "./common/message";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
//import { login } from "../../../actions/auth";

class WhyZampoita extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  render() {
    //const { message } = this.props;
    return (
      <>
        <div className="sb-app">
          <div id="sb-dynamic-content" className="sb-transition-fade">
            <section className="sb-banner sb-banner-sm sb-banner-color">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="sb-main-title-frame">
                      <div className="sb-main-title text-center">
                        <h1 className="sb-mb-30 color-black m-0">
                        {this.props.t("whyZampoita.why")} <span className="color"> {this.props.t("whyZampoita.zampoita")}</span>
                        </h1>
                        <p className="sb-text sb-text-lg sb-mb-30">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. <br /> Ut enim ad minim veniam,
                          quis nostrud exercitation ullamco laboris nisi ut
                          aliquip ex ea commodo consequat.
                        </p>
                        <ul className="sb-breadcrumbs">
                          <li>
                            <Link to="/">{this.props.t("aboutUs.home")}</Link>
                          </li>
                          <li>
                            <Link to="/why-zampoita">{this.props.t("whyZampoita.why_zampoita")}</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="sb-call-to-action">
              <div className="sb-bg-3"></div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="sb-illustration-3">
                      <img
                        src="img/dish-1.png"
                        alt="phones"
                        className="sb-phones"
                      />
                      <div className="sb-cirkle-1"></div>
                      <div className="sb-cirkle-2"></div>
                      <div className="sb-cirkle-3"></div>
                      <div className="sb-cirkle-4"></div>
                      <img src="img/1.svg" alt="phones" className="sb-pik-1" />
                      <img src="img/2.svg" alt="phones" className="sb-pik-2" />
                      <img src="img/3.svg" alt="phones" className="sb-pik-3" />
                    </div>
                  </div>
                  <div className="col-lg-6 align-self-center">
                    <div className="sb-cta-text">
                      <h1 className="sb-mb-30 color-black">
                      {this.props.t("whyZampoita.online_home")}{" "}
                        <span className="color">  {this.props.t("whyZampoita.your_eatery")}</span>
                      </h1>
                      <p className="sb-text sb-mb-30">
                      {this.props.t("whyZampoita.eateryText")}
                      </p>
                      <p className="sb-text sb-mb-30">
                      {this.props.t("whyZampoita.eateryText1")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="sb-video">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="sb-mb-90">
                      <h1 className="sb-mb-30 color-black mt-0">
                      {this.props.t("whyZampoita.dinner_waiting")} <br />
                        <span className="color">
                        {this.props.t("whyZampoita.are_you_seat_them")}
                        </span>
                      </h1>
                      <p className="sb-text sb-mb-30">
                      {this.props.t("whyZampoita.dinnerText")}
                      </p>
                      <p className="sb-text sb-mb-30">
                      {this.props.t("whyZampoita.dinnerText1")}
                      </p>
                      <p className="sb-text sb-mb-30">
                      {this.props.t("whyZampoita.dinnerText2")}
                      </p>
                      <p className="sb-text sb-mb-30">
                      {this.props.t("whyZampoita.dinnerText3")}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 align-self-center">
                    <div className="sb-illustration-7 sb-mb-90">
                      <div className="sb-interior-frame">
                        <img
                          src="img/dish-2.jpg"
                          alt="interior"
                          className="sb-interior"
                        />
                      </div>
                      <div className="sb-cirkle-1"></div>
                      <div className="sb-cirkle-2"></div>
                      <div className="sb-cirkle-3"></div>
                      <div className="sb-cirkle-4"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="sb-call-to-action">
              <div className="sb-bg-3"></div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 align-self-center">
                    <div className="sb-cta-text">
                      <h1 className="sb-mb-30 color-black">
                      {this.props.t("whyZampoita.we")}<span className="color"> {this.props.t("whyZampoita.love")}</span> {this.props.t("whyZampoita.what_you_do")}
                      </h1>
                      <p className="sb-text sb-mb-30">
                      {this.props.t("whyZampoita.what_you_do_text")}
                        <a href="#!" className="color-theme">
                        {this.props.t("whyZampoita.pricing")}
                        </a>
                        {this.props.t("whyZampoita.page")}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="sb-illustration-3">
                      <img
                        src="img/dish-3.png"
                        alt="phones"
                        className="sb-phones"
                      />
                      <div className="sb-cirkle-1"></div>
                      <div className="sb-cirkle-2"></div>
                      <div className="sb-cirkle-3"></div>
                      <div className="sb-cirkle-4"></div>
                      <img src="img/1.svg" alt="phones" className="sb-pik-1" />
                      <img src="img/2.svg" alt="phones" className="sb-pik-2" />
                      <img src="img/3.svg" alt="phones" className="sb-pik-3" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="sb-call-to-action mb-5">
              <div className="sb-bg-3"></div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="sb-illustration-3">
                      <img
                        src="img/dish-4.png"
                        alt="phones"
                        className="sb-phones"
                      />
                      <div className="sb-cirkle-1"></div>
                      <div className="sb-cirkle-2"></div>
                      <div className="sb-cirkle-3"></div>
                      <div className="sb-cirkle-4"></div>
                      <img src="img/1.svg" alt="phones" className="sb-pik-1" />
                      <img src="img/2.svg" alt="phones" className="sb-pik-2" />
                      <img src="img/3.svg" alt="phones" className="sb-pik-3" />
                    </div>
                  </div>
                  <div className="col-lg-6 align-self-center">
                    <div className="sb-cta-text">
                      <h1 className="sb-mb-30 color-black">
                      {this.props.t("whyZampoita.putting_money")}
                        <span className="color"> {this.props.t("whyZampoita.our_month")}</span>
                      </h1>
                      <p className="sb-text sb-mb-30">
                      {this.props.t("whyZampoita.our_month_text")}
                      </p>
                      <p className="sb-text sb-mb-30">
                      {this.props.t("whyZampoita.our_month_text1")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(WhyZampoita);
