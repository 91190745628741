import {
    GET_RESTAURANT_SEARCH_DATA,
 
    // DELETE_ALL_RATES,
  } from "../actions/types";
  
  const initialState = [];
  
  function restaurantSearchReducer(restaurantsearch = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_RESTAURANT_SEARCH_DATA:
        return [restaurantsearch, payload];
  
    
  
      default:
        return restaurantsearch;
    }
  }
  
  export default restaurantSearchReducer;
  