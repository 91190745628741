import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import Message from "./common/message";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { login } from "../actions/auth";
import {  Form } from "react-bootstrap";
import Cookies from 'universal-cookie';
import { toast } from "react-toastify";
import configData from "../config.json";




// const required = (value) => {
//   if (!value) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This field is required!
//       </div>
//     );
//   }
// };

class FirstLogin extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.handleClick = this.handleClick.bind(this);

    
    this.state = {
      email: "",
      password: "",
      loading: false,
      passwordType:"password",
      passwordShow:false,
    };
  }

  schema =  yup.object().shape({
    
    email: yup.string().email('user.addForm.validation.validemail').required('user.addForm.validation.email'),
    password: yup.string().required('user.addForm.validation.password')
  
    });

    componentDidMount() {
      const cookies = new Cookies();
      if(cookies.get('authetication')){
        window.location.href = configData.baseUrl
      }else{
       window.scrollTo(0, 0);
      }
    }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleClick(e){
      if(this.state.passwordShow == false){
        this.setState({
          passwordShow:true,
          passwordType:"text",
        });
      }else{
        this.setState({
          passwordShow:false,
          passwordType:"password",
        });

      }
  }
  async handleLogin(values) {
    // e.preventDefault();

    this.setState({
      loading: true,
    });
    const { dispatch, history } = this.props;
    const cookies = new Cookies();
    if(values.email == "admin@kardarma.com" && values.password == "Pass123"){
      cookies.set('authetication', 'admin');

      window.location.href = configData.baseUrl

    }else{
      // CREDENTIALS
      toast.error("Please enter valid Credentials");
    }

    

    // // this.form.validateAll();

    // const { dispatch, history } = this.props;

    // // if (this.checkBtn.context._errors.length === 0) {
    //   dispatch(login(values.email, values.password))
    //     .then(() => {
    //       //  history.push("/user/dashboard");
    //       // window.location = "/profile";
    //       window.location.href = "https://kardarma.com/user/user/dashboard";
    //     })
    //     .catch((e) => {

    //       console.log(e);
    //       this.setState({
    //         loading: false,
    //       });
    //     });
    // } else {
    //   this.setState({
    //     loading: false,
    //   });
    // }
  }

  render() {
    const { isLoggedIn, message } = this.props;

    // if (isLoggedIn) {
    //   console.log(isLoggedIn);
    //   return window.location.href = "https://kardarma.com/user/user/dashboard";
    // }

    return (
            
      <div>
      <div className="rts-contact-form-area vh-100 login-page d-flex align-items-center" style={{background: 'url(assets/images/login-bg.jpg)', backgroundRepeat: 'no-repeat', width: '100%'}}>
      <div className="container">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <div className="rts-contact-fluid">
              <div className="rts-title-area contact-fluid text-center mb--25">
                {/* <img className="login-logo-cus-css" src="assets/images/login-logo.png" /> */}
                <h2 className="title">Log in to Kardarma</h2>
              </div>
                <div className="form-wrapper">
                        <Message message={message} />
                        <Formik
                        validationSchema={this.schema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize="true"
                        onSubmit={(values) => {
                          this.handleLogin(values);
                        }}
                        initialValues={{
                          email: this.state.email,
                          password: this.state.password,
                        }}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                        }) => (
                        <Form autoComplete="off" className="custom-btn" onSubmit={handleSubmit}>
                          <input  type="email" name="email"
                                placeholder={this.props.t(
                                  "login.labelemail"
                                )}
                                value={values.email}
                                autoComplete="off"
                                onChange={handleChange} />
                          <input type={this.state.passwordType}  name="password" onChange={handleChange}
                                placeholder={this.props.t('login.labelpwd')}
                                value={values.password}
                                autoComplete="off"/>
                          <div className="text-center">
                          <button type="submit" className="rts-btn btn-cus ">Login</button>
                          </div>
                          {/* <div className="mt-10">
                            Don't have an account? <Link to={'/register'}>Sign up</Link>
                          </div> */}
                          </Form>
                        )}
                     </Formik>
                     </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Login form fluid end */}
        {/* start loader */}
        <div className="loader-wrapper">
          <div className="loader">
          </div>
          <div className="loader-section section-left" />
          <div className="loader-section section-right" />
        </div>
      </div>
    

             
        
            

    //   <div className="page-wrapper">
    //   <Container fluid={true}>
    //     <div className="authentication-main">
    //       <Row>
    //         <Col md="12" className="p-0">
    //           <div className="auth-innerright auth-minibox">
    //             <div className="reset-password-box">
    //               <div className="text-center">
    //                 <img
    //                   src={require("../assets/images/logo/zam-logo.png")}
    //                   alt=""
    //                 />
    //               </div>
    //               <Card className="mt-4 mb-0">
    //                 <h4 className="text-center">LOGIN</h4>
    //                 <Message message={message} />
    //                 <Formik
    //                     validationSchema={this.schema}
    //                     validateOnChange={false}
    //                     validateOnBlur={false}
    //                     enableReinitialize="true"
    //                     onSubmit={(values) => {
    //                       this.handleLogin(values);
    //                     }}
    //                     initialValues={{
    //                       email: this.state.email,
    //                       password: this.state.password,
    //                     }}
    //                   >
    //                     {({
    //                       handleSubmit,
    //                       handleChange,
    //                       handleBlur,
    //                       values,
    //                       touched,
    //                       isValid,
    //                       errors,
    //                     }) => (
    //                     <Form className="theme-form" onSubmit={handleSubmit}>
    //                       <FormGroup>
    //                         <Form.Label className="col-form-label"> {this.props.t(
    //                               "login.labelemail"
    //                             )}</Form.Label>
    //                          <Form.Control   className="btn-pill" type="text" name="email"
    //                             placeholder={this.props.t(
    //                               "login.labelemail"
    //                             )}
    //                             value={values.email}
    //                             onChange={handleChange}
    //                             isInvalid={!!errors.email} />
    //                             <Form.Control.Feedback
    //                             className=""
    //                             type="invalid"
    //                           >
    //                             { this.props.t(errors.email)}
    //                           </Form.Control.Feedback>
    //                       </FormGroup>
    //                       <FormGroup>
    //                         <Form.Label className="col-form-label">
    //                         {this.props.t(
    //                               "login.labelpwd"
    //                             )}
    //                         </Form.Label>
    //                         <Form.Control className="btn-pill" type="password"  name="password" onChange={handleChange}
    //                             placeholder={this.props.t('login.labelpwd')}
    //                             value={values.password}
    //                             isInvalid={!!errors.password} />
    //                              <Form.Control.Feedback
    //                             className=""
    //                             type="invalid"
    //                           >
    //                             { this.props.t(errors.password)}
    //                           </Form.Control.Feedback>
    //                       </FormGroup>
    //                       <div class="text-right mt-4 mb-4"><span class="reset-password-link"><Link class="btn-link" to={'/forget-password'}>Forgot password ?</Link></span></div>
    //                       <FormGroup className="form-row mb-2">
    //                         <Col md="2 m-auto">
    //                           <Button type="submit" color="primary btn-pill">LOGIN</Button>
    //                         </Col>
    //                       </FormGroup>
    //                     </Form>
    //                     )}
    //                  </Formik>
    //               </Card>
    //             </div>
    //           </div>
    //         </Col>
    //       </Row>
    //     </div>
    //   </Container>
    // </div>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default compose(withTranslation(), connect(mapStateToProps))(FirstLogin);
