import { combineReducers } from "redux";
import auth from "./auth";
import authUser from "./auth-user";
import message from "./message";
import tutorials from "./tutorials";
import guests from "./guests";
import rates from "./rates";
import users from "./user";
import guestUser from "./guest-user";
import restaurants from "./restaurant";
import restaurantHours from "./restaurant-hour";
import restaurantTables from "./restaurant-table";
import configurations from "./configuration";
import reservation from "./reservation";
import restaurantsearch from "./restaurant-search";
import cities from "./cities";
import citiesAuto from "./citiesAuto";
import restaurant_postcode from "./restaurant-search-postcode";
import page_detail from "./page-detail";







export default combineReducers({
  auth,
  message,
  authUser,
  tutorials,
  guests,
  guestUser,
  rates,
  users,
  restaurantHours,
  restaurantTables,
  restaurants,
  configurations,
  reservation,
  restaurantsearch,
  cities,
  citiesAuto,
  restaurant_postcode,
  page_detail,
});
