
export default function authHeader() {
  let language = "es"
  if(localStorage.getItem("lan")){
    language = localStorage.getItem("lan");
  }
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.accessToken) {
    // For Spring Boot back-end
    return { Authorization: "Bearer " + user.accessToken,
            "Accept-Language": language,

     };

    // for Node.js Express back-end
    //return { "x-access-token": user.accessToken };
  } else {
    return {};
  }
}
