import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
// import { Redirect } from 'react-router';

import "./App.css";

import Home from "./components/home.component";
import FirstLogin from "./components/firstlogin.component";

import Search from "./components/search/search.component";
import SearchListing from "./components/search/search-listing.component";
import Individual from "./components/front-end/individual/individual.component";
import Individual2 from "./components/front-end/individual/individual2.component";
import Individual3 from "./components/front-end/individual/individual3.component";




import AboutUs from "./components/front-end/cms/about-us.component";
import WhyZampoita from "./components/front-end/cms/why-zampoita.component";
import Blog from "./components/front-end/cms/blog.component";
import BlogDetail from "./components/front-end/cms/blog-detail.component";
import CookiesInfo from "./components/front-end/cms/cookies-info.component";
import Pricing from "./components/front-end/cms/pricing.component";
import PrivacyPolicy from "./components/front-end/cms/privacy-policy.component";

import TermsConditions from "./components/front-end/cms/terms-conditions.component";
import Terms from "./components/front-end/cms/terms.component";

import ViewMenu from "./components/front-end/menu/view-menu.component";


import Register from "./components/register.component";
import ForgotPassword from "./components/forget-password.component";






import Login from "./components/login.component";
// import Profile from "./components/profile.component";
import ChangePassword from "./components/change-password.component";

import Dashboard from "./components/dashboard.component";

import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

import { history } from "./helpers/history";
import EventBus from "./common/EventBus";
import ProtectedRoute from "./components/common/protectedRoute";
import PrivateRoute from "./components/common/privateRoute";


// import { _pre } from "./utils/vlogs";
import AppHeader from "./components/layout/AppHeader";
import AppSideBar from "./components/layout/AppSideBar";
import AppFooter from "./components/layout/AppFooter";

//import Logs from "./components/log/Logs";

//import GuestUserList from "./components/guest-user/guest-list.component";
//import GuestUserAdd from "./components/guest-user/add-guest.component";

import UserList from "./components/users/user-list.component";
import UserAdd from "./components/users/add-user.component";

import page_404 from "./components/page_404.component";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
      toggled: false,
      isHomePage: true,
      isNotHomePage: false, 
    };

    history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }
  componentDidMount() {
    const { user } = this.props;
    if (user) {
      this.setState({
        currentUser: user,
      });
    }

    console.log(history.location.pathname, 'hhhh');
    if (history.location.pathname !== "/") {

      this.setState((state) => {
        // Important: read `state` instead of `this.state` when updating.
        return {isHomePage:false}
      });
      
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentDidUpdate() {
    // alert("In");
  }
  componentWillUnmount() {
    EventBus.remove("logout");
  }
  logOut() {
    this.props.dispatch(logout());
    this.setState({
      currentUser: undefined,
    });
  }
  toggleMenu() {
    console.log(this.state.toggled);
    let isToggled = this.state.toggled;
    // this.setState({ toggled: !isToggled });
    this.setState((state) => {
      // Important: read `state` instead of `this.state` when updating.
      return { toggled: !isToggled };
    });
  }
  render() {
    // _pre("1. APP  Render ", this.props);
    const { user: currentUser } = this.props;
    console.log(history.location.pathname);
    //alert(" isHomePage " + this.state.isHomePage);
    // console.log(currentUser);
    return (
      <Router history={history}>
        
            <Suspense fallback="loading">
              <AppHeader
                currentUser={currentUser}
                onClick={() => this.logOut()}
                toggleMenu={() => this.toggleMenu()}
                toggled={this.state.toggled}
                history={history}
                isHomePage={true}
              />
            </Suspense>
          

        <Switch>
          <Route exact path={["/authetication"]} component={FirstLogin} />
          <PrivateRoute exact path={["/", "/home"]} component={Home} />
          <PrivateRoute exact path={["/search"]} component={Search} />
          <PrivateRoute exact path={["/search-listing"]} component={SearchListing} />
          <PrivateRoute exact path={["/resturants/:slug/1"]} component={Individual} />
          <PrivateRoute exact path={["/resturants/:slug/2"]} component={Individual2} />

          <PrivateRoute exact path={["/resturants/:slug/3"]} component={Individual3} />
          <PrivateRoute exact path={["/resturants/:slug/view-menu"]} component={ViewMenu} />


          <PrivateRoute exact path={["/login"]} component={Login} />
          <PrivateRoute exact path={["/about-us"]} component={AboutUs} />
          <PrivateRoute exact path={["/why-zampoita"]} component={WhyZampoita} />
          <PrivateRoute exact path={["/blog"]} component={Blog} />
          <PrivateRoute exact path={["/blog/:slug"]} component={BlogDetail} />
          <PrivateRoute exact path="/cookie-info" component={CookiesInfo} />
          <PrivateRoute exact path={["/pricing"]} component={Pricing} />
          <PrivateRoute exact path="/privacy-policy" component={PrivacyPolicy} />

          <PrivateRoute exact path="/terms-conditions" component={TermsConditions} />

          <PrivateRoute exact path="/terms-conditions-competitions" component={Terms} />
          <PrivateRoute exact path="/register" component={Register} />
          <PrivateRoute exact path="/forget-password" component={ForgotPassword} />



          <PrivateRoute
            exact
            path="/change-password"
            component={ChangePassword}
          />

          {/* <ProtectedRoute exact path="/reservation/map" component={Reservation} />
          <ProtectedRoute exact path="/reservation/list" component={Reservation} />

          <ProtectedRoute exact path="/reservation/calender" component={Reservation} /> */}

          <Route exact path="/dashboard" component={Dashboard} />

          <ProtectedRoute exact path="/users/add" component={UserAdd} />
          <ProtectedRoute exact path="/users/update/:id" component={UserAdd} />
          <ProtectedRoute exact path="/users" component={UserList} />

          <ProtectedRoute path="*" component={page_404} />
        </Switch>

       
            <AppFooter currentUser={currentUser} />
         
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App);
