import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import * as yup from "yup";
import { withTranslation } from "react-i18next";
import Loading from "../../common/loading";
import { getRestaurantDetailByslug } from "../../../actions/restaurants";
import { connect } from "react-redux";
import { compose } from "redux";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import $ from 'jquery'; 

 
class Individual extends Component {
  constructor(props) {
    super(props);
    

    this.state = {
        activeIndex: 1,
        email: "",
        password: "",
        loading: false,
        animating: false,
        resturants: [],
    };

    this.handleClick = this.handleClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.closeModalSlider = this.closeModalSlider.bind(this);
    this.handClickModal = this.handClickModal.bind(this);

    
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.getRestaurantByDetail();
  }

  handleClick(e){
    console.log(e.target.getAttribute('id'));
    $('body').addClass('modal-active');
    $('#modal-container').removeAttr('class').addClass(e.currentTarget.getAttribute('id'));
  }

  closeModal(e){
    
    $('body').removeClass('modal-active');
    $('#modal-container').removeAttr('class').removeClass(e.currentTarget.getAttribute('id'));
  }

  handleMenu(e){
    let event = e.currentTarget.getAttribute('data-attr');
  

    if(event == "open_menu"){
        if(this.state.resturants.merchant_menu_images.length > 0){
            const { history } = this.props;
            let url = "/resturants/"+this.state.resturants.merchant_restaurant.restaurant_slug + "/view-menu";
            history.push(url);
        }else if(this.state.resturants.merchant_menu_pdf !== null){
            window.open(
                this.state.resturants.merchant_menu_pdf,
                '_blank' // <- This is what makes it open in a new window.
              );
        }
    }else{
        $('.quick_link_close_back').addClass('shw');
        $('.quick_link_close_back').attr('data-id', event);
        $('.quick_link_inner_wrp_ul').addClass('lk_open');
    }
    if(event == "quick_menu_photos"){
        $('#pop__slider').addClass('fade show d-block');
    }else{
    $('.'+event).removeClass('d-none');
    }
    


  }

  handleMenuClose(e){
    let event = e.currentTarget.getAttribute('data-id');
    $('.quick_link_inner_wrp_ul').removeClass('lk_open');
    $('.'+event).addClass('d-none');
    $('.quick_link_close_back').attr('data-id', ' ');
    $('.quick_link_close_back').removeClass('shw');
  }

  closeModalSlider(e){
     $('.quick_link_inner_wrp_ul').removeClass('lk_open');
    $('#pop__slider').removeClass('fade show d-block');
  }

  handClickModal(e){
    console.log(e.currentTarget);
  }
 

  async getRestaurantByDetail()
  {
    let slug = this.props.match.params.slug;
    await this.props.getRestaurantDetailByslug(slug);

    console.log(this.props, "kkkkkkkk");
    this.setState({
        resturants:this.props.restaurants.merchant
    });

  } 
  render() {
    //const { isLoggedIn, message } = this.props;
    console.log(this.state, "ccccccccccccccccc");
    const { isLoggedIn } = this.props;
    const res = {
        
        0:{
            items:1,
            nav:true
        },
        600:{
            items:1,
            nav:false
        },
        1000:{
            items:1,
            nav:true,
            loop:true
        }
    
}

const res2 = {
        
    0:{
        items:5,
        nav:true
    },
    600:{
        items:8,
        nav:false
    },
    1000:{
        items:8,
        nav:false,
        loop:true
    }

}
    console.log(this.state.resturants.length, "readerzz");
    if(this.state.resturants.length !== 0){
    return (
        <>
            <div>
                <section id="main-wrapper" className="padall0 image-to-background" data-overlay-black={5}>

                    <img src={this.state.resturants.merchant_image.cover_photo_url} onError={(e)=>{e.target.src= this.state.resturants.default_cover_photos[0]; }} alt="Hero Slider Background" />
                 
                    <div id="hero-wrapper">
                    <OwlCarousel className="hero-slider owl-theme" loop autoplay={true} nav={false} dots={false} items={1}>
                 
                        <div className="container">
                            <div className="left-hero-text">
                                <h2 className="carousel-big">{this.state.resturants.merchant_restaurant.restaurant_name}</h2>
                                <div className="single-page-nav" id="testing">
                                <a href="javascript:void(0)" id="one" onClick={this.handleClick}  className="btn btn-empty margintop30 glow-on-hover">{this.props.t("restaurant_detail.quick_links")}</a>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="left-hero-text">
                                <h2 className="carousel-big">{this.state.resturants.merchant_restaurant.restaurant_name}</h2>
                                <div className="single-page-nav" id="testing">
                                <a href="javascript:void(0)"  id="one" onClick={this.handleClick}   className="btn btn-empty margintop30 glow-on-hover">{this.props.t("restaurant_detail.quick_links")}</a>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="left-hero-text">
                                <h2 className="carousel-big">{this.state.resturants.merchant_restaurant.restaurant_name}</h2>
                                <div className="single-page-nav" id="testing">
                                <a href="javascript:void(0)"   id="one" onClick={this.handleClick}  className="btn btn-empty margintop30 glow-on-hover">{this.props.t("restaurant_detail.quick_links")}</a>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                    </div>
                </section>
                {this.state.resturants.merchant_foods.length > 0 ?
                <section id="portfolio" className="dark-wood white py-5">
                    <div className="title header-line text-center">
                    <div>
                        <h2 className="color">{this.props.t("restaurant_detail.food_type")}</h2>
                    </div>
                    <small className="mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua.</small>
                    </div>
                    <div className="container" id="food-type-top-section">
                    <div className="row mt-5">
                    
                        {this.state.resturants.merchant_foods.map((f, index) => {
                            
                            return (
                                <div key={index} className={"col-md-2 col-xs-6-cus " + (index == 0 ? 'offset-md-2' :'') + " d-flex justify-content-around"}>
                                    <div className="food-type-img">
                                        <img src={f.image_url} className="img-fluid" />
                                        <h5 className="text-center font-weight-bold mt-4">{f.displayName}</h5>
                                    </div>
                                </div>
                              )
                            })
                        }
                        {/* <div className="col-md-2 col-xs-6-cus d-flex justify-content-around">
                        <div className="food-type-img">
                            <img src="https://media.istockphoto.com/photos/toast-with-avocado-and-egg-in-heart-shape-picture-id1046693924?b=1&k=20&m=1046693924&s=170667a&w=0&h=hntlfoxp2gK9BRGqgbLJOEQDw8Ae9aNroJKqE2DZ5OI=" className="img-fluid" />
                            <h5 className="text-center font-weight-bold mt-4">Italian</h5>
                        </div>
                        </div>
                        <div className="col-md-2 col-xs-6-cus d-flex justify-content-around">
                        <div className="food-type-img">
                            <img src="https://media.istockphoto.com/photos/heart-shape-eggs-in-toast-picture-id495061495?k=20&m=495061495&s=170667a&w=0&h=QNFtN4e6wqGe3W2rVPjsTVd-fE63VIO_AM699TLrmZ0=" className="img-fluid" />
                            <h5 className="text-center font-weight-bold mt-4">Vegetarian Options</h5>
                        </div>
                        </div>
                        <div className="col-md-2 col-xs-6-cus d-flex justify-content-around">
                        <div className="food-type-img">
                            <img src="https://images.unsplash.com/photo-1513442542250-854d436a73f2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwcm9maWxlLXBhZ2V8OHx8fGVufDB8fHx8&w=1000&q=80" className="img-fluid" />
                            <h5 className="text-center font-weight-bold mt-4">Vegan Options</h5>
                        </div>
                        </div> */}
                    </div>
                    </div>
                </section>
                :''
                }
                { this.state.resturants.merchant_additional_feature.length > 0 ?
                <section id="aditional-info" className="py-4">
                    <div className="container" id="payment-type">
                    <div className="row">
                        <div className="col-md-6 d-flex align-items-center justify-content-center divider_right">
                        <h3 className="color m-0">{this.props.t("restaurant_detail.additional_information")}</h3>
                        </div>
                        <div className="col-md-6 d-flex justify-content-center">
                       

                            {this.state.resturants.merchant_additional_feature.map((af, index) => {
                                return(
                                        af.is_available == 1 ?
                                        <div key={index} className="img-bg-payment mx-md-4">
                                            <img src={"../../img/"+ af.feature +".png"} className="img-fluid" />
                                        </div>
                                        : ''

                                       )
                                    })
                               
                            }
                    
                        </div>
                    </div>
                    </div>
                </section>
                : ''
                }
                { this.state.resturants.merchant_location.mapEmbedUrl !== undefined ?
                <section id="map" className="pt-0">
                    <iframe src={this.state.resturants.merchant_location.mapEmbedUrl} width="100%" height={550} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                   
                </section>
                : ''
                }
                { this.state.resturants.merchant_slider_images.length > 0 ?
                <section className="pt-0">
                    <div className="container">
                    <div className="sb-group-title">
                        <div className="sb-left">
                        <h1 className="sb-mb-30 mt-0 color-black">{this.props.t("restaurant_detail.see_our")} <span className="color">{this.props.t("restaurant_detail.photos")}</span></h1>
                        <p className="sb-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua.
                        </p>
                        </div>
                    </div>
                    <div className="margintop60">
                        <ul className="grid no-pad text">
                        { this.state.resturants.merchant_slider_images.map((slider, index) => {
                            return(
                                    index == 0 ?
                                    <li className="halfbox" key={index}>
                                        <a href={slider.image_url} className="image-to-background" data-imagelightbox="h" data-ilb2-caption="Food Image" style={{backgroundImage: 'url('+slider.image_url+')'}}>
                                        <img src={slider.image_url} alt="Some desc" />
                                        <div className="overlay-wrapper">
                                            <div className="overlay-inner">
                                            <div className="center">
                                                <h3>{slider.title}</h3>
                                                <p>{slider.description}</p>
                                            </div>
                                            </div>
                                        </div>
                                        </a>
                                    </li>
                                    : 
                                    <li className="quarterbox" key={index}>
                                        <a href={slider.image_url} className="image-to-background" data-imagelightbox="h" data-ilb2-caption="Interior Image 1" style={{backgroundImage: 'url('+slider.image_url+')'}}>
                                        <img src={slider.image_url} alt="Some desc" />
                                        <div className="overlay-wrapper">
                                            <div className="overlay-inner">
                                            <div className="center">
                                                 <h3>{slider.title}</h3>
                                                <p>{slider.description}</p>
                                            </div>
                                            </div>
                                        </div>
                                        </a>
                                    </li>
                            )
                        })
                        }
                        {/* <li className="quarterbox">
                            <a href="../../img/see-photo/eg-1.jpg" className="image-to-background" data-imagelightbox="h" data-ilb2-caption="Event Image 1" style={{backgroundImage: 'url(img/see-photo/eg-1.jpg)'}}>
                            <img src="../../img/see-photo/eg-1.jpg" alt="Some desc" />
                            <div className="overlay-wrapper">
                                <div className="overlay-inner">
                                <div className="center">
                                    <h3>Event</h3>
                                    <p>Image discription...</p>
                                </div>
                                </div>
                            </div>
                            </a>
                        </li>
                        <li className="quarterbox">
                            <a href="../../img/see-photo/eg-2.jpg" className="image-to-background" data-imagelightbox="h" data-ilb2-caption="Event Image 2" style={{backgroundImage: 'url(img/see-photo/eg-2.jpg)'}}>
                            <img src="../../img/see-photo/eg-2.jpg" alt="Some desc" />
                            <div className="overlay-wrapper">
                                <div className="overlay-inner">
                                <div className="center">
                                    <h3>Event</h3>
                                    <p>Image discription...</p>
                                </div>
                                </div>
                            </div>
                            </a>
                        </li>
                        <li className="quarterbox">
                            <a href="../../img/see-photo/ig-2.jpg" className="image-to-background" data-imagelightbox="h" data-ilb2-caption="Interior Image 2" style={{backgroundImage: 'url(img/see-photo/ig-2.jpg)'}}>
                            <img src="../../img/see-photo/ig-2.jpg" alt="Some desc" />
                            <div className="overlay-wrapper">
                                <div className="overlay-inner">
                                <div className="center">
                                    <h3>Interior</h3>
                                    <p>Image discription...</p>
                                </div>
                                </div>
                            </div>
                            </a>
                        </li>
                        <li className="halfbox">
                            <a href="../../img/see-photo/dg-1.jpg" className="image-to-background" data-imagelightbox="h" data-ilb2-caption="Desert Image" style={{backgroundImage: 'url(img/see-photo/dg-1.jpg)'}}>
                            <img src="../../img/see-photo/dg-1.jpg" alt="Some desc" />
                            <div className="overlay-wrapper">
                                <div className="overlay-inner">
                                <div className="center">
                                    <h3>Desert</h3>
                                    <p>Image discription...</p>
                                </div>
                                </div>
                            </div>
                            </a>
                        </li> */}
                        </ul>
                    </div>
                    </div>
                </section>
                : ''
                }
                { this.state.resturants.merchant_aboutus !== undefined ?
                <section className="sb-video py-0">
                    <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                        <div className="sb-mb-90">
                            <h1 className="sb-mb-30 mt-0 color-black">{this.props.t("restaurant_detail.about")} <span className="color">{this.props.t("restaurant_detail.us")}</span></h1>
                            <p className="sb-text sb-mb-30">
                           {this.state.resturants.merchant_aboutus.description}
                            </p>
                        </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                        <div className="sb-illustration-7 sb-mb-90">
                            <div className="sb-interior-frame">
                            <img src={this.state.resturants.merchant_aboutus.image_url} alt="interior" className="sb-interior" />
                            </div>
                            <div className="sb-cirkle-1" />
                            <div className="sb-cirkle-2" />
                            <div className="sb-cirkle-3" />
                            <div className="sb-cirkle-4" />
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
                : ' '
                }
                { this.state.resturants.merchant_payment_types.length > 0 ?
                <section id="portfolio" className="bg-black-cus white py-5">
                    <div className="title header-line text-center">
                    <div>
                        <h2 className="color">{this.props.t("restaurant_detail.payment_types")}</h2>
                    </div>
                    <small className="mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua.</small>
                    </div>
                    <div className="container" id="payment-type">
                    <div className="row">
                        <div className="col-md-4 offset-md-4 mt-4 d-flex justify-content-around">

                        { this.state.resturants.merchant_payment_types.map((payment, index) => {
                            return(
                   
                                <div className="img-bg-payment" key={index}>
                                    <img src={payment.image_url} className="img-fluid mw-60" />
                                </div>
                            )
                            })
                        }
                        {/* <div className="img-bg-payment">
                            <img src="../../img/master-c.png" className="img-fluid mw-85" />
                        </div>
                        <div className="img-bg-payment">
                            <img src="../../img/visa.png" className="img-fluid mw-85" />
                        </div> */}
                        </div>
                    </div>
                    </div>
                </section>
                : ''
                }
                {this.state.resturants.merchant_restaurant_timing.length > 0 ?
                <section id="info" className="bg-white">
                    <div className="container">
                    <div className="text-center">
                        <div className>
                        <h1 className="sb-mb-30 text-center color-black">{this.props.t("restaurant_detail.opening")} <span className="color">{this.props.t("restaurant_detail.hours")} </span></h1>
                        </div>
                        <small className="my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua.</small>
                    </div>
                    <div className="row text-center d-flex justify-content-md-center justify-content-sm-center align-items-center">
                        
                        { this.state.resturants.merchant_restaurant_timing.map((timing, index) => {
                            return(
                                <div className={"col-sm-2 " +( timing.day_of_week == "Sunday" ? "padtop30 padbottom30 " : "col-xs-6-cus divider_right")}>
                                { timing.day_of_week == "Sunday" ?
                                <h2 className="deco-font color margintop10">{timing.day_of_week}</h2>
                                :  <h4 className="margintop10">{timing.day_of_week}</h4>
                                }
                                <p>{timing.start_end_times[0].start_time} - {timing.start_end_times[0].end_time}</p>
                                { timing.is_closed == 0 ?
                                <p>{timing.start_end_times[1].start_time} - {timing.start_end_times[1].end_time}</p>
                                :<p>CLOSED</p>
                                }
                               
                               </div>
                                )
                            })
                        }
                     
                        {/* <div className="col-sm-2 col-xs-6-cus divider_right">
                        <h4 className="margintop10">Tuesday</h4>
                        <p>20:00 - 00:00</p>
                        </div>
                        <div className="col-sm-2 col-xs-6-cus divider_right">
                        <h4 className="margintop10">Wednesday</h4>
                        <p>20:00 - 00:00</p>
                        </div>
                        <div className="col-sm-2 col-xs-6-cus divider_right">
                        <h4 className="margintop10">THURSDAY</h4>
                        <p>20:00 - 00:00</p>
                        </div>
                        <div className="col-sm-2 col-xs-6-cus divider_right">
                        <h4 className="margintop10">FRIDAY</h4>
                        <p>20:00 - 00:00 </p>
                        </div>
                        <div className="col-sm-2 col-xs-6-cus divider_right">
                        <h4 className="margintop10">SATURDAY</h4>
                        <p className="m-0">14:00 - 18:00</p>
                        <p className="m-0">20:00 - 00:00</p>
                        </div> */}
                        {/* <div className="col-md-2 padtop30 padbottom30">
                        <h2 className="deco-font color margintop10">Saunday</h2>
                        <p>CLOSED</p>
                        </div> */}
                    </div>
                    </div>
                </section>
                :''
                }
            </div>
            <div id="modal-container">
                <div className="modal-background">
                    <div className="modal">
                    <div className="quick_link_wrp active">
                        <div className="quick_link_wrp_inner">
                        <button className="quick_link_close_back" onClick={this.handleMenuClose}><img src="../../img/food/back2quick.png" />GO BACK</button>
                        <button className="quick_link_close" onClick={this.closeModal}>Close </button>
                        {/* left-contet-start */}
                        <div className="quick_link_left active">
                            <h2>{this.state.resturants.merchant_restaurant.restaurant_name}</h2>
                            <h5>{this.state.resturants.merchant_restaurant_types.displayName} </h5>
                            <p>{this.state.resturants.merchant_location.address_line1} {this.state.resturants.merchant_location.address_line2}{this.state.resturants.merchant_location.address_line3}</p>
                            <p>{this.state.resturants.merchant_location.postal_code}, {this.state.resturants.merchant_location.city.displayName}, {this.state.resturants.merchant_location.subregion.displayName},  {this.state.resturants.merchant_location.region.displayName}</p>
                            <h6><a href="#" target="_blank">VIEW WEBSITE</a></h6>
                            <strong><a href={"tel:+"+this.state.resturants.merchant_location.phone_number1_display+""}><img src="../../img/food/phone_icon_gold.png" />{this.state.resturants.merchant_location.phone_number1_display}</a></strong>
                        </div>
                        {/* left-contet-end */}
                        {/* right-contet-start */}
                        <div className="quick_link_right">
                            <div className="quick_link_inner_wrp">
                            {/* main-food-type-menu-start */}
                            <ul className="quick_link_inner_wrp_ul stop_anim ">
                                <li className="show_open_menue" >
                                <a href="javascript:void(0)" data-attr="open_food" onClick={this.handleMenu}>
                                    <div className="foodtypes">
                                        <img src="../../img/food/qcicon_food-1.png" />
                                    </div>
                                    <p>YOUR FOOD TYPES</p>
                                </a>
                                </li>
                                { this.state.resturants.merchant_menu_images.length > 0 ||  this.state.resturants.merchant_menu_pdf !== null ?
                                <li>
                                <a href="javascript:void(0);" data-attr="open_menu" onClick={this.handleMenu}>
                                    <div className="menu_">
                                    <img src="../../img/food/qcicon_menu.png" />
                                    </div>
                                    <p>Menu</p>
                                </a>
                                </li>
                                 : ""
                                }
                                <li className="show_open_menue" data-attr="open_about">
                                <a href="javascript:void(0);" data-attr="open_about" onClick={this.handleMenu}>
                                    <div className="aboutus">
                                    <img src="../../img/food/qcicon_about.png?5" />
                                    </div>
                                    <p>About us</p>
                                </a>
                                </li>
                                
                                
                                <li>
                                <a href="javascript:void(0)" className="quick_menu_photoss" data-attr="quick_menu_photos" onClick={this.handleMenu}>
                                    <div className="photos">
                                    <img src="../../img/food/qcicon_photos.png" />
                                    </div>
                                    <p>PHOTOS</p>
                                </a>
                                </li>
                                
                                <li className="show_open_menue" data-attr="open_pay"  onClick={this.handleMenu}>
                                <a href="javascript:void(0);">
                                    <div className="payment">
                                    <img src="../../img/food/qcicon_blank.png" />
                                    <img className="card_01" src="../../img/food/qc_card_01.png" />
                                    <img className="card_02" src="../../img/food/qc_card_02.png" />
                                    </div>
                                    <p>PAYMENT TYPES</p>
                                </a>
                                </li>
                                <li className="show_open_menue" data-attr="open_hours"  onClick={this.handleMenu}>
                                <a href="javascript:void(0);">
                                    <div className="openinghr">
                                    <img src="../../img/food/qcicon_hours2.png" />
                                    </div>
                                    <p>Opening hours</p>
                                </a>
                                </li>
                            </ul>
                            {/* main-food-type-menu-end */}
                            <div className="quick_link_opened">
                                {/* FOOD TYPE SUB MENU */}
                                <div className="foodtype_wrp open_food d-none">
                                    <div className="quick_link_inner_wrp_title">
                                        <a href="#food_type">
                                        <div className="foodtypes">
                                            <img src="../../img/food/menu_bg.png" />
                                            <img className="menu_inner_img" src="../../img/food/menu_icon_inner.png" />
                                        </div>
                                        <p>YOUR FOOD TYPES</p>
                                        </a>
                                    </div>
                                    { this.state.resturants.merchant_foods.length > 0 ?
                                    <div className="quick_link_inner_wrp_content">
                                        <ul>
                                        {this.state.resturants.merchant_foods.map((f, index) => {
                            
                                            return (
                                                <li key={index}>
                                                    <div className="new_food_typ_listing">
                                                    <div className="new_food_typ_thumb"  style={{ background: "url("+f.image_url+")"}} >
                                                    </div>
                                                    <p>{f.displayName}</p>
                                                    </div>
                                                </li>
                                              )
                                            })
                                        }
                                       
                                        </ul>
                                    </div>
                                    :""
                                    }
                                </div>
                                {/* FOOD TYPE SUB MENU */}
                                {/* ABOUT MENU */}
                                <div className="qck_open_bx open_about active d-none">
                                <div className="qck_menu_title text-center">
                                    <img src="../../img/food/qcicon_about.png" />
                                    <h4>About us</h4>
                                </div>
                                { this.state.resturants.merchant_aboutus !== undefined ?
                                <div className="qxk_menu_content">
                                    <p> {this.state.resturants.merchant_aboutus.description}
                                    </p>
                                </div>
                                :""
                                }
                                </div>
                                {/* ABOUT MENU */}
                                {/* Payment Menu*/}
                                <div className="qck_open_bx open_pay active d-none">
                                <div className="qck_menu_title text-center">
                                    <div className="payment">
                                    <img src="../../img/food/qcicon_blank.png" />
                                    <img className="card_01" src="../../img/food/qc_card_01.png" />
                                    <img className="card_02" src="../../img/food/qc_card_02.png" />
                                    </div>
                                    <h4>PAYMENT TYPES</h4>
                                </div>
                                <div className="qxk_menu_content">
                                    <div className="new_payment_wrp">
                                    <h4>WE ACCEPT</h4>
                                    { this.state.resturants.merchant_payment_types.length > 0 ?
                                    <ul>
                                         { this.state.resturants.merchant_payment_types.map((payment, index) => {
                                        return(
                                            <li key={index}><img className src={payment.image_url} /></li>
                                        
                                            )
                                        })
                                    }
                                    </ul>
                                    :""
                                    }
                                    </div>
                                </div>
                                </div>
                                {/* Payment Menu*/}
                                {/* Opening Hours Menu*/}
                                <div className="qck_open_bx open_hours  active d-none">
                                <div className="qck_menu_title text-center">
                                    <div className="openinghr">
                                    <img src="../../img/food/qcicon_hours2.png" />
                                    </div>
                                    <h4>Opening hours</h4>
                                </div>
                                {this.state.resturants.merchant_restaurant_timing.length > 0 ?
                                <div className="qxk_menu_content">
                                    <div className="openg_hr_section" id="openg_hr">
                                    <ul>
                                    { this.state.resturants.merchant_restaurant_timing.map((timing, index) => {
                                    return(
                                            <li>
                                            <div className="work_time_each">
                                                <label>{timing.day_of_week}</label>
                                                <p>{timing.start_end_times[0].start_time} - {timing.start_end_times[0].end_time}</p>
                                                <hr />
                                                { timing.is_closed == 0 ?
                                                <p>{timing.start_end_times[1].start_time} - {timing.start_end_times[1].end_time}</p>
                                                :<p>CLOSED</p>
                                                }
                                            </div>
                                            </li>
                                             )
                                            })
                                        }

                                       
                                    </ul>
                                    </div>
                                </div>
                                :""
                                }
                                </div>
                                {/* Opening Hours Menu*/}
                            </div>
                            </div>
                            <div className="aboutus_wrp" />
                            <div className="openinghr_wrp" />
                            <div className="payment_wrp" />
                        </div>
                        {/* left-contet-end */}
                        </div>
                    </div>
                    </div>
                </div>
            </div>
         <div id="pop__slider" className="modal  in" role="dialog" style={{ display: 'none'}}>
            <div className="modal-dialog">
                {/* Modal content*/}
                <div className="modal-content">
                <div className="modal-body">
                    <div className="container">
                    <div className="outer">
                        <button type="button" className="close" data-dismiss="modal" onClick={this.closeModalSlider}>×</button>
                        { this.state.resturants.merchant_slider_images.length > 0 ?
                        <OwlCarousel id="big" className="owl-theme owl-carousel-custom res_gallery" items={1} lazyLoad={true} responsiveRefreshRate={200} margin={10} responsive={res} navText={['<i class="fa fa-arrow-left" aria-hidden="true"></i>',
                        '<i class="fa fa-arrow-right" aria-hidden="true"></i>']} autoplay={false} nav dots={false}>

                                { this.state.resturants.merchant_slider_images.map((photos, index) => {
                                        return(
                                            <div>
                                                <img src="../../img/slider-img-2.jpg" className="img-responsive" />
                                                <h4>{photos.title}</h4>
                                                <p>{photos.description}</p>
                                            </div>
                                        )
                                    })
                               
                                }
                           
                        </OwlCarousel>
                        : ""
                        }
                        { this.state.resturants.merchant_slider_images.length > 0 ?
                        <OwlCarousel id="thumb" className="owl-theme owl-carousel-custom res_gallery_thumb" items={8}  margin={10} responsive={res2} navText={['<i class="fa fa-arrow-left" aria-hidden="true"></i>',
                        '<i class="fa fa-arrow-right" aria-hidden="true"></i>']} autoplay={false} nav dots={false}>

                                { this.state.resturants.merchant_slider_images.map((photos, index) => {
                                        return(
                                            <div onClick={this.handClickModal}>
                                                <img src="../../img/slider-img.jpg" className="img-responsive" />
                                            </div>
                                        )
                                    })
                               
                                }

                        </OwlCarousel>
                       : ""
                    }
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </>
    );
    }else{
        return (
            <>
            <div className="row justify-content-center align-items-center h-70">
                <Loading />
            </div> 
            </>
        )
    }
  }
}

function mapStateToProps(state) {
    console.log(state);
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  let { restaurants } = state.restaurants;
  if(state.restaurants){
      
    restaurants = state.restaurants[1];
}
  return {
    isLoggedIn,
    message,
    restaurants: restaurants,
  };
}

export default compose(withTranslation(), connect(mapStateToProps,{
    getRestaurantDetailByslug
}))(Individual);
