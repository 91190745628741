import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import Message from "./common/message";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { login } from "../actions/auth";
import {  Form } from "react-bootstrap";


// const required = (value) => {
//   if (!value) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This field is required!
//       </div>
//     );
//   }
// };

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);


    
    this.state = {
      email: "",
      password: "",
      loading: false,
    };
  }

  schema =  yup.object().shape({
    
    email: yup.string().email('user.addForm.validation.validemail').required('user.addForm.validation.email'),
    password: yup.string().required('user.addForm.validation.password')
  
    });

    componentDidMount() {
        window.scrollTo(0, 0);
      }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  async handleLogin(values) {
    // e.preventDefault();

    this.setState({
      loading: true,
    });

    // this.form.validateAll();

    const { dispatch, history } = this.props;

    // if (this.checkBtn.context._errors.length === 0) {
      dispatch(login(values.email, values.password))
        .then(() => {
          history.push("/merchant/dashboard");
          // window.location = "/profile";
        })
        .catch(() => {
          this.setState({
            loading: false,
          });
        });
    // } else {
    //   this.setState({
    //     loading: false,
    //   });
    // }
  }

  render() {
    const { isLoggedIn, message } = this.props;

    // if (isLoggedIn) {
    //   return <Redirect to="/merchant/dashboard" />;
    // }

    return (
    <div className="forny-container">
        <div className="forny-inner">
            <div className="forny-two-pane">
            <div>
                <div className="forny-form">
                {/* <div class="mb-8 forny-logo">
                        <img src="img/zam-logo.png">
                        </div> */}
                <div>
                    <h1 className="mb-2 color">Reset Your <span className="text-dark">Password</span></h1>
                    <p className="mb-5">Please enter your email address and we will send you a password password link.</p>
                </div>
                <form>
                    <div className="row">
                    <div className="form-group border-radius-100 col-md-12">
                        <div className="input-group">
                        <input required className="form-control" name="username" type="username" placeholder="Enter Email Address" />
                        </div>
                    </div>
                    </div>
                    <div className="text-center">
                    <button className="btn btn-bg-custom px-5 border-radius-100">Send Reset Link</button>
                    </div>
                    <div className="mt-10">
                    Already have an account? <Link to={"/login"}>Login here</Link>
                    </div>
                </form>
                </div>
            </div>
            <div />
            </div>
        </div>
    </div>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default compose(withTranslation(), connect(mapStateToProps))(ForgotPassword);
