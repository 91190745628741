import {
  GET_RESTAURANT_DATA,
    // DELETE_ALL_RATES,
  } from "../actions/types";
  
  const initialState = [];
  
  function restaurantReducer(restaurants = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_RESTAURANT_DATA:
        return [restaurants, payload];
  
     
      default:
        return restaurants;
    }
  }
  
  export default restaurantReducer;
  
  