import React, { Component } from "react";
import i18n from "../../i18n/i18n";
import { NavLink, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import configData from "../../config.json";

class AppFooter extends Component {
   constructor(props) {
    super(props);

    if (localStorage.getItem("lan")) {
      this.state = {
        selectedLang: localStorage.getItem("lan"),
      };
    } else {
      this.state = {
        selectedLang: "en",
      };
    }

    this.changeLanguage = this.changeLanguage.bind(this);

  } 

  componentDidMount() {

    // alert('ddd-');

  }

  
  componentDidUpdate() {
  
    if (localStorage.getItem("lan") !== this.state.selectedLang) {
      this.setState({
        selectedLang:localStorage.getItem("lan"),
      });
     
    } 
   
  }

  changeLanguage(e) {

    // console.log(e);
    this.setState({
      selectedLang: e.target.value,
    });

    localStorage.setItem("lan", e.target.value);

    i18n.changeLanguage(e.target.value);
  }

  render() {


    // console.log(typeof this.state.selectedLang,  this.state.selectedLang, 'footer');
    //_pre(" Header ", this.props);
    // const { currentUser } = this.props;

    //_pre(" buttonClass ", buttonClass);
    //_pre(" t ", this.props.toggled);
    if(this.props.history.location.pathname !== '/authetication' && this.props.history.location.pathname !== '/login' && this.props.history.location.pathname !== '/register'){
      return (
 
      <div>
        <div className="rts-footer-area pt-0 footer-three bg-soft-purple-footer-color rts-section-gapTop footer-bg-2 footer-cus-css-apply" id="contact_us"> 
          <div className="container">
            <div className="row g-5">
              <div className="col-xl-4 col-lg-6 mt-0 pt--60 pb--40">
                <div className="footer-three-single-wized left">
                  <p className="disc">Welcome to a true oasis we designed especially for you. Here you’ll find absolutely everything you’ll need to present your health, spa or beauty oriented business the modern way.</p>
                  <ul className="social-three-wrapper">
                    <li><a href="#"><i className="fab fa-instagram" /></a></li>
                    <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                    <li><a href="#"><i className="fab fa-linkedin-in" /></a></li>
                    <li><a href="#"><i className="fab fa-pinterest" /></a></li>
                  </ul>
                </div>
              </div>
              {/* footer three mid area */}
              <div className="col-xl-2 col-lg-6 mt-0 col-md-6 col-sm-12 col-12 pt--60 pb--40">
                <div className="footer-two-single-wized two">
                  <div className="wized-title-area">
                    <h5 className="wized-title">Useful Links</h5>
                  </div>
                  <div className="wized-2-body">
                    <ul>
                      <li>
                        <a href="#">Contact Us</a>
                      </li>
                      <li>
                        <a href="#">Experts</a>
                      </li>
                      <li>
                        <a href="#">Experience</a>
                      </li>
                      <li>
                        <a href="#">Our Team</a>
                      </li>
                      <li>
                        <a href="#">Career Opportunity</a>
                      </li>
                      <li>
                        <a href="#">Advertising</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-6 mt-0 col-md-6 col-sm-12 col-12 pt--60 pb--40">
                <div className="footer-two-single-wized two">
                  <div className="wized-title-area">
                    <h5 className="wized-title">Explore</h5>
                  </div>
                  <div className="wized-2-body">
                    <ul>
                      <li>
                        <a href="#">
                          Appointment</a>
                      </li>
                      <li>
                        <a href="#">
                          Location</a>
                      </li>
                      <li>
                        <a href="#">
                          Pricing</a>
                      </li>
                      <li>
                        <a href="#">
                          Discount</a>
                      </li>
                      <li>
                        <a href="#">
                          Blog</a>
                      </li>
                      <li>
                        <a href="#">
                          Gallery</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* footer three mid area ENd */}
              <div className="col-xl-4 col-lg-6 mt-0 pt--60 pb--40 plr--30" style={{background: '#9b9bc7'}}>
                <div className="footer-three-single-wized right for-mobile-cus-padding">
                  <h5 className="title">Contact</h5>
                  <div className="body footer-form">
                    <div className="form-wrapper">
                      <div id="form-messages" />
                      <form id="contact-form" action="mailer.php" method="post">
                        <input type="text" name="name" placeholder="Name" required />                      
                        <input type="email" name="email" placeholder="Email" required />
                        <textarea placeholder="Message" name="message" defaultValue={""} />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-area">
            <div className="container">
              <div className="row">
                <div className="col-12 d-md-flex justify-content-md-between ">
                  <p className="disc ptb--25 m-0">
                    Copyright © 2024 Kardarma. All rights reserved
                  </p>
                  <p className="disc text-end ptb--25 m-0">
                    <a href="#" className="mx-3">Privacy Policy</a>
                    <a href="#" className="mx-3">Cookie Policy</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
 
          <div className="loader-wrapper">
            <div className="loader">
            </div>
            <div className="loader-section section-left" />
            <div className="loader-section section-right" />
          </div>

        <div className="progress-wrap">
          <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
      
    </div>

      );
    }else{
      return(
        <></>
      )
    }
  }
}

function mapStateToProps(state) {

  
 
}
//export default Header;

export default withTranslation()(withRouter(connect(mapStateToProps, {
})(AppFooter))); 
