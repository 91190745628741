import {
    CREATE_USER,
    RETRIEVE_USERS,
    DELETE_USER,
    UPDATE_USER,
    //RETRIEVE_TUTORIALS,
    //UPDATE_TUTORIAL,
    //DELETE_TUTORIAL,
    // DELETE_ALL_TUTORIALS,
    SET_MESSAGE,
  } from "./types";
  import CommonHelper from "../utils/CommonHelper";
  
  import UserDataService from "../services/users.service";
  // import { _pre } from "../utils/vlogs";
  
  export const createUser = (formData) => async (dispatch) => {
    try {
      const res = await UserDataService.create(formData);
  
      dispatch({
        type: CREATE_USER,
        payload: res.data,
      });
  
      /* const resMessage = {
        message: res.data.message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      }); */
      return Promise.resolve(res.data);
    } catch (error) {

      // console.log('in error of create restaurant');

      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
  
    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    // console.log(resMessage, 'restaurant');

    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
      return Promise.reject(error);
    }
  };
  
  export const retrieveUsers = (page, perPage) => async (dispatch) => {
    try {
      const res = await UserDataService.getAll(page, perPage);
      // console.log("ACTION TU : retrieveUsers " + page);
      // console.log(res.data.data);
      dispatch({
        type: RETRIEVE_USERS,
        payload: res.data.data,
      });
    } catch (err) {
      // console.log(err);
    }
  };
  
  export const getUser = (id) => async (dispatch) => {
    try {
      const res = await UserDataService.getRecord(id);
  
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  export const getUserRole = () => async (dispatch) => {
    try {
      const res = await UserDataService.getUserRole();
  
      dispatch({
        type: CREATE_USER,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  
  export const updateUser = (id, data) => async (dispatch) => {
    try {
      const res = await UserDataService.update(id, data);
  
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const deleteUser = (id) => async (dispatch) => {
    try {
      const res = await UserDataService.delete(id);
      dispatch({
        type: DELETE_USER,
        //payload: { id },
        payload: res.data,
      });
      // _pre(" test ", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      // console.log(err);
    }
  };


  export const userChatLogin = (id) => async (dispatch) => {
    try {
      const res = await UserDataService.userChatLogin(id);
      
      // console.log(res, 'dispatch user action');
      dispatch({
        type: CREATE_USER,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  

  