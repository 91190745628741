import React from "react";
import { Route, Redirect } from "react-router-dom";
//import auth from "../../services/authService";
import AuthService from "../../services/auth.service";
import Cookies from 'universal-cookie';
import configData from "../../config.json";

const PrivateRoute = ({ path, component: Component, render, ...rest }) => {
    const cookies = new Cookies();
  console.log(path, Component, render,cookies.get('authetication'), 'dddddddddddddddddddddddddddddddddddddddddddddddddddd' );
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!cookies.get('authetication')) {
          return (
            <Redirect
              to={{
                pathname: "/authetication",
                state: { from: props.location },
              }}
            />
          );
        } else {
          return Component ? <Component {...props} /> : render(props);
        } 
        
      }}
    />
  );
};

export default PrivateRoute;
