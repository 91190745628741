import React, { Component } from "react";
//import { Redirect } from "react-router-dom";
//import * as yup from "yup";
//import Message from "./common/message";
import { Link } from "react-router-dom";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
//import { login } from "../../../actions/auth";

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    /*  const genres = getGenres();
    this.setState({ genres });

    const movieId = this.props.match.params.id;
    if (movieId === "new") return;

    const movie = getMovie(movieId);
    if (!movie) return this.props.history.replace("/not-found");

    this.setState({ data: this.mapToViewModel(movie) }); */
  }
  render() {
    //const { message } = this.props;
    return (
      <>
        <div className="sb-app">
          <div id="sb-dynamic-content" className="sb-transition-fade">
            <section className="sb-banner sb-banner-sm sb-banner-color">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="sb-main-title-frame">
                      <div className="sb-main-title text-center">
                        <span className="sb-suptitle sb-mb-30">{this.props.t("aboutUs.about_us")}</span>

                        <h1 className="sb-mb-30 color-black m-0">
                        {this.props.t("aboutUs.there_is")} <span className="color">{this.props.t("aboutUs.love")}</span> {this.props.t("aboutUs.more_sincere")} <br />
                        {this.props.t("aboutUs.than_the_love_of")}<span className="color">{this.props.t("aboutUs.food")}</span>.
                        </h1>

                        <p className="sb-text sb-text-lg sb-mb-30">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. <br />
                          Ut enim ad minim veniam, quis nostrud exercitation
                          ullamco laboris nisi ut aliquip ex ea commodo
                          consequat.
                        </p>

                        <ul className="sb-breadcrumbs">
                          <li>
                            <Link to="/">{this.props.t("aboutUs.home")}</Link>
                          </li>

                          <li>
                            <Link to="/about-us">{this.props.t("aboutUs.about_us")}</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="pb-0">
              <div className="sb-bg-2">
                <div></div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-lg-6 align-self-center">
                    <div className="sb-illustration-2 sb-mb-90">
                      <div className="sb-interior-frame">
                        <img
                          src="img/abp_hero-2.jpeg"
                          alt="interior"
                          className="sb-interior"
                        />
                      </div>

                      <div className="sb-square"></div>

                      <div className="sb-cirkle-1"></div>

                      <div className="sb-cirkle-2"></div>

                      <div className="sb-cirkle-3"></div>

                      <div className="sb-cirkle-4"></div>

                      <div className="sb-experience">
                        <div className="sb-exp-content"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 align-self-center">
                    <h1 className="sb-mb-30 mt-0 color-black">
                    {this.props.t("aboutUs.company")} <span className="color">{this.props.t("aboutUs.pledge")}</span>
                    </h1>

                    <p className="sb-text sb-mb-30">
                    {this.props.t("aboutUs.company_pledge_text")}
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="sb-p-0-30 pt-0">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="sb-features-item sb-mb-60">
                      <div className="sb-number">01</div>

                      <div className="sb-feature-text">
                        <h2 className="sb-mb-15 color-black m-0">
                        {this.props.t("aboutUs.mission_statement")}
                        </h2>

                        <p className="sb-text">
                        {this.props.t("aboutUs.mission_text1")}
                          <br />
                          {this.props.t("aboutUs.mission_text")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <div className="sb-features-item sb-mb-60">
                      <div className="sb-number">02</div>

                      <div className="sb-feature-text">
                        <h2 className="sb-mb-15 color-black m-0">{this.props.t("aboutUs.our_vision")}</h2>

                        <p className="sb-text">
                        {this.props.t("aboutUs.develop_text")}
                        </p>
                      </div>
                    </div>

                    <div className="sb-features-item sb-mb-60">
                      <div className="sb-number">03</div>

                      <div className="sb-feature-text">
                        <h2 className="sb-mb-15 color-black m-0">{this.props.t("aboutUs.goal")}</h2>

                        <p className="sb-text">
                        {this.props.t("aboutUs.our_goal_text")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="sb-video py-0">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="sb-mb-90">
                      <span className="sb-suptitle sb-mb-15">Lorem Ipsum</span>

                      <h1 className="sb-mb-30 color-black">
                      {this.props.t("aboutUs.restaurant_is_like")}{" "}
                        <span className="color">
                        {this.props.t("aboutUs.theater")}
                          <br /> {this.props.t("aboutUs.our_task")}
                        </span>
                      </h1>

                      <p className="sb-text sb-mb-30">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat.
                      </p>

                      <a
                        data-fancybox=""
                        data-no-swup
                        href="#!"
                        className="sb-btn"
                      >
                        <span className="sb-icon">
                          <img src="img/play.svg" alt="icon" />
                        </span>

                        <span>{this.props.t("aboutUs.learn_more")}</span>
                      </a>
                    </div>
                  </div>

                  <div className="col-lg-6 align-self-center">
                    <div className="sb-illustration-7 sb-mb-90">
                      <div className="sb-interior-frame">
                        <img
                          src="img/s2.jpg"
                          alt="interior"
                          className="sb-interior"
                        />
                      </div>

                      <div className="sb-cirkle-1"></div>

                      <div className="sb-cirkle-2"></div>

                      <div className="sb-cirkle-3"></div>

                      <div className="sb-cirkle-4"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="sb-team pt-0">
              <div className="sb-bg-2">
                <div></div>
              </div>

              <div className="container">
                <div className="sb-group-title sb-mb-30">
                  <div className="sb-left sb-mb-30">
                    <h1 className="sb-mb-30 color-black">
                    {this.props.t("aboutUs.meet_the")}<span className="color">{this.props.t("aboutUs.team")}</span>
                    </h1>

                    <p className="sb-text">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>

                  <div className="sb-right sb-mb-30">
                    <a href="#!" className="sb-btn sb-m-0">
                      <span className="sb-icon">
                        <img src="img/menu.svg" alt="icon" />
                      </span>

                      <span>{this.props.t("aboutUs.open_menu")}</span>
                    </a>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4">
                    <div className="sb-team-member sb-mb-30">
                      <div className="sb-photo-frame sb-mb-15">
                        <img src="img/team-1.jpg" alt="Team member" />
                      </div>

                      <div className="sb-member-description">
                        <h3 className="sb-mb-10 color-black">
                          Gareth Maidment
                        </h3>

                        <p className="sb-text sb-text-sm sb-mb-10 color">
                        {this.props.t("aboutUs.founder_of_zamp")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="sb-team-member sb-mb-30">
                      <div className="sb-photo-frame sb-mb-15">
                        <img src="img/team-2.jpg" alt="Team member" />
                      </div>

                      <div className="sb-member-description">
                        <h3 className="sb-mb-10 color-black">Hima Shyam</h3>

                        <p className="sb-text sb-text-sm sb-mb-10 color">
                        {this.props.t("aboutUs.lead_develop")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="sb-team-member sb-mb-30">
                      <div className="sb-photo-frame sb-mb-15">
                        <img src="img/team-3.jpg" alt="Team member" />
                      </div>

                      <div className="sb-member-description">
                        <h3 className="sb-mb-10 color-black">Sean O'Reilly</h3>

                        <p className="sb-text sb-text-sm sb-mb-10 color">
                        {this.props.t("aboutUs.marketing_exe")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="sb-team-member sb-mb-30">
                      <div className="sb-photo-frame sb-mb-15">
                        <img src="img/team-4.jpg" alt="Team member" />
                      </div>

                      <div className="sb-member-description">
                        <h3 className="sb-mb-10 color-black">Tony Jose</h3>

                        <p className="sb-text sb-text-sm sb-mb-10 color">
                        {this.props.t("aboutUs.product_and_design")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="sb-team-member sb-mb-30">
                      <div className="sb-photo-frame sb-mb-15">
                        <img src="img/team-5.jpg" alt="Team member" />
                      </div>

                      <div className="sb-member-description">
                        <h3 className="sb-mb-10 color-black">
                          Cynthia Martínez
                        </h3>

                        <p className="sb-text sb-text-sm sb-mb-10 color">
                        {this.props.t("aboutUs.sales_customer")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="sb-team-member sb-mb-30">
                      <div className="sb-photo-frame sb-mb-15">
                        <img src="img/team-6.jpg" alt="Team member" />
                      </div>

                      <div className="sb-member-description">
                        <h3 className="sb-mb-10 color-black">Debin Denny</h3>

                        <p className="sb-text sb-text-sm sb-mb-10 color">
                      {this.props.t("aboutUs.seo_digital")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default compose(withTranslation(), connect(mapStateToProps))(AboutUs);
