import axios from "axios";
import authHeader from "./auth-header";
import configData from "../config.json";
//const API_URL = "http://localhost:8080/api/test/";
const apiEndpoint = configData.apiUrl + "/restaurant";

class RestaurantDataService {
  

  getRestaurant(data) {
    return axios.post(apiEndpoint + `/search/restaurants`, data, { headers: authHeader() });
    //return axios.get(apiEndpoint, { headers: authHeader() } );
  }

 
  getRestaurantList(data) {
    return axios.post(apiEndpoint + `/search/restaurants/list`, data, { headers: authHeader() });
    //return axios.get(apiEndpoint, { headers: authHeader() } );
  }

  getRestaurantDetailByslug(slug){
  
    return  axios.get(configData.apiUrl +`/restaurants/${slug}`, { headers: authHeader() });
  }

//   getCityData(data){
//     return axios.post(apiEndpoint + `/search/cities`, data,);
//   }

 

}

export default new RestaurantDataService();
