import React, { Component } from "react";
import { Link } from "react-router-dom";
import {  Form } from "react-bootstrap";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { withTranslation } from "react-i18next";
import { isEmail } from "validator";
import { compose } from "redux";
import { connect } from "react-redux";
import { register } from "../actions/auth";
import { Formik } from "formik";
import * as yup from "yup";
import Message from "./common/message";
import { toast } from "react-toastify";



class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      confirm_password:"",
      address:"",
      successful: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }


  schema = yup.object().shape({
    firstname:yup.string('user.addForm.validation.first_name').required('user.addForm.validation.first_name'),
    lastname:yup.string('user.addForm.validation.last_name').required('user.addForm.validation.last_name'),
    email:yup.string('user.addForm.validation.email').required('user.addForm.validation.email'),
    password:yup.string('user.addForm.validation.password').required('user.addForm.validation.password'),
    confirm_password:yup.string('user.addForm.validation.confirm_password').required('user.addForm.validation.confirm_password'),
    address:yup.string('user.addForm.validation.address').required('user.addForm.validation.address'),

  });
  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      successful: false,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      this.props
        .dispatch(
          register(this.state.username, this.state.email, this.state.password)
        )
        .then(() => {
          this.setState({
            successful: true,
          });
        })
        .catch(() => {
          this.setState({
            successful: false,
          });
        });
    }
  }

  async handleRegister(values) {
    // e.preventDefault();

    this.setState({
      loading: true,
    });

    let formData = new FormData();
    formData.append(`first_name`, values.firstname);
    formData.append(`last_name`, values.lastname);
    formData.append(`email`, values.email);
    formData.append(`password`, values.password);
    formData.append(`address`, values.address);
    // formdata.append(`last_name`, values.lastname);
    // this.form.validateAll();
    formData  = Object.fromEntries(formData);
//     console.log(formData);
// return false;
    const { dispatch, history } = this.props;

    // if (this.checkBtn.context._errors.length === 0) {
      dispatch(register(formData))
        .then((data) => {
          console.log(data);
          toast.success("user registerd successfully");
          history.push("/login");
          // window.location = "/profile";
        })
        .catch((error ) => {
          console.log(error );
          this.setState({
            loading: false,
          });
        });
    // } else {
    //   this.setState({
    //     loading: false,
    //   });
    // }
  }

  render() {
    
    const { isLoggedIn, message } = this.props;
    console.log(this.props);
    if (isLoggedIn) {
      console.log(isLoggedIn);
      return window.location.href = "/";
    }
    return (
      <div>
      <div className="rts-contact-form-area vh-100 login-page d-flex align-items-center" style={{background: 'url(assets/images/login-bg.jpg)', backgroundRepeat: 'no-repeat', width: '100%'}}>
        <div className="container">
          <div className="row">
            <div className="col-md-4 offset-md-4">
              <div className="rts-contact-fluid">
                <div className="rts-title-area contact-fluid text-center mb--25">
                  {/* <img className="login-logo-cus-css" src="assets/images/login-logo.png" /> */}
                  <h2 className="title">Register to Kardarma</h2>
                </div>
                <div className="form-wrapper">
                <Message message={message} />
                      <Formik
                      validationSchema={this.schema}
                      validateOnChange={false}
                      validateOnBlur={false}
                      enableReinitialize="true"
                      onSubmit={(values) => {
                        this.handleRegister(values);
                      }}
                      initialValues={{
                        firstname: this.state.firstname,
                        lastname: this.state.lastname,
                        email:this.state.email,
                        password:this.state.password,
                        confirm_password:this.state.confirm_password,
                        address:this.state.address,
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                      }) => (
                      <Form autoComplete="off" className="theme-form custom-btn" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group border-radius-100 col-md-6">
                      <Form.Control type="text" name="firstname"
                                placeholder={this.props.t(
                                  "register.first_name"
                                )}
                                value={values.firstname}
                                autoComplete="off"
                                onChange={handleChange}
                                isInvalid={!!errors.firstname} />
                                <Form.Control.Feedback
                                className=""
                                
                                type="invalid"
                              >
                                { this.props.t(errors.firstname)}
                              </Form.Control.Feedback>
                    {/* <div className="input-group">
                      <input required className="form-control" name="first_name" type="text" placeholder={this.props.t("register.first_name")}  value={values.firstname} onChange={handleChange} />
                    </div> */}
                  </div>
                  <div className="form-group border-radius-100 col-md-6">
                   
                    <Form.Control type="text" name="lastname"
                                placeholder={this.props.t(
                                  "register.last_name"
                                )}
                                value={values.lastname}
                                autoComplete="off"
                                onChange={handleChange}
                                isInvalid={!!errors.lastname} />
                      {/* <input  className="form-control" name="lastname" type="text" placeholder={this.props.t("register.last_name")} isInvalid={!!errors.lastname}  value={values.lastname} onChange={handleChange}/> */}
                      <Form.Control.Feedback
                                className=""
                                
                                type="invalid"
                              >
                                { this.props.t(errors.lastname)}
                              </Form.Control.Feedback>
                    
                  </div>
                </div>
                <div className="row">
                  <div className="form-group border-radius-100 col-md-12">
                   
                    <Form.Control type="email" name="email"
                                placeholder={this.props.t(
                                  "register.email_address"
                                )}
                                value={values.email}
                                autoComplete="off"
                                onChange={handleChange}
                                isInvalid={!!errors.email} />
                      {/* <input  className="form-control" name="email" type="email" placeholder={this.props.t("register.email_address")} isInvalid={!!errors.email}  value={values.email} onChange={handleChange}/> */}
                      <Form.Control.Feedback
                                className=""
                                
                                type="invalid"
                              >
                                { this.props.t(errors.email)}
                              </Form.Control.Feedback>
                    
                  </div>
                </div>
                <div className="row">
                  <div className="form-group border-radius-100 col-md-12">
                  <Form.Control type="text" name="address"
                                placeholder={this.props.t(
                                  "register.address"
                                )}
                                value={values.address}
                                autoComplete="off"
                                onChange={handleChange}
                                isInvalid={!!errors.address} />
                      {/* <input  className="form-control" name="address" type="text" placeholder={this.props.t("register.address")} isInvalid={!!errors.address} value={values.address} onChange={handleChange}/> */}
                      <Form.Control.Feedback
                                className=""
                                
                                type="invalid"
                              >
                                { this.props.t(errors.address)}
                              </Form.Control.Feedback>
                    
                  </div>
                </div>
                <div className="row">
                  <div className="form-group password-field border-radius-100 col-md-6">
                  <Form.Control type="password" name="password"
                                placeholder={this.props.t(
                                  "register.password"
                                )}
                                value={values.password}
                                autoComplete="off"
                                onChange={handleChange}
                                isInvalid={!!errors.password} />
                    {/* <div className="input-group">
                      <input  className="form-control" name="password" type="password" placeholder={this.props.t("register.password")} isInvalid={!!errors.password}  value={values.password} onChange={handleChange} /> */}
                      <Form.Control.Feedback
                                className=""
                                
                                type="invalid"
                              >
                                { this.props.t(errors.password)}
                              </Form.Control.Feedback>
                    {/* </div> */}
                  </div>
                  <div className="form-group password-field border-radius-100 col-md-6">
                    {/* <div className="input-group"> */}
                    <Form.Control type="password" name="confirm_password"
                                placeholder={this.props.t(
                                  "register.confirm_password"
                                )}
                                value={values.confirm_password}
                                autoComplete="off"
                                onChange={handleChange}
                                isInvalid={!!errors.confirm_password} />
                      {/* <input  className="form-control" name="confirm_password" type="password" placeholder={this.props.t("register.confirm_password")} isInvalid={!!errors.confirm_password}  value={values.confirm_password} onChange={handleChange} /> */}
                      <Form.Control.Feedback
                                className=""
                                
                                type="invalid"
                              >
                                { this.props.t(errors.confirm_password)}
                              </Form.Control.Feedback>
                    {/* </div> */}
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="rts-btn btn-cus ">{this.props.t("register.signup")}</button>
                </div>
                </Form>
                        )}
                     </Formik>
                      <Link to="/login">
                            <p className="pre-title text-white text-center"> Already Acoount? Log IN</p>
                        </Link>
                     </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Login form fluid end */}
        {/* start loader */}
        <div className="loader-wrapper">
          <div className="loader">
          </div>
          <div className="loader-section section-left" />
          <div className="loader-section section-right" />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {

  // console.log(state);
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default compose(withTranslation(), connect(mapStateToProps))(Register);
