import React, { Component } from "react";
import { Link } from "react-router-dom";
//import * as yup from "yup";
//import Message from "./common/message";
import ReactHtmlParser from "react-html-parser";

import { withTranslation } from "react-i18next";
import { getPageDetail } from "../../../actions/page-detail"; 
import { connect } from "react-redux";
import { compose } from "redux";

//import { login } from "../../../actions/auth";

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      ActiveIndex: 0,
      lan:localStorage.getItem('lan')
    };

    this.handleActive = this.handleActive.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.getPageDetail();
  }

  handleActive(e){

    let Active = e.currentTarget.getAttribute('data-id');
    if(Active == this.state.ActiveIndex){
      this.setState({ 
        ActiveIndex: -1
      });
    }else{
      this.setState({ 
        ActiveIndex: Active
      });
    }

  }

  async componentDidUpdate(){
   
    if(this.props.i18n.language !== this.state.lan){
      await this.getPageDetail();
    }
  }

  async getPageDetail(){

    var pathname = window.location.pathname
    await this.props.getPageDetail(pathname);

    this.setState({
        data:this.props.page_detail,
        lan:this.props.i18n.language,
    });
   

  }
  render() {
    //const { message } = this.props;
    if(this.state.data !== undefined){
        console.log(this.props.page_detail, "cccccccccccccccccccccccccccc");
    return (
      <>
        <div className="sb-app">
            <div id="sb-dynamic-content" className="sb-transition-fade">
                <section className="sb-banner sb-banner-sm sb-banner-color">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="sb-main-title-frame">
                        <div className="sb-main-title text-center">
                            <img src="img/terms.png" className="img-fluid" width={350} />
                            <p className="sb-text sb-text-lg sb-mb-30 mt-4">{ this.state.data.displayTitle }</p>
                            { ReactHtmlParser(this.state.data.displayDescription)}
                            <ul className="sb-breadcrumbs">
                            <li><Link to="/">{this.props.t("aboutUs.home")}</Link></li>
                            <li><Link to="/terms">{this.props.t("terms.terms")}</Link></li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>
                <div className="container bootstrap snippets bootdey">
                <div className="div row py-5">
                    <div className="col-sm-10 offset-md-1">
                    <div className="panel-group drop-accordion" id="accordion" role="tablist" aria-multiselectable="true">

                    { this.state.data.page_items !== undefined ?
                             this.state.data.page_items.map((p, index) => {
                            return (
                                <div className="panel panel-default" key={index}>
                                    <div className={"panel-heading " + (this.state.ActiveIndex == index ? "tab-collapsed" : '') + ""} role="tab" id={"heading" + index }>
                                        <h4 className="panel-title">
                                        <a className={"collapse-controle " + (this.state.ActiveIndex !== index ? "collapsed" : '')} data-id={index} onClick={this.handleActive} data-toggle="collapse" data-parent="#accordion" href={"#collapse" + index } aria-expanded={this.state.ActiveIndex == index ? "true" : "false"} aria-controls={"collapse" + index }>
                                            {p.displayTitle}
                                            <span className="expand-icon-wrap"><i className="fa expand-icon" /></span>
                                        </a>
                                        </h4>
                                    </div>
                                    <div id={"collapse" + index } className={"panel-collapse collapse in " + (this.state.ActiveIndex == index ? 'show' : '')} role="tabpanel" aria-labelledby={"heading" + index } aria-expanded={this.state.ActiveIndex == index ? "true" : "false"}>
                                        <div className="panel-body p-3">
                                        { ReactHtmlParser(p.displayDescription) }
                                        </div>
                                    </div>
                                </div>
                                  )
                                })
                                 :""
                            }
                     
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
      </>
    );
    }
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  
  let page_detail = state.page_detail
  if(state.page_detail.length > 0){
    page_detail = state.page_detail[1];
  }
  return {
    isLoggedIn,
    message,
    page_detail
  };
}

export default compose(withTranslation(), connect(mapStateToProps,
    {
        getPageDetail
    }))(Terms);
