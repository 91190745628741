import {
  GET_CMS_DATA,
  } from "../actions/types";
      
  const initialState = [];
  
  function pageDetailReducer(page_detail = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_CMS_DATA:
        return [page_detail, payload];
  
    
  
      default:
        return page_detail;
    }
  }
  
  export default pageDetailReducer;