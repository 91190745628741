import React, { Component } from "react";
import { Redirect } from "react-router-dom";
//import { Formik } from "formik";
import * as yup from "yup";
import { getCityHomeData } from "../actions/city";
import { getSearchedCityData } from "../actions/city_search";
import { getCityData } from "../actions/restaurant-search";
//import Message from "./common/message";
//import { Row, Form, FormGroup } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { login } from "../actions/auth";
import debounce from 'lodash.debounce';

// const required = (value) => {
//   if (!value) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This field is required!
//       </div>
//     );
//   }
// };

class Home extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.getCitiesMalaga = this.getCitiesMalaga.bind(this);
    this.getCitiesCadiz = this.getCitiesCadiz.bind(this);
    this.getSmallCities = this.getSmallCities.bind(this);
    this.handleSearchedCity = this.handleSearchedCity.bind(this); 
    this.onChangeDebounced = debounce(this.onChangeDebounced, 2000)
    this.timer = null;
    this.state = {
      email: "",
      password: "",
      loading: false,
      cities:[],
      allCity:[],
      searchValue: "",
      serchedCities:[],
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

      await this.getCities();
    await this.getSerchedCity();

  }

  schema = yup.object().shape({
    email: yup
      .string()
      .email("user.addForm.validation.validemail")
      .required("user.addForm.validation.email"),
    password: yup.string().required("user.addForm.validation.password"),
  });

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  async getCities(){

     await this.props.getCityHomeData();

    // console.log( this.props,"ddddddddddddd");
     this.setState({
      cities: this.props.cities,
    });
    
  }

  async getSerchedCity(){
    let data = {
      type: 'cities_of_merchants',
    }

    await this.props.getCityData(data);


    this.setState({
      allCity: this.props.restaurantsearch.cities,
    });


  }

  async handleChange(e){
    // this is the search text
  
      this.setState({
        searchValue: e.target.value,
      });

      this.onChangeDebounced(e)
  
  }

  async onChangeDebounced(e){
    await this.props.getSearchedCityData(this.state.searchValue);

    this.setState({
      serchedCities: this.props.citiesAuto,
    });
  }

  async handleSearchedCity(e){
    let city = e.target.getAttribute('data-id');

    let cities = this.state.allCity.filter((ct, index) => {
      if(ct.name == city){
        return ct;
      }
    });
  console.log(cities);

  localStorage.setItem('city_id', cities[0].encrypted_id);
  localStorage.setItem('subregion_id', cities[0].encrypted_subregion_id);
  localStorage.setItem('region_id', cities[0].encrypted_region_id);
  localStorage.setItem('resturant_type_id', "cmc9PQ");
  // const newList = this.state.defaultCities.filter((i, itemIndex) => index !== itemIndex) 
  const { history } = this.props;
  // console.log(e.target);


  history.push("/search-listing");
  }


  async getCitiesMalaga(e){
 
    let cityID = e.target.getAttribute('data-id');
   
    let cities = this.state.cities.citiesMalaga.filter((ct, index) => {
        if(ct.encrypted_id == cityID){
          return ct;
        }


    });

    console.log(cities);
    localStorage.setItem('resturant_type_id', "cmc9PQ");
    localStorage.setItem('city_id', cityID);
    localStorage.setItem('subregion_id', cities[0].encrypted_subregion_id);
    localStorage.setItem('region_id', cities[0].encrypted_region_id);
    // const newList = this.state.defaultCities.filter((i, itemIndex) => index !== itemIndex) 
    const { history } = this.props;
    // console.log(e.target);
 

    history.push("/search-listing");
 }

 async getCitiesCadiz(e){
 
  let cityID = e.target.getAttribute('data-id');
 
  let cities = this.state.cities.citiesCadiz.filter((ct, index) => {
      if(ct.encrypted_id == cityID){
        return ct;
      }


  });

  console.log(cities);
  localStorage.setItem('resturant_type_id', "cmc9PQ");
  localStorage.setItem('city_id', cityID);
  localStorage.setItem('subregion_id', cities[0].encrypted_subregion_id);
  localStorage.setItem('region_id', cities[0].encrypted_region_id);
  // const newList = this.state.defaultCities.filter((i, itemIndex) => index !== itemIndex) 
  const { history } = this.props;
  // console.log(e.target);


  history.push("/search-listing");
}


async getSmallCities(e){
 
  let cityID = e.target.getAttribute('data-id');
 
  let cities = this.state.cities.smallCities.filter((ct, index) => {
      if(ct.encrypted_id == cityID){
        return ct;
      }


  });

  localStorage.setItem('resturant_type_id', "cmc9PQ");
  localStorage.setItem('city_id', cityID);
  localStorage.setItem('subregion_id', cities[0].encrypted_subregion_id);
  localStorage.setItem('region_id', cities[0].encrypted_region_id);
    
  // const newList = this.state.defaultCities.filter((i, itemIndex) => index !== itemIndex) 
  const { history } = this.props;
  // console.log(e.target);


  history.push("/search-listing");
}

  handleClick(e){

     let resturant_type_id = e.target.getAttribute('data-id');
     localStorage.setItem('resturant_type_id', resturant_type_id);
      this.props.history.push('/search');
  }
  async handleLogin(values) {
    // e.preventDefault();

    this.setState({
      loading: true,
    });

    // this.form.validateAll();

    const { dispatch, history } = this.props;

    // if (this.checkBtn.context._errors.length === 0) {
    dispatch(login(values.email, values.password))
      .then(() => {
        history.push("/reservations");
        // window.location = "/profile";
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
    // } else {
    //   this.setState({
    //     loading: false,
    //   });
    // }
  }

  render() {
    //const { isLoggedIn, message } = this.props;
    const { isLoggedIn } = this.props;

    
    console.log(this.state);
    // if (isLoggedIn) {
    //   return <Redirect to="/dashboard" />;
    // }

    return (

     <div>
      <div id="anywhere-home" />
      {/* banner area start three */}
      <div className="rts-banner-area  custom-slider-banner">
        <div className="mt--20" id="banner">
          <div className="container">
            <div className="row banner-three banner-four">
              <div className="col-12">
                <div className="banner-three-inner banner-four-inner slider-custom-btn">
                  {/* type headline start*/}
                  <h1 className="title cd-headline clip is-full-width">
                    Connect, Discover, and Explore
                  </h1>
                  <h1 className="title-small-contain">
                    Unleash your Spiritual Journey
                  </h1>
                  <div className="clearfix" />
                  <p className="disc">
                    Dive into our chatroom: a safe, open gateway for connecting<br /> with spiritual souls in a peaceful community.
                  </p>
                  <div className="custom-btn button-group mb--30">
                    <div className="col-4 btn-group">
                      <button className="rts-btn btn-cus color-h-black" type="submit">Discover More About Our Chatroom</button>
                    </div>
                  </div>
                  <p className="slider-p-tag-title">
                    Harmonious Community: 
                  </p>
                  <p className="slider-p-tag-title-text">
                    Connect in our nurturing chatroom, dedicated solely to<br /> 
                    exploring spirituality and healing through a diverse <br />
                    range of topics.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* banner area end three */}
      {/* business goal area */}
      <div className="rts-business-goal rts-business-goal2 mt--0 rts-section-gapBottom introduction-section " id="about-us">
        <div className="container">
          <div className="row">
            {/* business goal left */}
            <div className="col-lg-5">
              <div className="business-goal-one">
                <img src="assets/images/introduction.png" alt="Introduction" />
              </div>
            </div>
            {/* business goal right */}
            {/* right area business */}
            <div className="col-lg-7 d-flex align-items-center">
              <div className="business-goal-right">
                <div className="rts-title-area business text-start pl--50">
                  <h2 className="title">Introduction</h2>
                </div>
                <div className="rts-business-goal pl--50 mt-0">
                  <div className="single-goal">
                    <div className="goal-wrapper">
                      <p className="disc">"Welcome to our unique chatroom, a space where you can 'Connect, Discover, and Explore: Unleash your spiritual journey'. We're creating a harmonious community dedicated to exploring spirituality and healing through a diverse range of topics. Here, you'll find a nurturing environment where you can connect with like-minded individuals, delve into deep discussions, and grow on your spiritual path. Our chatroom is more than just a platform; it's a gateway to a peaceful community where every voice is heard, every experience is valued, and every journey is respected."</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* right area business ENd */}
          </div>
        </div>
      </div>
      {/* business goal area End */}
      <div className="working-process-area">
        <div className="container story-bg">
          <div className="row align-items-center">
            <div className="title-area text-center working-process mt-0">
              <h3 className="title mb-5 text-white font-weight-600">Our Story</h3>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              {/* single wirking process */}
              <div className="rts-business-goal pl--30 mt-0">
                <div className="single-goal">
                  <div className="goal-wrapper">
                    <p className="disc text-center text-white">"Our chatroom was conceived from a transformative experience my partner and I had at a healing retreat in Holland. After participating in two profound healing ceremonies, we returned home rejuvenated but with a sense of emptiness, a longing to continue discussing our experiences and connecting with others in a serene, friendly, yet serious environment. This yearning sparked an idea, a vision that came to me with majestic clarity. I knew in that moment that we were destined to create this unique space - a self-contained, dedicated environment for exploring spirituality and healing."</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* business goal area */}
      <div className="rts-business-goal rts-business-goal2 mt--0 rts-section-gapBottom introduction-section " id="about-us">
        <div className="container">
          <div className="row">
            {/* business goal left */}
            <div className="col-lg-5">
              <div className="business-goal-one">
                <img src="assets/images/our-mission.png" alt="Introduction" />
              </div>
            </div>
            {/* business goal right */}
            {/* right area business */}
            <div className="col-lg-7 d-flex align-items-center">
              <div className="business-goal-right">
                <div className="rts-title-area business text-start pl--50">
                  <h2 className="title">Our Mission</h2>
                </div>
                <div className="rts-business-goal pl--50 mt-0">
                  <div className="single-goal">
                    <div className="goal-wrapper">
                      <p className="disc">"Welcome to our unique chatroom, a space where you can 'Connect, Discover, and Explore: Unleash your spiritual journey'. We're creating a harmonious community dedicated to exploring spirituality and healing through a diverse range of topics. Here, you'll find a nurturing environment where you can connect with like-minded individuals, delve into deep discussions, and grow on your spiritual path. Our chatroom is more than just a platform; it's a gateway to a peaceful community where every voice is heard, every experience is valued, and every journey is respected."</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* right area business ENd */}
          </div>
        </div>
      </div>
      {/* business goal area End */}
      {/* business goal area */}
      <div className="rts-business-goal mt--0 rts-section-gapBottom introduction-section " id="about-us">
        <div className="container">
          <div className="row">
            {/* right area business */}
            <div className="col-lg-7 d-flex align-items-center order-sm-last">
              <div className="business-goal-right">
                <div className="rts-title-area business text-start">
                  <h2 className="title">Our Community</h2>
                </div>
                <div className="rts-business-goal mt-0">
                  <div className="single-goal">
                    <div className="goal-wrapper">
                      <p className="disc">Our community is built on a foundation of trust, welcoming thinkers from all walks of life. From inquisitive minds embarking on their spiritual journey, to seasoned soul searchers and thought leaders sharing their wisdom. We invite caring hearts of all ages, from young minds exploring their spirituality, to wise wanderers eager to pass on their knowledge and experiences. Our chatroom is a melting pot of perspectives, a place where every question is valued and every journey is respected.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* right area business ENd */}
            {/* business goal left */}
            <div className="col-lg-5">
              <div className="business-goal-one pl--50">
                <img src="assets/images/our-community.png" alt="Introduction" />
              </div>
            </div>
            {/* business goal right */}
          </div>
        </div>
      </div>
      {/* business goal area End */}
      {/* business goal area */}
      <div className="rts-business-goal mt--0 introduction-section " id="about-us">
        <div className="container">
          <div className="row">
            {/* business goal left */}
            <div className="col-lg-5">
              <div className="business-goal-one">
                <img src="assets/images/subscription-model.png" alt="Introduction" />
              </div>
            </div>
            {/* business goal right */}
            {/* right area business */}
            <div className="col-lg-7 d-flex align-items-center">
              <div className="business-goal-right">
                <div className="rts-title-area business text-start pl--50">
                  <h2 className="title">Subscription Model</h2>
                </div>
                <div className="rts-business-goal pl--50 mt-0">
                  <div className="single-goal">
                    <div className="goal-wrapper">
                      <p className="disc">At Kardarma Chat, we believe in providing a clean, safe, and always available environment for our community.  To help us maintain this standard, we operate on a modest subscription model. For details about our fees, please scroll down to the 'Pricing Section'.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* right area business ENd */}
          </div>
        </div>
      </div>
      {/* business goal area End */}
      {/* start trusted client section */}
      <div className="rts-trusted-client rts-trusted-client2 rts-section-gapBottom custom-btn">
        <div className="container">
          <div className="row">
            <div className="col-10 offset-md-1 mt--80 btn-group">
              <button className="mx-3 rts-btn btn-cus color-h-black" type="submit">Start Your Journey</button>
              <button className="mx-3 rts-btn btn-cus color-h-black" type="submit">Discover More About Our Chatroom</button>
            </div>
          </div>
        </div>
      </div>
      {/* end trusted client section */}
      {/* our working Process */}
      <div className="working-process-area rts-section-gap features-section-p-tag " style={{background: '#f7f7fb'}} id="features">
        <div className="container">
          <div className="row mb--40">
            <div className="title-area text-center working-process">
              <h2 className="title">Features</h2>
            </div>
          </div>
          <div className="row g-5 align-items-center">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              {/* single wirking process */}
              <div className="rts-working-process-1 text-center">
                <div className="icon">
                  <img src="assets/images/private-messaging.png" alt="Working_process" />
                </div>
                <div className="content">
                  <h6 className="title">Private Messaging</h6>
                  <p className="disc">
                    Engage in one-on-one conversations in a<br /> secure environment, ensuring your discussions<br /> remain private and safe.
                  </p>
                </div>
              </div>
              {/* single wirking process End */}
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              {/* single wirking process */}
              <div className="rts-working-process-1 process-lg text-center">
                <div className="icon">
                  <img src="assets/images/forever-friends.png" alt="Working_process" />
                </div>
                <div className="content">
                  <h6 className="title">Forever Friends</h6>
                  <p className="disc">
                    Connect on a deeper level with kindred souls.<br /> Send and accept friend requests to build<br /> lasting friendships!
                  </p>
                </div>
              </div>
              {/* single wirking process End */}
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              {/* single wirking process */}
              <div className="rts-working-process-1 text-center">
                <div className="icon">
                  <img src="assets/images/find-people-nearby.png" alt="Working_process" />
                </div>
                <div className="content">
                  <h6 className="title">Find People Nearby</h6>
                  <p className="disc">
                    Discover and connect with like-minded<br /> individuals in your area through our chat<br /> platform. Your next spiritual friend could be just<br /> around the corner!
                  </p>
                </div>
              </div>
              {/* single wirking process End */}
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              {/* single wirking process */}
              <div className="rts-working-process-1 process-lg text-center">
                <div className="icon">
                  <img src="assets/images/send-stickers.png" alt="Working_process" />
                </div>
                <div className="content">
                  <h6 className="title">Send Stickers</h6>
                  <p className="disc">
                    Add a dash of fun to your conversations! Send<br /> stickers to show support, express emotions, or<br /> simply lighten the mood
                  </p>
                </div>
              </div>
              {/* single wirking process End */}
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              {/* single wirking process */}
              <div className="rts-working-process-1 process-lg text-center">
                <div className="icon">
                  <img src="assets/images/voice-messaging.png" alt="Working_process" />
                </div>
                <div className="content">
                  <h6 className="title">Voice Messaging</h6>
                  <p className="disc">
                    Add a personal touch to your conversations.<br /> When you're tired of typing, switch to voice<br /> messages to express yourself more<br /> authentically!
                  </p>
                </div>
              </div>
              {/* single wirking process End */}
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              {/* single wirking process */}
              <div className="rts-working-process-1 process-lg text-center">
                <div className="icon">
                  <img src="assets/images/groups-rooms.png" alt="Working_process" />
                </div>
                <div className="content">
                  <h6 className="title">Groups/Rooms</h6>
                  <p className="disc">
                    Dive into diverse discussions in our dedicated groups.<br /> Whether your interest lies in Yoga, Meditation, Reiki,<br /> healing through psychedelics, or more, you'll find a<br /> space to share your thoughts and ideas.
                  </p>
                </div>
              </div>
              {/* single wirking process End */}
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              {/* single wirking process */}
              <div className="rts-working-process-1 process-lg text-center">
                <div className="icon">
                  <img src="assets/images/user-mentions.png" alt="Working_process" />
                </div>
                <div className="content">
                  <h6 className="title">User Mentions</h6>
                  <p className="disc">
                    Engage directly with others by using the<br /> @name mention feature. It's a simple way to let<br /> someone know you're speaking specifically to<br /> them!
                  </p>
                </div>
              </div>
              {/* single wirking process End */}
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              {/* single wirking process */}
              <div className="rts-working-process-1 process-lg text-center">
                <div className="icon">
                  <img src="assets/images/profanity-filter.png" alt="Working_process" />
                </div>
                <div className="content">
                  <h6 className="title">Profanity Filter</h6>
                  <p className="disc">
                    We're committed to maintaining a respectful<br /> atmosphere. Our chat features a profanity filter<br /> that modifies texts to remove offensive words,<br /> ensuring a safe and friendly environment for all.
                  </p>
                </div>
              </div>
              {/* single wirking process End */}
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              {/* single wirking process */}
              <div className="rts-working-process-1 process-lg text-center">
                <div className="icon">
                  <img src="assets/images/complaints-spam-reporting.png" alt="Working_process" />
                </div>
                <div className="content">
                  <h6 className="title">Complaints / Spam reporting</h6>
                  <p className="disc">
                    Your comfort is our priority. If you ever feel<br /> harassed or unjustly abused, report the issue.<br /> Our dedicated Kardarma moderators will<br /> promptly address the situation.
                  </p>
                </div>
              </div>
              {/* single wirking process End */}
            </div>
            <div className="col-xl-4 offset-md-4 col-lg-4 col-md-6 col-sm-6 col-12">
              {/* single wirking process */}
              <div className="rts-working-process-1 process-lg text-center">
                <div className="icon">
                  <img src="assets/images/many-more-features.png" alt="Working_process" />
                </div>
                <div className="content">
                  <h6 className="title">Many More Features</h6>
                  <p className="disc">
                    Our chatroom is packed with additional features to<br /> enhance your experience. Enjoy emoji pickers, personalized<br /> avatars, quoted replies, the ability to request a new group,<br /> customize your own background image, and much more!
                  </p>
                </div>
              </div>
              {/* single wirking process End */}
            </div>
          </div>
          <div className="custom-btn">
            <div className="row">
              <div className="col-10 offset-md-1 mt--80 btn-group">
                <button className="mx-2 mx-md-3 rts-btn btn-cus color-h-black" type="submit">Start Your Journey</button>
                <button className="mx-3 rts-btn btn-cus color-h-black" type="submit">Discover More About Our Chatroom</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* our working Process End */}
      <div className="rts-pricing-plane rts-section-gap margin-dec-padding-con pricing-section" id="pricing">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="pricing-tab-button-area title-area pricing-h2">
                <h2 className="title">Pricing</h2>
                <p className="offer text-left">Quality Worth More Than a Couple of Coffees! </p>
                <p className="text-left mb-2">To ensure Kardarma Chat remains a clean, safe, and always available environment, we operate on a small subscription model. This modest fee not only helps us maintain the quality of our platform but also significantly reduces the presence of bots and trolls. We've found that bad actors are less likely to pay to disrupt our harmonious community.</p>
                <p className="mb-2 text-left">
                  As a token of our appreciation, loyal subscribers will also receive discounts on future products that we're excited to introduce in the coming months.  Check out our simple pricing structure below:"
                </p>
              </div>
            </div>
          </div>
          <div className="row mt--50">
            <div className="col-12">
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div className="row g-5">
                    {/* single pricing plane */}
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 d-flex align-items-stretch">
                      <div className="pricing-wrapper-one home-two">
                        {/* pricing header */}
                        <div className="pricing-header-start main-round-title d-flex align-items-center justify-content-center">
                          <h5 className="title">
                            QUARTERLY
                          </h5>
                        </div>
                        {/* pricing header End */}
                        {/* pricing body start */}
                        <div className="pricing-body">
                          {/* single pricing */}
                          <div className="single-pricing">
                            <span className="price-details">Pay every 3 months</span>
                          </div>
                          <h5 className="title">
                            €5,000
                          </h5>
                          {/* single pricing End */}
                        </div>
                        {/* pricing body end */}
                      </div>
                    </div>
                    {/* single pricing plane */}
                    {/* single pricing plane */}
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 d-flex align-items-stretch">
                      <div className="pricing-wrapper-one home-two">
                        {/* pricing header */}
                        <div className="pricing-header-start main-round-title">
                          <span>Most Populer</span>
                          <h5 className="title">
                            Anual
                          </h5>
                        </div>
                        {/* pricing header End */}
                        {/* pricing body start */}
                        <div className="pricing-body">
                          {/* single pricing */}
                          <div className="single-pricing available">
                            <span className="price-details">12 months</span>
                          </div>
                          <h5 className="title">
                            €5,00
                          </h5>
                          <div className="single-pricing available">
                            <span className="price-details">Save € 50</span>
                          </div>
                          {/* single pricing End */}
                        </div>
                        {/* pricing body end */}
                      </div>
                    </div>
                    {/* single pricing plane */}
                    {/* single pricing plane */}
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 d-flex align-items-stretch">
                      <div className="pricing-wrapper-one home-two">
                        {/* pricing header */}
                        <div className="pricing-header-start main-round-title">
                          <span>Super Saver</span>
                          <h5 className="title">
                            3 YEARS
                          </h5>
                        </div>
                        {/* pricing header End */}
                        {/* pricing body start */}
                        <div className="pricing-body">
                          {/* single pricing */}
                          <div className="single-pricing available">
                            <span className="price-details">36 months</span>
                          </div>
                          <h5 className="title">
                            €5,00
                          </h5>
                          <div className="single-pricing available">
                            <span className="price-details">Save € 50</span>
                          </div>
                          {/* single pricing End */}
                        </div>
                        {/* pricing body end */}
                      </div>
                    </div>
                    {/* single pricing plane */}
                  </div>
                  <div className="row custom-btn d-flex justify-content-center">
                    <div className="col-6  mt--80 btn-group">
                      <button className="mx-3 rts-btn btn-cus color-h-black" type="submit">Start Your Journey</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rts-faq-section pt-0 rts-section-gap features-section-p-tag custom-faq-sec" id="faq">
        <div className="container">
          <div className="row">
            <div className="title-area text-center working-process">
              <h2 className="title">FAQ</h2>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="faq-two-inner">
                {/* faq accordion area */}
                <div className="faq-accordion-area">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          Why do you charge for a chatroom when other chatrooms are free?
                        </button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{}}>
                        <div className="accordion-body">
                          Our chatroom is specifically dedicated to Spirituality and Healing. We strive to maintain and monitor it to the highest standards. While our fees are modest, they help us ensure a safe and engaging environment for meaningful discourse.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          Can I cancel my subscription and will I be refunded?
                        </button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style={{}}>
                        <div className="accordion-body">
                          While you can cancel your subscription at any time, we unfortunately do not offer refunds. Due to the minimal nature of our fees, the cost of transfer charges for refunds would exceed the subscription fee itself. If you're unsure about committing to the chatroom, we recommend starting with our quarterly subscription model. You can cancel at any point, and no further charges will be applied.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          What should I do if I can't find a Public Group in chat that I'm interested in?
                        </button>
                      </h2>
                      <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          First, ensure you've thoroughly searched for the group. If you still can't find it, head over to your Kardarma dashboard and send a request to our support team. We'll be happy to create the group for you. Please note that you can request the creation of one group per year.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                          Can I create a private Group?
                        </button>
                      </h2>
                      <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          Yes, you can create a private Group. We allow two group creations per year, though there will be a limit on the number of participants. If you need assistance, don't hesitate to contact our support team. We're here to help!
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                          What can I do if I'm being harassed by another user?
                        </button>
                      </h2>
                      <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          We're committed to maintaining a respectful community. If you're experiencing harassment, we first encourage you to politely ask the user to stop. If the behaviour persists, please use the report option in the settings. Our team at Kardarma will investigate and take appropriate action.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingsix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                          What action is taken against repeat offenders of harassment or abusive language?
                        </button>
                      </h2>
                      <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          We operate on a three-strike policy. Users who repeatedly misuse the chatroom will exhaust their three lifelines. After this, they will face a ban for a minimum of one year. In extreme cases, we may impose a lifetime ban to ensure the safety and comfort of our community.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingseven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                          I noticed some inappropriate language, even though you have a Profanity Filter. Why is that?
                        </button>
                      </h2>
                      <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          Our Profanity Filter is populated by Kardarma administrators and while we strive to keep it comprehensive, we might miss some terms. If you notice inappropriate language, please let us know and we'll update the filter. However, please note that we focus on filtering out swear words and not on policing all language.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* faq accordion area end */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* our working Process End */}
    </div>

    );
  }
}

function mapStateToProps(state) {

  console.log(state, "hhhhhhhh");
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  let { cities } = state.cities;
  let { citiesAuto } = state.citiesAuto;
  if(state.cities){
    cities = state.cities[0];
  }
  let { restaurantsearch } = state.restaurantsearch;
    if(state.restaurantsearch){
    restaurantsearch = state.restaurantsearch[1];
    }
  if(state.citiesAuto){
    citiesAuto = state.citiesAuto[0];
  }
  console.log(cities, "hhhhhhhhzzz");
  return {
    
    isLoggedIn,
    message,
    cities: cities,
    citiesAuto:citiesAuto,
    restaurantsearch: restaurantsearch
  };
 
}

export default compose(withTranslation(), connect(mapStateToProps, {
  getCityData,
  getCityHomeData,
  getSearchedCityData,
}))(Home);
