  import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
//import { Redirect } from "react-router-dom";
//import * as yup from "yup";
//import Message from "./common/message";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
//import { login } from "../../../actions/auth";
import configData from "../../../config.json";
const apiEndpoint = configData.apiUrl;

class BlogDetail extends Component {
  constructor() {
    super();
    this.state = {
        blogData: [],
        latestBlogs: [],
    };
  }
  getBlogData(blogSlug) { 
    let selectedLanguage = "en";
    if(localStorage.getItem("lan")){
      selectedLanguage = localStorage.getItem("lan");
    }
    else{
      selectedLanguage = "en";
    }
    const config = {
      headers: {
          'Accept-Language': selectedLanguage,
      }
    };
    axios.get(apiEndpoint + `/home/blog/${blogSlug}`, config).then((response) => {
      this.setState({
        blogData: {...response.data.data.blogData},
        latestBlogs: [...response.data.data.latestBlogs],
      });
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    let blogSlug = this.props.match.params.slug;
    this.getBlogData(blogSlug);
  }
  handleClickReadMore = (blogSlug) => {
    this.getBlogData(blogSlug);
  };
  render() {
    //const { message } = this.props;
    return (
      <>
        <div className="sb-app">
            <div id="sb-dynamic-content" className="sb-transition-fade">
                <section className="sb-banner sb-banner-xs sb-banner-color pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="sb-main-title-frame pt-5 pb-0">
                                    <div className="sb-main-title">
                                        <h1 className="sb-h2 my-0">Zampoita <span className="color">Blog Details</span></h1>
                                        <ul className="sb-breadcrumbs">
                                            <li><Link to={`/`}>Home</Link></li>
                                            <li><Link to={`/blog`}>Blog</Link></li>
                                            <li><a href="#.">Blog Details</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{backgroundImage: `url(${this.state.blogData.imageUrl})`}}>
                    <div className="container">
                        <div className="row">
                            <h2 className="sb-cate-title py-5 text-white sb-mb-15 mt-0 text-center">{this.state.blogData.displayBlogName}</h2>
                        </div>
                        <p className="text-center mb-0"><span className="color">{this.state.blogData.createdDateFormatted}</span></p>
                    </div>
                </section>
                <section className="sb-blog-list sb-p-90-90 pt-0 mt-4">
                    <div className="container">
                        <div className="sb-mb-60">
                            {ReactHtmlParser(this.state.blogData.displayDescription)}
                            <div className="mt-3">
                            <Link to={`/blog`}><i className="fa fa-chevron-right color" aria-hidden="true"></i><i className="fa fa-chevron-right color" aria-hidden="true"></i><span className="color">Back To Blog List</span></Link>
                            </div>
                        </div>
                        <div className="sb-masonry-grid">
                            <div className="sb-grid-sizer"></div>
                            {this.state.latestBlogs.length > 0 ? this.state.latestBlogs.map((value, index, array) => {
                              return (
                                  <div className="sb-grid-item sb-item-33" key={value.encrypted_id}>
                                      <a href="#" className="sb-blog-card">
                                      <div className="sb-cover-frame sb-mb-30">
                                          <img src={value.imageUrl} alt="cover" />
                                      </div>
                                      <div className="sb-blog-card-descr">
                                          <h4 className="sb-mb-10">{value.displayBlogName}</h4>
                                          <div className="sb-suptitle sb-mb-15"><span>{value.createdDateFormatted}</span></div>
                                          <p className="sb-text">{value.displayShortDescription}</p>
                                          <Link className="btn btn-bg-custom my-3" to={`/blog/${value.blog_slug}`} 
                                          onClick={() => { this.handleClickReadMore(value.blog_slug); }}>Read More</Link>
                                      </div>
                                      </a>
                                  </div>
                              )
                          }) : ''
                          }
                        </div>
                    </div>
                </section>
            </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default compose(withTranslation(), connect(mapStateToProps))(BlogDetail);
