import axios from "axios";
import authHeader from "./auth-header";
import configData from "../config.json";
//const API_URL = "http://localhost:8080/api/test/";
const apiEndpoint = configData.apiUrl + "/restaurant";

class RestaurantSearchService {
  

  getData(data) {
    return axios.post(apiEndpoint + `/search/parameters/bypostcode`,data,);
    //return axios.get(apiEndpoint, { headers: authHeader() } );
  }

 

}

export default new RestaurantSearchService();
