import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import * as yup from "yup";
import { getRestaurantDetailByslug } from "../../../actions/restaurants";
import { withTranslation } from "react-i18next";
import Loading from "../../common/loading";
import { connect } from "react-redux";
import { compose } from "redux";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

 
class Individual2 extends Component {
  constructor(props) {
    super(props);
    

    this.state = {
        activeIndex: 1,
        email: "",
        password: "",
        loading: false,
        animating: false,
        resturants: [],
    };


  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.getRestaurantByDetail();
  }

   
  async getRestaurantByDetail()
  {
    let slug = this.props.match.params.slug;
    await this.props.getRestaurantDetailByslug(slug);

    console.log(this.props, "kkkkkkkk");
    this.setState({
        resturants:this.props.restaurants.merchant
    });
  } 

  render() {
    //const { isLoggedIn, message } = this.props;
    const { isLoggedIn } = this.props;

    if(this.state.resturants.length !== 0){
        return (
                <div>
                    <section id="main-wrapper" className="padall0 image-to-background" data-overlay-black={5}>
                        <img src={this.state.resturants.merchant_image.cover_photo_url} onError={(e)=>{e.target.src= this.state.resturants.default_cover_photos[0]; }} alt="Hero Slider Background" />
                    
                        <div id="hero-wrapper">
                        <OwlCarousel className="hero-slider owl-theme" loop autoplay={true} nav={false} dots={false} items={1}>
                    
                            <div className="container">
                                <div className="left-hero-text">
                                { 
                                    this.state.resturants.merchant_image.logo_shape == 2 ? 
                                    <div className="d-flex justify-content-left">
                                        <div className="individual-slider-logo-img" >
                                            <img src={this.state.resturants.merchant_image.logo_url} className="img-fluid mw-150" />
                                        </div>
                                    </div>
                                    : this.state.resturants.merchant_image.logo_shape == 1 ?
                                        <div className="d-flex justify-content-left">
                                            <img src={this.state.resturants.merchant_image.logo_url} className="img-fluid mw-150" />
                                        </div>
                                    : this.state.resturants.merchant_image.logo_shape == 3 ?
                                        <div className="d-flex justify-content-left rectangle">  
                                            <img src={this.state.resturants.merchant_image.logo_url} className="img-fluid mw-150" />
                                        </div>
                                    : ""
                                    }
                                    <h2 className="carousel-big">{this.state.resturants.merchant_restaurant.restaurant_name}</h2>
                                    <p className="text-white m-0">{this.props.t("restaurant_detail.rest_msg")}.</p>
                                    <div className="single-page-nav" id="testing">
                                    <a href="#" className="btn btn-bg-custom margintop30 btn-lg mr-1">{this.props.t("restaurant_detail.find_out_more")}</a>
                                    <a href="#" className="btn btn-bg-custom margintop30 btn-lg">{this.props.t("restaurant_detail.back_to_listing")}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="left-hero-text">
                                { 
                                    this.state.resturants.merchant_image.logo_shape == 2 ? 
                                    <div className="d-flex justify-content-left">
                                        <div className="individual-slider-logo-img" >
                                            <img src={this.state.resturants.merchant_image.logo_url} className="img-fluid mw-150" />
                                        </div>
                                    </div>
                                    : this.state.resturants.merchant_image.logo_shape == 1 ?
                                        <div className="d-flex justify-content-left">
                                            <img src={this.state.resturants.merchant_image.logo_url} className="img-fluid mw-150" />
                                        </div>
                                    : this.state.resturants.merchant_image.logo_shape == 3 ?
                                        <div className="d-flex justify-content-left rectangle">  
                                            <img src={this.state.resturants.merchant_image.logo_url} className="img-fluid mw-150" />
                                        </div>
                                    : ""
                                    }
                                    <h2 className="carousel-big">{this.state.resturants.merchant_restaurant.restaurant_name}</h2>
                                    <p className="text-white m-0">{this.props.t("restaurant_detail.rest_msg")}.</p>
                                    <div className="single-page-nav" id="testing">
                                    <a href="#" className="btn btn-bg-custom margintop30 btn-lg mr-1">{this.props.t("restaurant_detail.find_out_more")}</a>
                                    <a href="#" className="btn btn-bg-custom margintop30 btn-lg">{this.props.t("restaurant_detail.back_to_listing")}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="left-hero-text">
                                { 
                                    this.state.resturants.merchant_image.logo_shape == 2 ? 
                                    <div className="d-flex justify-content-left">
                                        <div className="individual-slider-logo-img" >
                                            <img src={this.state.resturants.merchant_image.logo_url} className="img-fluid mw-150" />
                                        </div>
                                    </div>
                                    : this.state.resturants.merchant_image.logo_shape == 1 ?
                                        <div className="d-flex justify-content-left">
                                            <img src={this.state.resturants.merchant_image.logo_url} className="img-fluid mw-150" />
                                        </div>
                                    : this.state.resturants.merchant_image.logo_shape == 3 ?
                                        <div className="d-flex justify-content-left rectangle">  
                                            <img src={this.state.resturants.merchant_image.logo_url} className="img-fluid mw-150" />
                                        </div>
                                    : ""
                                    }
                                    <h2 className="carousel-big">{this.state.resturants.merchant_restaurant.restaurant_name}</h2>
                                    <p className="text-white m-0">{this.props.t("restaurant_detail.rest_msg")}.</p>
                                    <div className="single-page-nav" id="testing">
                                    <a href="#" className="btn btn-bg-custom margintop30 btn-lg mr-1">{this.props.t("restaurant_detail.find_out_more")}</a>
                                    <a href="#" className="btn btn-bg-custom margintop30 btn-lg">{this.props.t("restaurant_detail.back_to_listing")}</a>
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>
                        </div>
                    </section>
            
                    { this.state.resturants.merchant_aboutus !== undefined ?
                    <section className="sb-video btn-bg-custom">
                        <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 align-self-center">
                            <div className="sb-illustration-7">
                                <div className="sb-interior-frame">
                                <img src={this.state.resturants.merchant_aboutus.image_url} alt="interior" className="sb-interior" />
                                </div>
                                <div className="sb-cirkle-1" />
                                <div className="sb-cirkle-2" />
                                <div className="sb-cirkle-3" />
                                <div className="sb-cirkle-4" />
                            </div>
                            </div>
                            <div className="col-lg-6">
                            <h1 className="sb-mb-30 mt-md-0 color-black">{this.props.t("restaurant_detail.about")}  <span className="color-black">{this.state.resturants.merchant_restaurant.restaurant_name}</span></h1>
                            <p className="sb-text sb-mb-10">
                            {this.state.resturants.merchant_aboutus.description}
                            </p>
                            {/* <p className="sb-text sb-mb-10">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                            </p>
                            <p className="sb-text sb-mb-10">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                            </p> */}
                            <a href={this.state.resturants.merchant_location.website_url} className="btn btn-lg bg-black-cus text-white">{this.props.t("restaurant_detail.visit_website")}</a>
                            </div>
                        </div>
                        </div>
                    </section>
                    : ''
                    }
                { this.state.resturants.merchant_foods.length > 0 ?
                <section id="portfolio" className="dark-wood white py-5 ">
                    <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-6 title header-line text-center">
                        <div>
                            <h2 className="color">{this.props.t("restaurant_detail.food_type")}</h2>
                        </div>
                        <p className="mt-3 ">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        </div>
                        <div className="col-md-6 text-center" id="food-type-top-section">
                       
                        <div className="row">
                        {this.state.resturants.merchant_foods.map((f, index) => {
                             return (
                            <div  key={index}  className="col-sm-6 col-xs-6 col-xs-6-cus">
                            <div className="food-type-img">
                                <img src={f.image_url} className="img-fluid rounded individual3" />
                                <h5 className="text-center font-weight-bold my-4">{f.displayName}</h5>
                            </div>
                            </div>
                             )
                            })
                        }
                            {/* <div className="col-sm-6 col-xs-6 col-xs-6-cus">
                            <div className="food-type-img">
                                <img src="https://media.istockphoto.com/photos/toast-with-avocado-and-egg-in-heart-shape-picture-id1046693924?b=1&k=20&m=1046693924&s=170667a&w=0&h=hntlfoxp2gK9BRGqgbLJOEQDw8Ae9aNroJKqE2DZ5OI=" className="img-fluid rounded individual3" />
                                <h5 className="text-center font-weight-bold my-4">Italian</h5>
                            </div>
                            </div>
                            <div className="col-sm-6 col-xs-6 col-xs-6-cus">
                            <div className="food-type-img">
                                <img src="https://media.istockphoto.com/photos/heart-shape-eggs-in-toast-picture-id495061495?k=20&m=495061495&s=170667a&w=0&h=QNFtN4e6wqGe3W2rVPjsTVd-fE63VIO_AM699TLrmZ0=" className="img-fluid rounded individual3" />
                                <h5 className="text-center font-weight-bold mt-4 mb-0">Vegetarian Options</h5>
                            </div>
                            </div>
                            <div className="col-sm-6 col-xs-6 col-xs-6-cus">
                            <div className="food-type-img">
                                <img src="https://images.unsplash.com/photo-1513442542250-854d436a73f2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwcm9maWxlLXBhZ2V8OHx8fGVufDB8fHx8&w=1000&q=80" className="img-fluid rounded individual3" />
                                <h5 className="text-center font-weight-bold mt-4 mb-0">Vegan Options</h5>
                            </div>
                            </div> */}
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
                :''
                }
                 {this.state.resturants.merchant_slider_images.length > 0 ?
                <section>
                    <div className="container">
                    <ul className="grid no-pad text">
                    { this.state.resturants.merchant_slider_images.map((slider, index) => {
                            return(
                                index == 0 ?
                                <li className="halfbox">
                                <a  href={slider.image_url}  className="image-to-background" data-imagelightbox="h" data-ilb2-caption="Food Image">
                                    <img src={slider.image_url} alt="Some desc" />
                                    <div className="overlay-wrapper" style={{background: 'rgba(0, 0, 0, 0)'}}>
                                    <div className="overlay-inner">
                                        <div className="center">
                                        <h3>{slider.title}</h3>
                                        <p>{slider.description}</p>
                                        </div>
                                    </div>
                                    </div>
                                </a>
                                </li>
                                :
                        <li className="quarterbox">
                        <a href={slider.image_url}  className="image-to-background" data-imagelightbox="h" data-ilb2-caption="Interior Image 1">
                            <img src={slider.image_url} alt="Some desc" />
                            <div className="overlay-wrapper" style={{background: 'rgba(0, 0, 0, 0)'}}>
                            <div className="overlay-inner">
                                <div className="center">
                                    <h3>{slider.title}</h3>
                                    <p>{slider.description}</p>
                                </div>
                            </div>
                            </div>
                        </a>
                        </li>
                          )
                        })
                        }
                        {/* <li className="quarterbox">
                        <a href="http://dev.magicmarinac.hr/Nivin/img/gallery/food/eg-1.jpg" className="image-to-background" data-imagelightbox="h" data-ilb2-caption="Event Image 1">
                            <img src="http://dev.magicmarinac.hr/Nivin/img/gallery/food/eg-1.jpg" alt="Some desc" />
                            <div className="overlay-wrapper" style={{background: 'rgba(0, 0, 0, 0)'}}>
                            <div className="overlay-inner">
                                <div className="center">
                                <h3>Event</h3>
                                <p>Image discription...</p>
                                </div>
                            </div>
                            </div>
                        </a>
                        </li>
                        <li className="quarterbox">
                        <a href="http://dev.magicmarinac.hr/Nivin/img/gallery/food/eg-2.jpg" className="image-to-background" data-imagelightbox="h" data-ilb2-caption="Event Image 2">
                            <img src="http://dev.magicmarinac.hr/Nivin/img/gallery/food/eg-2.jpg" alt="Some desc" />
                            <div className="overlay-wrapper" style={{background: 'rgba(0, 0, 0, 0)'}}>
                            <div className="overlay-inner">
                                <div className="center">
                                <h3>Event</h3>
                                <p>Image discription...</p>
                                </div>
                            </div>
                            </div>
                        </a>
                        </li>
                        <li className="quarterbox">
                        <a href="http://dev.magicmarinac.hr/Nivin/img/gallery/food/ig-2.jpg" className="image-to-background" data-imagelightbox="h" data-ilb2-caption="Interior Image 2">
                            <img src="http://dev.magicmarinac.hr/Nivin/img/gallery/food/ig-2.jpg" alt="Some desc" />
                            <div className="overlay-wrapper" style={{background: 'rgba(0, 0, 0, 0)'}}>
                            <div className="overlay-inner">
                                <div className="center">
                                <h3>Interior</h3>
                                <p>Image discription...</p>
                                </div>
                            </div>
                            </div>
                        </a>
                        </li>
                        <li className="halfbox">
                        <a href="http://dev.magicmarinac.hr/Nivin/img/gallery/food/dg-1.jpg" className="image-to-background" data-imagelightbox="h" data-ilb2-caption="Desert Image">
                            <img src="http://dev.magicmarinac.hr/Nivin/img/gallery/food/dg-1.jpg" alt="Some desc" />
                            <div className="overlay-wrapper" style={{background: 'rgba(0, 0, 0, 0)'}}>
                            <div className="overlay-inner">
                                <div className="center">
                                <h3>Desert</h3>
                                <p>Image discription...</p>
                                </div>
                            </div>
                            </div>
                        </a>
                        </li> */}
                    </ul>
                    </div>
                </section>
                : ''
                }
                <section id="info" className="bg-white">
                    <div className="container">
                    <div className="row">

                        <div className="col-md-4 pl-md-0">
                        {   this.state.resturants.merchant_restaurant_timing.length > 0 ?
                            <>
                            <h1 className="sb-mb-30 text-center color-black mt-0">{this.props.t("restaurant_detail.opening")} <span className="color">{this.props.t("restaurant_detail.hours")}</span></h1>
                            <div className="d-flex align-items-stretch">
                                <div className="bg-dark p-5 text-white">
                                <ul className="pb-4 text-center opening-hours-css">
                                { this.state.resturants.merchant_restaurant_timing.map((timing, index) => {
                            return(
                                    <li>
                                    { timing.day_of_week == "Sunday" ?
                                        <h2 className="deco-font color margintop10">{timing.day_of_week}</h2>
                                      : <h4 className="margintop10">{timing.day_of_week}</h4>
                                    }
                                    <p>{timing.start_end_times[0].start_time} - {timing.start_end_times[0].end_time}</p>
                                    { 
                                        timing.is_closed == 0 ?
                                        <p>{timing.start_end_times[1].start_time} - {timing.start_end_times[1].end_time}</p>
                                        :<p>CLOSED</p>
                                    }
                                    </li>
                                    )
                                })
                            }
                           
                                </ul>
                                </div>
                            </div>
                            </>
                            :""
                        }
                        </div>
                        <div className="col-md-4">
                        {  this.state.resturants.merchant_additional_feature.length > 0 ?
                            <>
                            <h1 className="sb-mb-30 text-center color-black mt-md-0">{this.props.t("restaurant_detail.additional")} <span className="color">{this.props.t("restaurant_detail.info")}</span></h1>
                            <div className="d-flex justify-content-center align-items-center bg-dark">
                                <div className="p-4">
                                <div className="row d-flex justify-content-around">
                                { this.state.resturants.merchant_additional_feature.map((af, index) => {
                                return(
                                    af.is_available == 1 ?
                                    <div key={index}  className="col-md-4 col-xs-6-cus p-md-0 mobile-size-space m-3">
                                        <div className="img-bg-payment p-4 mb-3 rounded-circle bg-white ">
                                            <img src={"../../img/"+ af.feature +".png"} className="img-fluid" />
                                        </div>
                                        <p className="text-white font-weight-bold text-center">{ af.feature }</p>
                                    </div>
                                    : ''
                                    )
                                    })
                                }
                                    {/* <div className="col-md-4 col-xs-6-cus p-md-0 mobile-size-space">
                                    <div className="img-bg-payment p-4 mb-3 rounded-circle bg-white ">
                                        <img src="../../img/wheelchair_new-2.png" className="img-fluid" />
                                    </div>
                                    <p className="text-white font-weight-bold">Wheelchair Friendly</p>
                                    </div>
                                    <div className="col-md-4 col-xs-6-cus p-md-0 mobile-size-space">
                                    <div className="img-bg-payment p-4 mb-3 rounded-circle  bg-white ">
                                        <img src="../../img/wifi-free-2.png" className="img-fluid" />
                                    </div>
                                    <p className="text-white font-weight-bold">Wheelchair Friendly</p>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-around">
                                    <div className="col-md-4 col-xs-6-cus p-md-0 mobile-size-space">
                                    <div className="img-bg-payment p-4 mb-3 rounded-circle bg-white ">
                                        <img src="../../img/delivery_new-2.png" className="img-fluid" />
                                    </div>
                                    <p className="text-white m-0 font-weight-bold">Wheelchair Friendly</p>
                                    </div>
                                    <div className="col-md-4 col-xs-6-cus p-md-0 mobile-size-space">
                                    <div className="img-bg-payment p-4 mb-3 rounded-circle bg-white ">
                                        <img src="../../img/delivery_new-2.png" className="img-fluid" />
                                    </div>
                                    <p className="text-white m-0 font-weight-bold">Wheelchair Friendly</p>
                                    </div> */}
                                </div>
                                </div>
                            </div>
                            </>
                        :""
                        }
                        </div>
                        <div className="col-md-4">
                        { this.state.resturants.merchant_payment_types.length > 0 ?
                            <>
                        <h1 className="sb-mb-30 text-center color-black mt-md-0">{this.props.t("restaurant_detail.payment")} <span className="color">{this.props.t("restaurant_detail.types")}</span></h1>
                        <div className="d-flex justify-content-center align-items-center bg-dark">
                            <div className="p-4">
                            <div className="row d-flex justify-content-around">
                            { this.state.resturants.merchant_payment_types.map((payment, index) => {
                            return(
                                
                                <div key={index}  className="col-md-3 col-xs-6-cus p-md-0 mobile-size-space mx-2 text-center align-items-center">
                                    <div className="img-bg-payment p-4 mb-3 rounded-circle bg-white " style={{ width: "104px", height: "104px"}}>
                                        <img src={payment.image_url} className="img-fluid" />
                                    </div>
                                    <p className="text-white font-weight-bold text-center">{ payment.displayName }</p>
                                </div>
                              
                                

                               
                                 )
                                })
                            }
                                
                            {/* </div>
                            <div className="row d-flex justify-content-around">
                                <div className="col-md-4 col-xs-6-cus p-md-0 mobile-size-space">
                                <div className="img-bg-payment p-4 mb-3 rounded-circle bg-white ">
                                    <img src="../../img/delivery_new-2.png" className="img-fluid" />
                                </div>
                                <p className="text-white m-0 font-weight-bold">Wheelchair Friendly</p>
                                </div>
                                <div className="col-md-4 col-xs-6-cus p-md-0 mobile-size-space">
                                <div className="img-bg-payment p-4 mb-3 rounded-circle bg-white ">
                                    <img src="../../img/delivery_new-2.png" className="img-fluid" />
                                </div>
                                <p className="text-white m-0 font-weight-bold">Wheelchair Friendly</p>
                                </div> */}
                            </div>
                            </div>
                        </div>
                        </>
                        :""
                        }
                        </div>
                    </div>
                    </div>
                </section>
                {  this.state.resturants.merchant_location !== undefined ?
                       
                <section id="map" className="py-5 btn-bg-custom">
                    <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-4">
                        <div className="text-center bg-dark py-5">
                            <i className="fa fa-map-marker f-100" />
                            <h1 className="text-white my-3">{this.state.resturants.merchant_restaurant.restaurant_name}</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                        </div>
                        <div className="col-md-8">
                        <iframe src={this.state.resturants.merchant_location.mapEmbedUrl} width="100%" height={350} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                        </div>
                    </div>
                    </div>
                </section>
                :""
                 }
                </div>

        );
    }else{
        return (
            <>
             <div className="row justify-content-center align-items-center h-70">
                <Loading />
            </div>
            </>
        )
    }
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  let { restaurants } = state.restaurants;
  if(state.restaurants){
      
    restaurants = state.restaurants[1];
}
  return {
    isLoggedIn,
    message,
    restaurants: restaurants,
  };
}

export default compose(withTranslation(), connect(mapStateToProps,{
    getRestaurantDetailByslug
}))(Individual2);
