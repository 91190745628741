import React from 'react'
import Spinner from 'react-bootstrap/Spinner';

const Loading = () => {

  return (
    <Spinner animation="border" role="status">
     
    </Spinner>
  );
    
}

export default Loading;
