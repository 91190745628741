import {
    GET_RESTAURANT_SEARCH_POSTCODE_DATA,
     
        // DELETE_ALL_RATES,
      } from "../actions/types";
      
      const initialState = [];
      
      function restaurantSearchPostcodeReducer(restaurant_postcode = initialState, action) {
        const { type, payload } = action;
      
        switch (type) {
          case GET_RESTAURANT_SEARCH_POSTCODE_DATA:
            return [...restaurant_postcode, payload];
      
        
      
          default:
            return restaurant_postcode;
        }
      }
      
      export default restaurantSearchPostcodeReducer;