import React from "react";
import { Route, Redirect } from "react-router-dom";
//import auth from "../../services/authService";
import AuthService from "../../services/auth.service";

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  const currentUser = AuthService.getCurrentUser();
  // console.log(path, Component, render,AuthService.getCurrentUser(), 'dddddddddddddddddddddddddddddddddddddddddddddddddddd' );
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!AuthService.getCurrentUser()) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        } else if (currentUser.role_slug === "admin") {
          return Component ? <Component {...props} /> : render(props);
        } else {
          return (
            <Redirect
              to={{
                pathname: "/dashboard",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
