import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import * as yup from "yup";
import { getSearchDataByPostCode } from "../../actions/restaurant-search-postcode";
import { getSearchData,getProvinceData,getCityData } from "../../actions/restaurant-search";
import { getRestaurant,getRestaurantList } from "../../actions/restaurants";
import Loading from "../common/loading";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";




class Search extends Component {
  constructor(props) {
    super(props);
    

    this.state = {
      region: [],
      defaultCities: [],
      cities: [],
      subregions: [],
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubRegionChange = this.handleSubRegionChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.getRestaurantData = this.getRestaurantData.bind(this);

    
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.fetchSearchData();
    if(localStorage.getItem('zipcode') !== ''){
        this.fetchDataByPostcode();
    }

  }


  fetchDataByPostcode = async () =>{
    const { history } = this.props;
    this.setState({ loading: true });
    let data = {
        postcode: localStorage.getItem('zipcode'),
    }
    await this.props.getSearchDataByPostCode(data);

    if(this.props.restaurantpostcode.encryptedCityId !== ''){
        localStorage.setItem('region_id', this.props.restaurantpostcode.encryptedRegionId);
        localStorage.setItem('subregion_id', this.props.restaurantpostcode.encryptedSubregionId);
        localStorage.setItem('city_id', this.props.restaurantpostcode.encryptedCityId);
        history.push("/search-listing");
    }
    // console.log(this.props.restaurantpostcode, "d");

  }

  fetchSearchData = async () =>{
    this.setState({ loading: true });
   
    await this.props.getSearchData();
    // await this.props.getProvinceData('');


    console.log(this.props);
    this.setState({ 
        region: this.props.restaurantsearch.regions,
        defaultCities: this.props.restaurantsearch.defaultCities,
        cities: this.props.restaurantsearch.cities,
    });

    await this.props.getProvinceData('');
    this.setState({ 
        loading: false,
        subregions: this.props.restaurantsearch.subregions,
    });

 }
 
 async handleChange(e){

    if(e.target.value !== ''){
        localStorage.setItem('region_id', e.target.value);

        this.setState({ loading: true });
        await this.props.getProvinceData(e.target.value);

        this.setState({ 
            loading: false,
            subregions: this.props.restaurantsearch.subregions,
        });
    }

 }

 async handleSubRegionChange(e){

    if(e.target.value !== ''){
        localStorage.setItem('subregion_id', e.target.value);

        this.setState({ loading: true });
        let data = {
            subregion_id : e.target.value,
            type: 'cities',
        }
        await this.props.getCityData(data);

        this.setState({ 
            loading: false,
            cities: this.props.restaurantsearch.cities,
        });

    }

 }


 async handleCityChange(e){

    const { history } = this.props;
    localStorage.setItem('city_id', e.target.value);
    if(e.target.value !== ''){
        this.setState({ loading: true });
        history.push("/search-listing");

    }
 }

 async getRestaurantData(e){
 
    let cityID = e.currentTarget.getAttribute('data-id');
   
    if(this.state.defaultCities.cadiz.encrypted_id == cityID){
        console.log(this.state.defaultCities.cadiz.encrypted_id == cityID);
        localStorage.setItem('city_id', cityID);
        localStorage.setItem('subregion_id', this.state.defaultCities.cadiz.encrypted_subregion_id);
        localStorage.setItem('region_id', this.state.defaultCities.cadiz.encrypted_region_id);

    }else if(this.state.defaultCities.malaga.encrypted_id == cityID){
        localStorage.setItem('city_id', cityID);
        localStorage.setItem('subregion_id', this.state.defaultCities.malaga.encrypted_subregion_id);
        localStorage.setItem('region_id', this.state.defaultCities.malaga.encrypted_region_id);
    }

      
    // const newList = this.state.defaultCities.filter((i, itemIndex) => index !== itemIndex) 
    const { history } = this.props;
    // console.log(e.target);
 

    history.push("/search-listing");
 }

 

  render() {

    console.log(this.state);
    //const { isLoggedIn, message } = this.props;
    const { isLoggedIn } = this.props;

   
    
    return (
      <>
        
       
        <div className="blog-single gray-bg">
            <div className="container">
              
                
                 
                <div className="row align-items-start">
         
                <div className="col-lg-3 m-15px-tb blog-aside sticky-top">
                    <div className="widget widget-tags d-none d-sm-block d-sm-none d-md-block">
                    <div className="widget-title text-center btn-bg-custom">
                        <h3 className="text-white">{this.props.t('searchPage.filterBy')}</h3>
                    </div>
                    <div className="widget-body">
                        <div className="nav tag-cloud d-flex justify-content-center">
                        <div className="mb-3">
                            <img src="img/city-loc.png" className="img-fluid" />
                        </div>
                  
                        <select className="form-control border mb-1 mt-3"  onChange={this.handleChange}>
                            <option value={0} >{this.props.t('searchPage.selectRegion')}</option>
                            { this.state.region.length > 0 ?
                                this.state.region.map((r, index) => {
                                    return (
                                        <option  key={index} value={r.encrypted_id} data-id={r.id}>{r.displayName}</option>
                                        )
                                    })
                                : ''
                            }
                        </select>
                        <select className="form-control border my-1"  onChange={this.handleSubRegionChange}>
                        {   this.state.subregions.length > 0 ?
                                this.state.subregions.map((s, index) => {
                                    return (
                                        <option  key={index} value={s.encrypted_id} data-id={s.id}>{s.displayName}</option>
                                        )
                                    })
                                : <option value={0} >{this.props.t('searchPage.selectProvince')}</option>
                            }
                            
                        </select>
                        <select className="form-control border my-1" onChange={this.handleCityChange}>
                        { this.state.cities.length > 0 ?
                                this.state.cities.map((c, index) => {
                                    return (
                                        <option key={index} value={c.encrypted_id} data-id={c.id}>{c.displayName}</option>
                                        )
                                    })
                            : <option value={0} >{this.props.t('searchPage.selectCity')}</option>
                        }
                          
                        </select>
                        <input type="text" className="form-control my-1 border" placeholder="Food Type" />
                        <label className="w-100 text-center">
                            <input type="checkbox" />
                            <span className="text">{this.props.t('searchPage.sortDelivery')}</span>
                        </label>
                        </div>
                    </div>
                    </div>
                    <div className="widget widget-tags d-block d-sm-none d-none d-sm-block d-md-none">
                    <div className="widget-body">
                        <div className="nav tag-cloud d-flex justify-content-center">
                        <div className="mb-1">
                            <img src="img/city-loc.png" className="img-fluid" />
                        </div>
                        <button className="btn btn-block" >Malaga <span>(200)</span></button>
                        </div>
                    </div>
                    <div className="widget-title text-center btn-bg-custom p-1">
                        <a href="#" data-toggle="modal" data-target="#exampleModal">
                        <h3 className="text-white"><i className="fa fa-filter" aria-hidden="true" /> {this.props.t('searchPage.filterBy')}</h3>
                        </a>
                    </div>
                    </div>
                </div>
                <div className="col-lg-9">
                    {this.state.loading ?
                    <div className="row justify-content-center align-items-center h-70">
                    <Loading />
                    </div>  
                    :
                    <div className="row">
                        <div className="container">
                            <h5 className="font-weight-normal mt-5 text-center mb-4">
                            <span className="font-weight-bold">{this.props.t('searchPage.opsText')}</span> {this.props.t('searchPage.locationText')} <br /> {this.props.t('searchPage.brwOur')} <span className="color font-weight-bold">{this.props.t('searchPage.curcity')}</span> below:
                            </h5>
                            <div className="row">
                            <div className="col-lg-5 offset-md-1 mb-3 mb-lg-0">
                                <div className="hover hover-5 text-white rounded">
                                <img src="https://www.ticketmonument.com/wp-content/uploads/2015/11/Malaga-Downtown.jpg" />
                                <div className="hover-overlay" />
                                <div className="hover-5-content">
                                    <a href="#!" onClick={this.getRestaurantData} data-id="clVROVFnPT0" >
                                    <h3 className="hover-5-title text-uppercase font-weight-light mb-0">
                                        <strong data-id="clVROVFnPT0" className="font-weight-bold text-white" >Málaga </strong><span>Lorem Ipsum</span>
                                    </h3>
                                    </a>
                                </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="hover hover-5 text-white rounded">
                                <img src="https://travellertripplanner.com/wp-content/uploads/2022/02/Discover-Cadiz-Spain.jpg" />
                                <div className="hover-overlay" />
                                <div className="hover-5-content">
                                    <a href="#!"  onClick={this.getRestaurantData} data-id="clVNN1JnPT0" >
                                    <h3 className="hover-5-title text-uppercase font-weight-light mb-0">
                                        <strong data-id="clVNN1JnPT0" className="font-weight-bold text-white">CÁDIZ </strong><span>Lorem Ipsum</span>
                                    </h3>
                                    </a>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    } 
                </div>
                </div>
            </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
    console.log(state, "***********************");
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  let { restaurantsearch } = state.restaurantsearch;
  let { restaurantpostcode } = state.restaurant_postcode;

  
    if(state.restaurantsearch){
    restaurantsearch = state.restaurantsearch[1];
    }

    if(state.restaurantsearch){
        restaurantpostcode = state.restaurant_postcode[0];
        }

// console.log(restaurantsearch, "===================")
  return {
    isLoggedIn,
    message,
    restaurantsearch: restaurantsearch,
    restaurantpostcode: restaurantpostcode,
  };
}

export default compose(withTranslation(), connect(mapStateToProps, {
    getSearchData,
    getProvinceData,
    getCityData,
    getSearchDataByPostCode,
}))(Search);
