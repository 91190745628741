import {
  GET_RESTAURANT_SEARCH_CITIES,
   
      // DELETE_ALL_RATES,
    } from "../actions/types";
    
    const initialState = [];
    
    function cityAutoCompleteSearchReducer(citiesAuto = initialState, action) {
      const { type, payload } = action;
    
      switch (type) {
        case GET_RESTAURANT_SEARCH_CITIES:
          return [...citiesAuto, payload];
    
      
    
        default:
          return citiesAuto;
      }
    }
    
    export default cityAutoCompleteSearchReducer;