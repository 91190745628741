import axios from "axios";
import authHeader from "./auth-header";
import configData from "../config.json";
//const API_URL = "http://localhost:8080/api/test/";
const apiEndpoint = configData.apiUrl + "/home/page";

class PageDetailService {
  

  getData(slug) {
    return axios.get(apiEndpoint + `${slug}`, { headers: authHeader() });
    //return axios.get(apiEndpoint, { headers: authHeader() } );
  }

 

}

export default new PageDetailService();
