import React, { Component }  from "react";
//import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
//import CommonHelper from "./../utils/CommonHelper";
// import Joi from "joi-browser";
import { Formik } from "formik";
import * as yup from "yup";
import Message from "./common/message";
import { Row,  Form, FormGroup } from "react-bootstrap";
import { compose } from "redux";
import { updatePassword } from "../actions/auth-user";
import { _pre } from "../utils/vlogs";
import { withTranslation } from "react-i18next";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    /*  this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.saveTutorial = this.saveTutorial.bind(this);
    this.newTutorial = this.newTutorial.bind(this); */

    /* this.state = {
      id: null,
      title: "",
      description: "",
      published: false,

      submitted: false,
    }; */

    this.state = {
      loading: false,
      data: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      errors: {},
    };
  }


  schema =  yup.object().shape({
    
    currentPassword: yup.string().required('changepassword.validation.currentPassword'),
    newPassword: yup.string().required('changepassword.validation.newPassword').min(8, 'changepassword.validation.minNewpassword').max(20, 'changepassword.validation.maxNewpassword').label("New Password"),
    confirmNewPassword:yup.string().oneOf([yup.ref("newPassword"), null], "changepassword.validation.passwordNotMatch").required('changepassword.validation.confirmPassword'),
  });
 
  componentDidMount() {
    /*  const genres = getGenres();
    this.setState({ genres });

    const movieId = this.props.match.params.id;
    if (movieId === "new") return;

    const movie = getMovie(movieId);
    if (!movie) return this.props.history.replace("/not-found");

    this.setState({ data: this.mapToViewModel(movie) }); */
  }

  doSubmit(values){
    //saveMovie(this.state.data);
    //e.preventDefault();

    this.setState({
      loading: true,
    });

    //this.form.validateAll();

    //, history
    const { dispatch } = this.props;

    dispatch(updatePassword(values))
      .then((response) => {
        _pre(" LAST aaaa", response);
       
        //history.push("/movies");
        // window.location = "/profile";
        this.setState({
          data: {
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          },
        });
          values.currentPassword = "";
          values.newPassword = "";
          values.confirmNewPassword= "";
          console.log(values);
        this.setState({
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });

    //this.props.history.push("/movies");
  };

  render() {
    const { message } = this.props;
    //const notify = () => toast.error("Wow so easy!");
    return (


    <div className="forny-container">
      <div className="forny-inner">
        <div className="forny-two-pane">
          <div>
            <div className="forny-form">
              {/* <div class="mb-8 forny-logo">
                      <img src="img/zam-logo.png">
                      </div> */}
              <div>
                <h1 className="mb-2 color">Reset Your <span className="text-dark">Password</span></h1>
                <p className="mb-5">Please enter your email address and we will send you a password password link.</p>
              </div>
              <form>
                <div className="row">
                  <div className="form-group border-radius-100 col-md-12">
                    <div className="input-group">
                      <input required className="form-control" name="username" type="username" placeholder="Enter Email Address" />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button className="btn btn-bg-custom px-5 border-radius-100">Send Reset Link</button>
                </div>
                <div className="mt-10">
                  Already have an account? <a href="login.html">Login here</a>
                </div>
              </form>
            </div>
          </div>
          <div />
        </div>
      </div>
    </div>

      // <div className="contents">
      //   <div className="container-fluid">
      //     <div className="row">
      //       <div className="col-lg-12">
      //         <div className="contact-breadcrumb">
      //           <div className="breadcrumb-main add-contact justify-content-sm-between ">
      //             <div className=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
      //               <div className="d-flex align-items-center add-contact__title justify-content-center mr-sm-25">
      //                 <h4 className="text-capitalize fw-500 breadcrumb-title">
      //                   {this.props.t("pagetitle.changepassword")}
      //                   {/* <button onClick={notify}>Notify!</button> */}
      //                 </h4>

      //                 <span className="sub-title ml-sm-25 pl-sm-25"></span>
      //               </div>
      //             </div>

      //             <div className="breadcrumb-main__wrapper"></div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>

      //     <div className="row">
      //       <div className="col-lg-12 mb-30">
      //         <div className="card">
      //           {/* <div className="card-header color-dark fw-500">Rate List</div> */}
      //           <div className="card-body">
      //             {/* <div className="row">
      //               <div className="col-xxl-12 col-md-6 p-10">
      //                 <div className="action-btn  form-inline-action d-flex justify-content-between align-items-center">
      //                   <div className="checkbox-theme-default custom-checkbox "></div>

      //                   <a href="" className="btn btn-sm btn-primary btn-add">
      //                     <i className="la la-plus"></i> Add New
      //                   </a>
      //                 </div>
      //               </div>
      //             </div> */}

      //             <div className="row">
      //               {/* <div className="col-md-12 p-10 text-right color-danger">
      //                 <span className="text-red">*</span> indicates a required
      //                 field
      //               </div> */}
      //               <div className="col-md-4 offset-md-4 p-10 ">
      //                 <div className="form-group mb-20">
      //                   <Message message={message} />
      //                   {/* {message && (
      //                     <div className="form-group">
      //                       <div
      //                         className="alert alert-danger"
      //                         role="alert"
      //                         dangerouslySetInnerHTML={{ __html: message }}
      //                       ></div>
      //                     </div>
      //                   )} */}
      //                 </div>
      //                 <Formik
      //                   validationSchema={this.schema}
      //                   validateOnChange={false}
      //                   validateOnBlur={false}
      //                   enableReinitialize="true"
      //                   onSubmit={(values) => {
      //                     this.doSubmit(values);
      //                   }}
      //                   initialValues={{
      //                     currentPassword: this.state.data.currentPassword,
      //                     newPassword: this.state.data.newPassword,
      //                     confirmNewPassword:  this.state.data.confirmNewPassword,
      //                   }}
      //                 >
      //                   {({
      //                     handleSubmit,
      //                     handleChange,
      //                     handleBlur,
      //                     values,
      //                     touched,
      //                     isValid,
      //                     errors,
      //                   }) => (
      //                     <Form noValidate onSubmit={handleSubmit}>
      //                       <Row className="mb-3">
      //                         <FormGroup className="col-md-12 form-group">
      //                         <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
      //                           {this.props.t(
      //                             "changepassword.labelcurrentpwd"
      //                           )}
      //                         </Form.Label>
      //                         <Form.Control
      //                           className=""
      //                           type="password"
      //                           name="currentPassword"
      //                           placeholder={this.props.t(
      //                             "changepassword.labelcurrentpwd"
      //                           )}
      //                           value={values.currentPassword}
      //                           onChange={handleChange}
      //                           isInvalid={!!errors.currentPassword}
      //                         />
                              
      //                         <Form.Control.Feedback
      //                           className=""
      //                           type="invalid"
      //                         >
      //                           { this.props.t(errors.currentPassword)}
      //                         </Form.Control.Feedback>
      //                         </FormGroup>
      //                       </Row>
      //                       <Row className="mb-3">
      //                       <FormGroup className="col-md-12 form-group">
      //                         <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
      //                           {this.props.t(
      //                             "changepassword.labelnewpwd"
      //                           )}
      //                         </Form.Label>
      //                         <Form.Control
      //                         type="password"
      //                           name="newPassword"
      //                           className="form-control"
      //                           onChange={handleChange}
      //                           placeholder={this.props.t('changepassword.labelnewpwd')}
      //                           value={values.newPassword}
      //                           isInvalid={!!errors.newPassword}
      //                         />                              
      //                         <Form.Control.Feedback
      //                           className=""
      //                           type="invalid"
      //                         >
      //                            { this.props.t(errors.newPassword)}
      //                         </Form.Control.Feedback>
      //                         </FormGroup>
      //                       </Row> 
      //                       <Row className="mb-3">
      //                       <FormGroup className="col-md-12 form-group">
      //                         <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
      //                           {this.props.t(
      //                             "changepassword.labelconfpwd"
      //                           )}
      //                         </Form.Label>
      //                         <Form.Control
      //                         type="password"
      //                           name="confirmNewPassword"
      //                           className="form-control"
      //                           onChange={handleChange}
      //                           placeholder={this.props.t('changepassword.labelconfpwd')}
      //                           value={values.confirmNewPassword}
      //                           isInvalid={!!errors.confirmNewPassword}
      //                         />                              
      //                         <Form.Control.Feedback
      //                           className=""
      //                           type="invalid"
      //                         >
      //                            { this.props.t(errors.confirmNewPassword)}
      //                         </Form.Control.Feedback>
      //                         </FormGroup>
      //                       </Row>                            
      //                       <div className="button-group d-flex pt-1 justify-content-md-start justify-content-center">
      //                       <button
      //                         className="btn btn-primary btn-default btn-squared mr-15 text-capitalize lh-normal px-40 py-15 "
      //                         disabled={this.state.loading}
      //                       >
      //                         {this.state.loading && (
      //                           <span className="spinner-border spinner-border-sm"></span>
      //                         )}
      //                         <span>{this.props.t('button.save')}</span>
      //                       </button>
      //                     </div>
      //                     </Form>
      //                   )}
      //                 </Formik>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { message } = state.message;

  return {
    user,
    message,
  };
}

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(ChangePassword);
