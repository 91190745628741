import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from "./types";

import AuthService from "../services/auth.service";
import CommonHelper from "../utils/CommonHelper";
// import { _pre } from "../utils/vlogs";
export const register = (data) => (dispatch) => {
  return AuthService.register(data).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: response.data,
      });
console.log(response);
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      
      // _pre("AuthService CYX ", error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        console.log(message);
      // dispatch({
      //   type: REGISTER_FAIL,
      // });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (email, password) => (dispatch) => {
  dispatch({
    type: SET_MESSAGE,
    payload: "",
  });
  return AuthService.login(email, password).then(
    (data) => {
      // _pre("in action auth.js -> login then ", data);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data.data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // _pre(" auth.js -> error ", error);
      const resMessage = {
        message: message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: LOGIN_FAIL,
      });
      // console.log(message);
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage,
      });

      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};
