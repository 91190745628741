import React from "react";

const Message = (msg) => {
  // console.log("message " + msg.message);
 
  //msg.message.type = "error";
  //msg.message.message = "this is testing";
  // console.log()
  return (
    <>
    
      {msg.message && (
         msg.message.message !== undefined && msg.message.message.message !== undefined ?
         msg.message.type =  msg.message.message.type
         : '',
         
            <div
              className={
                " alert  fade show content-center1 col-md1-6 offset-md1-3 col1-12  alert-" + msg.message.type
              }
              role="alert"
            >
              <div className="alert-content">
             { msg.message.message !== undefined ?

                <>
                { msg.message.message.message !== undefined ? 
                <p dangerouslySetInnerHTML={{ __html:msg.message.message.message  }}></p>
                : <p dangerouslySetInnerHTML={{ __html:msg.message.message  }}></p>
                }
                </>
                
               
              : ''

            }

         
          </div>
        </div>
      )}
    </>
  );
};

export default Message;
